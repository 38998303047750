import { Alert, Box, Card, Fade, Grid, IconButton, LinearProgress, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import dayjs from "dayjs";
import NoDataError from "../../errors/NoDataError";
import NoPointError from "../../errors/NoPointError";
import UsageComparison from "./UsageComparison";
import UsageDateSelection from "./UsageDateSelection";
import { setWeekInYear } from "../../util/DateUtils";
import UsageGraph from "./UsageGraph";
import { Warning } from "../../types/Warning";
import { Player } from '@lottiefiles/react-lottie-player';
import { EnergyType } from "../../types/EnergyType";
import { Dispatch, SetStateAction } from "react";
import { DateSelection } from "../../types/DateSelection";
import { updateQueryParam } from "../../util/ParamUtils";

interface UsageOverviewBoxProps {
    energyType: EnergyType,
    energySelection: number | null,
    energyLastYear: number | null,
    energyPrevious: number | null,
    date: DateSelection,
    graphType: string,
    animClass: string,
    setAnimClass: Dispatch<SetStateAction<string>>,
    isLoading: number,
    error: Error | null,
    warnings: {selection: Warning, lastYear: Warning, previous: Warning},
    setDetailViewState: Dispatch<SetStateAction<EnergyType | null>>
}

export default function UsageOverviewBox(props: UsageOverviewBoxProps) {

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: props.energyType.mainColor,
            fontSize: "0.8rem",
            fontFamily: "NeoSansProBold"
        }
    });

    let date = dayjs(props.date.year.toString());
    let dateLastYear = date.subtract(1, "year");

    let dateFormat = "YYYY";
    let lastYearFormat = "YYYY";

    let lastYearCompareFormat = " ";

    let prevDateStr = "for to år siden";

    if (props.date.month !== undefined) {
        date = dayjs(`${props.date.year}-${props.date.month + 1}-01`);
        dateLastYear = date.subtract(1, "year");

        dateFormat = "MMMM YYYY";
        lastYearFormat = "MMMM YYYY";

        lastYearCompareFormat = "MMMM";

        prevDateStr = "måneden før";
    } else if (props.date.week !== undefined) {
        date = setWeekInYear(dayjs(props.date.year.toString()), props.date.week);
        dateLastYear = setWeekInYear(dayjs((props.date.year - 1).toString()), props.date.week === 53 ? 52 : props.date.week);

        dateFormat = `Uge W ${date.isoWeekYear()}`;
        lastYearFormat = `Uge W ${dateLastYear.isoWeekYear()}`;

        lastYearCompareFormat = "uge W";

        prevDateStr = "ugen før";
    }

    let boxContents = <></>;

    if (props.isLoading !== 100) {
        // If the API calls for the data have not all returned yet, show Lottie animations
        boxContents = (
            <Box position="absolute" margin="auto" top="50%" left={0} right={0} sx={{transform: "translateY(-50%)"}}>
                <Player
                    autoplay
                    loop
                    src={props.energyType.loadingIcon}
                    style={{ height: "125px", width: "125px" }}
                />
                <Box width={120} margin="auto" top="50%" left={0} right={0} sx={{color: props.energyType.mainColor}}>
                    <LinearProgress
                        variant="determinate"
                        value={props.isLoading}
                        color="inherit"
                        sx={{
                            height: 6,
                            borderRadius: 5,
                            '& .MuiLinearProgress-bar': { borderRadius: 5 }
                        }}
                    />
                </Box>
            </Box>
        );

    } else if (props.energySelection === null) {
        // If the API calls have returned, but no data was found for energySelection (the main value from the chosen date)
        if (props.error !== null) {
            // If an error has occurred (this should always be true because otherwise energySelection would have a value)
            if (props.error instanceof NoDataError) {
                boxContents = (
                    <Alert severity="info">
                        For den valgte periode er der ikke fyldestgørende data. Kontakt eventuelt Campus Service BMS.
                    </Alert>
                );
            } else if (props.error instanceof NoPointError) {
                boxContents = (
                    <Alert severity="info">
                        Den valgte bygning har ikke nogle målinger for {props.energyType.displayName.toLowerCase()}.
                    </Alert>
                );
            } else {
                boxContents = (
                    <Alert severity="error">
                        Der opstod en fejl under hentning af data ({props.error.message}).
                    </Alert>
                );
            }
        }

    } else {
        // If the API calls have, at minimum, returned a value for energySelection
        boxContents = (
            <Grid container columns={2} columnSpacing={1} rowSpacing={{xs: 2, md: 4, xl: 1, xxl: 4}} mb={2}>
                <Grid item xs={2} md={1} xl={2} xxl={1}>
                    <UsageDateSelection
                        energy={props.energyLastYear} date={dateLastYear.format(lastYearFormat)}
                        decimals={props.energyType.decimals} color={"#c5c5c5"} warning={props.warnings.lastYear}
                    />
                </Grid>
                <Grid item
                    xs={2} md={1} xl={2} xxl={1}
                    display="flex" justifyContent={{xs: "left", md: "right", xl: "left", xxl: "right"}}
                >
                    <UsageDateSelection
                        energy={props.energySelection} date={date.format(dateFormat)}
                        decimals={props.energyType.decimals} color={props.energyType.mainColor} warning={props.warnings.selection}
                    />
                </Grid>
                <Grid item xs={2}>
                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                            { props.energyLastYear !== null && props.energyLastYear !== 0 &&
                                <UsageGraph
                                    current={props.energySelection} lastYear={props.energyLastYear}
                                    color={props.energyType.mainColor} decimals={props.energyType.decimals}
                                />
                            }
                            <Box display="flex" minWidth={200} flexDirection="column" justifyContent="center" gap={2}>
                                { props.energyLastYear !== null &&
                                    <UsageComparison
                                        current={props.energySelection} previous={props.energyLastYear}
                                        decimals={props.energyType.decimals} unit={props.energyType.unit} prevStr="året før"
                                        dateStr={dateLastYear.format(lastYearCompareFormat)}
                                    />
                                }
                                { props.energyPrevious !== null &&
                                    <UsageComparison
                                        current={props.energySelection} previous={props.energyPrevious}
                                        decimals={props.energyType.decimals} unit={props.energyType.unit} prevStr={prevDateStr}
                                        warning={props.warnings.previous}
                                    />
                                }
                            </Box>
                        </Stack>
                </Grid>
            </Grid>
        )
    }

    return (
        <Box position="relative" height="100%">
            <Card sx={{height: "100%", minHeight: {xs: 530, md: 495, xl: 518, xxl: 495}, p: {xs: 3, md: 3.75}}}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" mb={{xs: 2, md: 4, xl: 1, xxl: 4}}>
                    <Typography color={props.energyType.mainColor} fontSize="1.625rem" fontFamily={"NeoSansProBold"} lineHeight={1}>
                        {props.energyType.displayName}
                    </Typography>
                    <Box px={2} py={1} borderRadius="10px" sx={{backgroundColor: props.energyType.lightColor}}>
                        <Typography fontSize="1.625rem" lineHeight={1} color={props.energyType.mainColor}>
                            {props.energyType.unit === "m3" ? <>m<sup>3</sup></> : props.energyType.unit}
                        </Typography>
                    </Box>
                </Stack>
                { boxContents }
            </Card>
            { props.isLoading === 100 &&
                <CustomTooltip
                    title="Vis detaljer"
                    placement="bottom"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 300 }}
                    PopperProps={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -5],
                                },
                            },
                        ],
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.setDetailViewState(props.energyType);
                            updateQueryParam("graph", props.graphType.toLowerCase(), false);
                            updateQueryParam("type", props.energyType.shortName, true);
                        }}
                        onMouseOver={() => props.setAnimClass("")}
                        sx={{
                            backgroundColor: "white",
                            width: 100,
                            height: 100,
                            boxShadow: "0px 2px 6px 2px #00000029",
                            position: "absolute",
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: 0,
                            right: 0,
                            bottom: -50,
                            "&:hover": {backgroundColor: "white"},
                            "&:hover img": {width: 65, transition: "width 0.3s"}
                    }}>
                        <img src={props.energyType.icon} className={props.animClass} alt="Energy Icon" width={55} style={{transition: "width 0.3s"}} />
                    </IconButton>
                </CustomTooltip>
            }
        </Box>
    )
}
