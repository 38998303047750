import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
      xs: true;
      sm: true;
      md: true;
      lg: true;
      xl: true;
      xxl: true;
    }
}

export const theme = createTheme({
    components: {
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    fontSize: "1rem",
                    backgroundColor: "#FAFAFA",
                    color: "#990000",
                    fontFamily: "NeoSansProBold",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    ":focus": {
                        backgroundColor: "transparent",
                    },
                },
            },
        },
    },
    palette: {
        mode: "light",
        primary: {
          main: "#0DD280",
          dark: "#08623C",
          light: "#BDD4CA",
        },
        secondary: {
          main: "#990000",
        },
        background: {
            default: "#F4FDF9",
        },
      },
    typography: {
        allVariants: {
            fontFamily: "NeoSansProRegular",
            fontSize: "1rem",
        },
    },
    shape: {
        borderRadius: 10,
    },
    breakpoints: {
        values: {
          xs: 0,
          sm: 770,
          md: 900,
          lg: 1200,
          xl: 1586,
          xxl: 1780
        },
    },
 });

 theme.shadows[1] = "0px 3px 6px #00000029"; // Default shadow value
