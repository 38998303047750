import { Grid } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { DateSelection } from "../../types/DateSelection";
import { EnergyData } from "../../types/EnergyData";
import { EnergyType } from "../../types/EnergyType";
import { UsageType } from "../../types/UsageType";
import { Warning } from "../../types/Warning";
import UsageOverviewBox from "./UsageOverviewBox";

interface TotalUsageDashboardProps {
    elecData: EnergyData,
    waterData: EnergyData,
    heatData: EnergyData,
    coolingData: EnergyData,
    date: DateSelection,
    graphType: string,
    animClass: string,
    setAnimClass: Dispatch<SetStateAction<string>>,
    isLoading: number[],
    errors: (Error | null)[],
    warnings: {selection: Warning, lastYear: Warning, previous: Warning}[],
    graphState: {isOpen: boolean, usageType: UsageType, date: string},
    setDetailViewState: Dispatch<SetStateAction<EnergyType | null>>
}

export default function TotalUsageDashboard(props: TotalUsageDashboardProps) {
    return (
        <Grid container rowSpacing={9} columnSpacing={4.5} columns={10} justifyContent="center" mb={{xs: 4, xl: 0}}>
            <>
                <Grid item xs={10} sm={5} xl className="overview-fade scale">
                    <UsageOverviewBox
                        energyType={EnergyType.ELEC} energySelection={props.elecData.selection} energyLastYear={props.elecData.lastYear}
                        energyPrevious={props.elecData.previous} date={props.date} graphType={props.graphType} isLoading={props.isLoading[EnergyType.ELEC.id]}
                        animClass={props.animClass} setAnimClass={props.setAnimClass} error={props.errors[EnergyType.ELEC.id]}
                        warnings={props.warnings[EnergyType.ELEC.id]} setDetailViewState={props.setDetailViewState}
                    />
                </Grid>
                <Grid item xs={10} sm={5} xl className="overview-fade scale delay-1" display={{xl: props.graphState.isOpen ? "none" : "block"}}>
                    <UsageOverviewBox
                        energyType={EnergyType.WATER} energySelection={props.waterData.selection} energyLastYear={props.waterData.lastYear}
                        energyPrevious={props.waterData.previous} date={props.date} graphType={props.graphType} isLoading={props.isLoading[EnergyType.WATER.id]}
                        animClass={props.animClass} setAnimClass={props.setAnimClass} error={props.errors[EnergyType.WATER.id]}
                        warnings={props.warnings[EnergyType.WATER.id]} setDetailViewState={props.setDetailViewState}
                    />
                </Grid>
                <Grid item xs={10} sm={5} xl className="overview-fade scale delay-2" display={{xl: props.graphState.isOpen ? "none" : "block"}}>
                    <UsageOverviewBox
                        energyType={EnergyType.HEAT} energySelection={props.heatData.selection} energyLastYear={props.heatData.lastYear}
                        energyPrevious={props.heatData.previous} date={props.date} graphType={props.graphType} isLoading={props.isLoading[EnergyType.HEAT.id]}
                        animClass={props.animClass} setAnimClass={props.setAnimClass} error={props.errors[EnergyType.HEAT.id]}
                        warnings={props.warnings[EnergyType.HEAT.id]} setDetailViewState={props.setDetailViewState}
                    />
                </Grid>
                <Grid item xs={10} sm={5} xl className="overview-fade scale delay-3" display={{xl: props.graphState.isOpen ? "none" : "block"}}>
                    <UsageOverviewBox
                        energyType={EnergyType.COOLING} energySelection={props.coolingData.selection} energyLastYear={props.coolingData.lastYear}
                        energyPrevious={props.coolingData.previous} date={props.date} graphType={props.graphType} isLoading={props.isLoading[EnergyType.COOLING.id]}
                        animClass={props.animClass} setAnimClass={props.setAnimClass} error={props.errors[EnergyType.COOLING.id]}
                        warnings={props.warnings[EnergyType.COOLING.id]} setDetailViewState={props.setDetailViewState}
                    />
                </Grid>
            </>
        </Grid>
    )
}
