import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { TimeValue } from "../../models/TimeValue";

interface EnergyGraphProps {
    energyValues: TimeValue[]
}

export default function EnergyGraph(props: EnergyGraphProps) {
    const theme = useTheme();

    const [focusBar, setFocusBar] = useState<number | undefined>();

    const data = [];

    // For every hour today
    for (let i = 0; i < 24; i++) {
        const searchHour = i + 1 === 24 ? 0 : i + 1;

        const energyValue = props.energyValues.find(tv => dayjs(tv.ts).hour() === searchHour);

        data.push({
            time: i < 10 ? "0" + i : i.toString(),
            energy: energyValue ? energyValue.value : null
        });
    }

    const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
        const nextHour = parseInt(label) + 1;
        const nextLabel = nextHour < 10 ? "0" + nextHour : nextHour.toString();
        const labelFormatted = label === "23" ? "23:00 - 00:00" : `${label}:00 - ${nextLabel}:00`;

        if (active && payload && payload.length) {
            return (
                <Box p={1.5} borderRadius="5px" border="1px solid black" sx={{backgroundColor: "white"}}>
                    <Typography lineHeight={1} mb={1}>{labelFormatted}</Typography>
                    <Typography color={theme.palette.primary.main} lineHeight={1} fontFamily="NeoSansProBold">
                        {(payload[0].value)?.toLocaleString("da-DK", {maximumFractionDigits: 2})} kWh
                    </Typography>
                </Box>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={340}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    right: 50
                }}
                onMouseLeave={state => setFocusBar(undefined)}
                onMouseMove={state => {
                    if (state.isTooltipActive) {
                      setFocusBar(state.activeTooltipIndex);
                    } else {
                      setFocusBar(undefined);
                    }
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tickSize={8} tickMargin={5} />
                <YAxis width={65} tickSize={8} tickMargin={5} tickFormatter={tick => tick.toLocaleString("da-DK", {maximumFractionDigits: 1})} />
                <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ outline: "none" }}
                    cursor={false}
                    isAnimationActive={false}
                />
                <Bar dataKey="energy" fill={theme.palette.primary.main} radius={[3, 3, 0, 0]} maxBarSize={35}>
                    {
                        data.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={focusBar === index ? '#1ba86d' : theme.palette.primary.main}
                            />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
