import { Autocomplete, Card, TextField, Typography, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { updateQueryParam } from "../../util/ParamUtils";

interface DropdownBoxProps {
    title: string,
    options: any[],
    getOptionLabel?: (option: any) => string,
    isOptionEqualToValue?: (option: any, value: any) => boolean,
    selected: any,
    setSelected: Dispatch<SetStateAction<any>>,
    disableTyping: boolean,
    isLoading: boolean,
    param: string,
    setDayViewState: Dispatch<SetStateAction<{isOpen: boolean, date: string}>>
}

/**
 * A card with an autocomplete component and an attached title.
 * @param {string} props.title - Title of the dropdown
 * @param {any[]} props.options - List of items
 * @param {(option: any) => string} [props.getOptionLabel] - Optional function to determine what the label should be for each item in the dropdown
 * @param {(option: any, value: any) => boolean} [props.isOptionEqualToValue] - Optional function to determine the comparator used for option and value
 * @param {any} props.selected - Currently selected item
 * @param {Dispatch<SetStateAction<any>>} props.setSelected - Set state of currently selected item
 * @param {boolean} props.disableTyping - Determines whether the dropdown should act as a Select or Autocomplete component
 * @param {boolean} props.isLoading - Determines whether we have received the options yet (likely from an API)
 * @param {boolean} props.param - Name of the search parameter used to represent this selection
 */
export default function DropdownBox(props: DropdownBoxProps) {
    const theme = useTheme();

    const styles = {
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
        "& .MuiAutocomplete-inputRoot": {
            color: theme.palette.secondary.main,
            backgroundColor: "#FAFAFA",
            fontFamily: "NeoSansProBold",
        },
        "& .MuiAutocomplete-popupIndicator": {
            color: theme.palette.secondary.main,
        },
        "& .MuiAutocomplete-clearIndicator": {
            color: theme.palette.secondary.main,
        },
    };

    return (
        <Card sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 3.75
        }}>
            <Typography pb={3.5} fontSize="1.25rem" fontFamily={"NeoSansProBold"} lineHeight={1}>{props.title}</Typography>
            <Autocomplete
                id="dropdown-box"
                options={props.options}
                getOptionLabel={props.getOptionLabel}
                sx={styles}
                disableClearable={props.disableTyping}
                loading={props.isLoading}
                loadingText="Indlæser..."
                noOptionsText="Ingen muligheder"
                popupIcon={<KeyboardArrowDownIcon />}
                isOptionEqualToValue={props.isOptionEqualToValue}
                renderInput={({ inputProps, ...rest }) =>
                    <TextField {...rest} placeholder={"Vælg..."} inputProps={{ ...inputProps, readOnly: props.disableTyping }} />
                }
                renderOption={(optionProps, option) => {
                    const item = props.getOptionLabel ? props.getOptionLabel(option) : option;
                    return (
                      <li
                        {...optionProps}
                        key={option.tags ? option.tags.id : item}
                        style={{wordBreak: "normal", overflowWrap: "anywhere"}}
                      >
                        {item}
                      </li>
                    );
                }}
                value={props.selected}
                onChange={(event: any, newValue: any) => {
                    props.setSelected(newValue);
                    updateQueryParam(props.param, newValue ? newValue.id.toString() : null, false);

                    props.setDayViewState({isOpen: false, date: ""});
                    updateQueryParam("day", null, true);

                    if (props.param === "campus") {
                        updateQueryParam("org", null, true);
                        updateQueryParam("site", null, true);
                    }
                }}
            />
        </Card>
    );
}
