import { DistEntity } from "../models/DistEntity";
import { Organization } from "../types/Organization";
import { getEntities } from "./api/EntityService";

/** 
 * Returns a list of all organizations within the selected campus fetched from the API. 
 * @param {DistEntity[]} distributions - List of distribution entities retrieved from DistributionService.getDistEntities() 
 * which is needed to filter the organizations by the selected campus
 * @param {AbortSignal} [signal] - Passed to the API fetch request so that it can be cancelled if necessary
 * @returns An array of organizations
*/
async function getAllOrganizations(distributions: DistEntity[], signal?: AbortSignal): Promise<Organization[]> {
    const orgEntities = await getEntities(null, null, ["organisation"], null, signal);
    const filteredOrgEntities = orgEntities.filter(entity => !entity.tags.markerTags.some(x => x === "hidden"));

    const mapped = distributions.map(dist => ({id: dist.organisationRef.legacyId, name: dist.organisationRef.dis}));
    const removedDuplicates = mapped.filter((org, index, arr) => arr.findIndex(org2 => (org2.id === org.id)) === index);
    const filtered = removedDuplicates.filter(org => filteredOrgEntities.find(entity => org.id === entity.tags.id));
    const sorted = filtered.sort((o1, o2) => o1.name.localeCompare(o2.name));
    return sorted;
}

const exported = {
    getAllOrganizations
};

export default exported;
