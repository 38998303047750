import { Player } from "@lottiefiles/react-lottie-player";
import { Box, Alert } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react";
import { cache } from "../..";
import NoDataError from "../../errors/NoDataError";
import NoPointError from "../../errors/NoPointError";
import { DistEntity } from "../../models/DistEntity";
import { TimeValue } from "../../models/TimeValue";
import { DistributionType } from "../../services/EnergyDistibutionCalculations";
import EnergyService from "../../services/EnergyService";
import { Campus } from "../../types/Campus";
import { DateSelection } from "../../types/DateSelection";
import { EnergyType } from "../../types/EnergyType";
import { Organization } from "../../types/Organization";
import { Site } from "../../types/Site";
import { UsageType } from "../../types/UsageType";
import ConsumptionGraph from "./ConsumptionGraph";
import LoadDurationGraph from "./LoadDurationGraph";

interface UsageDetailsContentProps {
    energyType: EnergyType,
    energyValues: {selection: TimeValue[], lastYear: TimeValue[]},
    selectedDate: DateSelection,
    setSelectedDate: Dispatch<SetStateAction<DateSelection>>,
    selectedCampus: Campus,
    selectedOrganization: Organization | null,
    selectedSite: Site | null,
    allSites: Site[],
    distributions: DistEntity[],
    dayViewState: {isOpen: boolean, date: string},
    setDayViewState: Dispatch<SetStateAction<{isOpen: boolean, date: string}>>,
    setNavStack: Dispatch<SetStateAction<DateSelection[]>>,
    isLoading: number,
    error: Error | null,
    graphState: {isOpen: boolean, usageType: UsageType, date: string},
    graphType: string,
    isLoadingDetails: boolean,
    setIsLoadingDetails: Dispatch<SetStateAction<boolean>>
}

export function UsageDetailsContent(props: UsageDetailsContentProps) {
    // Data for the day view (hour values for the selected day)
    const [dayValues, setDayValues] = useState<{selection: TimeValue[], lastYear: TimeValue[]}>({selection: [], lastYear: []});

    // Data for the load duration graph (when "Belastningstid" is selected in the dropdown)
    const [loadDurationValues, setLoadDurationValues] = useState<{selection: TimeValue[], lastYear: TimeValue[]}>({selection: [], lastYear: []});

    // Error to be shown if any occurs while fetching data for the load duration graph
    const [loadDurationError, setLoadDurationError] = useState<Error | null>(null);

    // Error to be shown if any occurs while fetching data for the day view
    const [dayError, setDayError] = useState<Error | null>(null);

    const controllerDayRef = useRef<AbortController | null>();
    const controllerLoadDurationRef = useRef<AbortController | null>();

    let detailsError = props.error;
    let detailsEnergyValues = props.energyValues;

    // Swaps between data sets depending on whether the user is in year/month/week view, day view or load duration view
    if (props.dayViewState.isOpen) {
        detailsError = dayError;
        detailsEnergyValues = dayValues;
    } else if (props.graphType === "Belastningstid") {
        detailsError = loadDurationError;
        detailsEnergyValues = loadDurationValues;
    }

    const lastYearWeek = (props.selectedDate.week !== undefined && props.selectedDate.week === 53) ? 52 : props.selectedDate.week;

    // Fetching API data for the load duration graph when it is being viewed
    useLayoutEffect(() => {
        if (controllerLoadDurationRef.current) {
            controllerLoadDurationRef.current.abort();
        }

        controllerLoadDurationRef.current = new AbortController();

        if (props.graphType === "Belastningstid" && !props.dayViewState.isOpen) {
            setLoadDurationValues({selection: [], lastYear: []});
            setLoadDurationError(null);

            const searchParams = new URLSearchParams(window.location.search);
            const cachedValue: any = cache.get(searchParams.toString());

            if (cachedValue) {
                setLoadDurationValues(cachedValue.loadDurationValues);
                setLoadDurationError(cachedValue.error);

                // Necessary in some cases where loading was not previously set to finished (not sure why it happens)
                props.setIsLoadingDetails(false);

                return;
            }

            props.setIsLoadingDetails(true);

            getHourValues().then(() => props.setIsLoadingDetails(false)).catch(() => {});
        }

        async function getHourValues(): Promise<void> {
            let today = dayjs();

            // See the same variable in App.tsx or DateParser.tsx for explanation of what it does
            let isDateFinished = true;

            if (
                (props.selectedDate.year === today.year() && props.selectedDate.week === today.isoWeek()) ||
                (props.selectedDate.year === today.year() && props.selectedDate.month === today.month())
            ) {
                isDateFinished = false;
            }

            try {
                let selection: TimeValue[] = [];
                if (props.energyType === EnergyType.ELEC) {
                    selection = await EnergyService.getEnergyUsageInHours(
                        props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                        props.distributions, props.allSites, isDateFinished, props.selectedDate.year, props.selectedDate.month,
                        props.selectedDate.week, DistributionType.CATEGORISED, controllerLoadDurationRef.current?.signal
                    )
                } else {
                    selection = await EnergyService.getEnergyUsageInHours(
                        props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                        props.distributions, props.allSites, isDateFinished, props.selectedDate.year, props.selectedDate.month,
                        props.selectedDate.week, DistributionType.STANDARD, controllerLoadDurationRef.current?.signal
                    )
                }

                setLoadDurationValues(prev => ({...prev, selection: selection}));

                try
                {
                    let lastYear: TimeValue[] = [];
                    if (props.energyType === EnergyType.ELEC) {
                        selection = await EnergyService.getEnergyUsageInHours(
                            props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                            props.distributions, props.allSites, isDateFinished, props.selectedDate.year - 1, props.selectedDate.month,
                            lastYearWeek, DistributionType.CATEGORISED, controllerLoadDurationRef.current?.signal
                        );
                    } else {
                        selection = await EnergyService.getEnergyUsageInHours(
                            props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                            props.distributions, props.allSites, isDateFinished, props.selectedDate.year - 1, props.selectedDate.month,
                            lastYearWeek, DistributionType.STANDARD, controllerLoadDurationRef.current?.signal
                        );
                    }
                    setLoadDurationValues(prev => ({...prev, lastYear: lastYear}));
                } catch (error: any) {
                    if (error.name === "AbortError") throw error;
                }
            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                setLoadDurationError(error);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.graphType, props.dayViewState, props.selectedDate, props.selectedCampus, props.selectedOrganization, props.selectedSite]);

    // Fetching API data for the day view when the user has clicked on a bar representing a day
    useLayoutEffect(() => {
        if (controllerDayRef.current) {
            controllerDayRef.current.abort();
        }

        controllerDayRef.current = new AbortController();

        if (props.dayViewState.isOpen) {
            setDayValues({selection: [], lastYear: []});
            setDayError(null);

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete("graph");
            const cachedValue: any = cache.get(searchParams.toString());

            if (cachedValue) {
                setDayValues(cachedValue.dayValues);
                setDayError(cachedValue.error);

                // Necessary in some cases where loading was not previously set to finished (not sure why it happens)
                props.setIsLoadingDetails(false);

                return;
            }

            props.setIsLoadingDetails(true);

            getDayValues().then(() => props.setIsLoadingDetails(false)).catch(() => {});
        }

        async function getDayValues(): Promise<void> {
            try {
                let selection: TimeValue[] = [];
                if (props.energyType === EnergyType.ELEC) {
                    selection = await EnergyService.getEnergyDayUsage(
                        props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                        props.distributions, props.allSites, props.dayViewState.date, DistributionType.CATEGORISED, controllerDayRef.current?.signal
                    );

                } else {
                    selection = await EnergyService.getEnergyDayUsage(
                        props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                        props.distributions, props.allSites, props.dayViewState.date, DistributionType.STANDARD, controllerDayRef.current?.signal
                    );

                }

                setDayValues(prev => ({...prev, selection: selection}));

                try {
                    let lastYear: TimeValue[] = [];

                    if (props.energyType === EnergyType.ELEC) {
                        lastYear = await EnergyService.getEnergyDayUsage(
                            props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                            props.distributions, props.allSites, dayjs(props.dayViewState.date).subtract(1, "year").format(), DistributionType.CATEGORISED, controllerDayRef.current?.signal
                        );

                    } else {
                        lastYear = await EnergyService.getEnergyDayUsage(
                            props.energyType, props.selectedCampus, props.selectedOrganization, props.selectedSite,
                            props.distributions, props.allSites, dayjs(props.dayViewState.date).subtract(1, "year").format(), DistributionType.STANDARD, controllerDayRef.current?.signal
                        );

                    }

                    setDayValues(prev => ({...prev, lastYear: lastYear}));
                } catch (error: any) {
                    if (error.name === "AbortError") throw error;
                }
            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                setDayError(error);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dayViewState]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("graph");

        // If the API request (for day values) has completed and there is no cached entry
        if (props.dayViewState.isOpen && !props.isLoadingDetails && !cache.peek(searchParams.toString()) && dayValues.selection.length) {
            cache.set(searchParams.toString(), {dayValues: dayValues, error: dayError});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingDetails, dayValues, dayError]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        // If the API request (for load duration values) has completed and there is no cached entry
        if (
            props.graphType === "Belastningstid" &&
            !props.dayViewState.isOpen &&
            !props.isLoadingDetails &&
            !cache.peek(searchParams.toString()) &&
            loadDurationValues.selection.length
        ) {
            cache.set(searchParams.toString(), {loadDurationValues: loadDurationValues, error: loadDurationError});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.isLoadingDetails, loadDurationValues, loadDurationError]);

    const isLoadingBool = props.isLoading === 100 ? false : true;
    const displayLoad = props.graphType === "Belastningstid" || props.dayViewState.isOpen ? props.isLoadingDetails : isLoadingBool;

    if (displayLoad) {
        // If the API calls for the data have not all returned yet, show Lottie animations
        return (
            <Box position="absolute" margin="auto" top="50%" left={0} right={0} sx={{transform: "translateY(-50%)"}}>
                <Player
                    autoplay
                    loop
                    src={props.energyType.loadingIcon}
                    style={{ height: "125px", width: "125px" }}
                />
            </Box>
        );
    } else if (detailsError && detailsEnergyValues.selection.length === 0) {
        // If an error has occurred
        if (detailsError instanceof NoDataError) {
            return (
                <Alert severity="info">
                    For den valgte periode er der ikke fyldestgørende data. Kontakt eventuelt Campus Service BMS.
                </Alert>
            );
        } else if (detailsError instanceof NoPointError) {
            return (
                <Alert severity="info">
                    Den valgte bygning har ikke nogle målinger for {props.energyType.displayName.toLowerCase()}.
                </Alert>
            );
        } else {
            return (
                <Alert severity="error">
                    Der opstod en fejl under hentning af data ({detailsError.message}).
                </Alert>
            );
        }
    } else {
        // Graph type is determined by what has been chosen in the dropdown
        if (props.graphType === "Forbrug") {
            return (
                <ConsumptionGraph
                    energyType={props.energyType} energyValues={detailsEnergyValues}
                    selectedDate={props.selectedDate} setSelectedDate={props.setSelectedDate}
                    dayViewState={props.dayViewState} setDayViewState={props.setDayViewState}
                    graphState={props.graphState} setNavStack={props.setNavStack}
                />
            )
        } else {
            return (
                <LoadDurationGraph
                    energyValues={detailsEnergyValues} energyType={props.energyType}
                    selectedDate={props.selectedDate} dayViewState={props.dayViewState}
                />
            )
        }
    }
}
