import dayjs from "dayjs";
import { setWeekInYear } from "../../util/DateUtils";

/**
 * Parses the user's selection of a year, month and week into 'from', 'to' and 'interval' values for the API.
 *
 * Example 1: calling parseDate(true, 2022, undefined, 18) will return ["2022-05-02", "2022-05-09", "P1D"].
 * The API will return 7 values because the range covers 7 days and the interval is set to 1 day. Similarly, if asking for data with a range of 1 year,
 * the method will return an interval of P1M which means the API will return 12 values - one for each month.
 *
 * Example 2: If isDateFinished is set to false, then the range will be from the start of the year to the current day and month but with the chosen year.
 * Same goes for month and week. In addition, if only a year is selected, then the interval will be changed from "P1M" to "P1D". This is used to properly
 * compare data from the current, unfinished year/month/week with the same period of time in the previous year/month/week.
 *
 * @param {boolean} isDateFinished - Determines the date range to get data from
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional) (zero-indexed, so must be 0-11)
 * @param {number} [week] - The selected week (optional)
 * @returns Object containing the 'from', 'to' and 'interval' values
 */
export function parseDate(isDateFinished: boolean, year: number, month?: number, week?: number): { from: string, to: string, interval: string } {
    let from = dayjs(year.toString());
    let to = from.add(1, "year");
    let interval = "P1M";

    // If the selected year is not yet over
    if (!isDateFinished) {
        to = dayjs().set("year", year);
        interval = "P1D";
    }

    if (month !== undefined) {
        from = from.set("month", month);
        to = from.add(1, "month");
        interval = "P1D";

        // If the selected month is not yet over
        if (!isDateFinished) {
            to = dayjs().set("year", year).set("month", month);
        }
    }

    if (week !== undefined) {
        from = setWeekInYear(from, week);
        to = from.add(7, "day");
        interval = "P1D";

        // If the selected week is not yet over
        if (!isDateFinished) {
            to = from.isoWeekday(dayjs().isoWeekday());
        }
    }

    const formatStr = "YYYY-MM-DD";

    return {
        from: from.format(formatStr),
        to: to.format(formatStr),
        interval: interval
    }
}
