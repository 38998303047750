import { Box, Card, CircularProgress, Typography, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { UsageType } from "../../types/UsageType";
import { ReactComponent as EnergyIcon } from "../../assets/images/live_energy.svg";
import { ReactComponent as PriceIcon } from "../../assets/images/live_price.svg";
import { ReactComponent as EmissionsIcon } from "../../assets/images/live_emissions.svg";

interface LiveValueBoxProps {
    liveValue: number | null,
    type: UsageType,
    graphState: {isOpen: boolean, usageType: UsageType, date: string},
    setGraphState: Dispatch<SetStateAction<{isOpen: boolean, usageType: UsageType, date: string}>>,
    loading: boolean,
    notImplemented?: boolean
}

export default function LiveValueBox(props: LiveValueBoxProps) {
    const theme = useTheme();

    const [iconColor, setIconColor] = useState(theme.palette.primary.light);

    const open = props.graphState.isOpen && props.graphState.usageType === props.type;

    let typeStr = "";
    let unit = "";
    let icon = <></>;

    switch (props.type) {
        case UsageType.ENERGY:
            typeStr = "Elforbrug";
            unit = "kW";
            icon = <EnergyIcon fill={open ? "white" : iconColor} />;
            break;
        case UsageType.PRICE:
            typeStr = "Omkostninger";
            unit = "DKK pr. time";
            icon = <PriceIcon fill={open ? "white" : iconColor} />;
            break;
        case UsageType.EMISSIONS:
            typeStr = "Udledning";
            unit = "kg pr. time";
            icon = <EmissionsIcon fill={open ? "white" : iconColor} />;
            break;
    }

    function handleClick() {
        props.setGraphState(prev => {
            if (prev.usageType === props.type) {
                return {isOpen: !prev.isOpen, usageType: props.type, date: prev.date};
            } else {
                return {isOpen: true, usageType: props.type, date: prev.date};
            }
        });
    }

    return (
        <Card
            onMouseOver={() => setIconColor(theme.palette.primary.dark)}
            onMouseOut={() => setIconColor(theme.palette.primary.light)}
            onClick={handleClick}
            sx={{
                p: 1.5,
                width: "100%",
                maxWidth: 200,
                display: "flex",
                position: "relative",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: open ? theme.palette.primary.dark : "white",
                boxShadow: "none",
                border: open ? "3px solid " + theme.palette.primary.dark : "3px solid white",
                "&:hover": {
                    border: "3px solid " + theme.palette.primary.dark
                }
            }}
        >
            {icon}
            { props.loading
                ? <Box mt={1} mb={0.25}>
                    <CircularProgress disableShrink size="1.75rem" thickness={5} sx={{color: open ? "white" : theme.palette.primary.dark}} />
                </Box>
                : <>
                    <Typography
                        fontFamily="NeoSansProBold"
                        fontSize="1.75rem"
                        lineHeight={1}
                        mt={1}
                        mb={1}
                        color={open ? "white" : theme.palette.primary.dark}
                        visibility={props.notImplemented ? "hidden" : "visible"}
                    >
                        {props.liveValue !== null ? Math.round(props.liveValue).toLocaleString("da-DK") : "--"}
                    </Typography>
                    { props.notImplemented &&
                        <Typography
                            position="absolute"
                            fontFamily="NeoSansProBold"
                            fontSize={{xs: "1.1rem", sm: "1rem", xxl: "1.1rem"}}
                            lineHeight={1}
                            border={open ? "3px solid white" : "3px solid gray"}
                            color={open ? "white" : "gray"}
                            p={1}
                            top={40}
                            sx={{transform: "rotate(-25deg)", opacity: 0.25}}
                        >
                            COMING SOON
                        </Typography>
                    }
                </>
            }
            <Typography fontFamily="NeoSansProBold" fontSize="0.9rem" lineHeight={1} color={open ? "white" : "black"} mb={0.5}>
                {typeStr}
            </Typography>
            <Typography fontSize="0.7rem" color={open ? "white" : "black"}>{unit}</Typography>
        </Card>
    )
}
