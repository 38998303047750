import { Card, SelectChangeEvent, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import { parseDate } from "../../services/api/DateParser";
import { DateSelection } from "../../types/DateSelection";
import { updateQueryParam } from "../../util/ParamUtils";
import DateDropdown from "./DateDropdown";

interface DateSelectorBoxProps {
    date: DateSelection,
    setDate: Dispatch<SetStateAction<DateSelection>>,
    setDetailNavStack: Dispatch<SetStateAction<DateSelection[]>>,
    setDayViewState: Dispatch<SetStateAction<{isOpen: boolean, date: string}>>
}

export default function DateSelectorBox(props: DateSelectorBoxProps) {
    // List of weeks shown in the "week" dropdown
    const [weekOptions, setWeekOptions] = useState<string[]>(
        Array.from(Array(dayjs(props.date.year.toString()).isoWeeksInYear()).keys()).map(i => (i + 1).toString())
    );

    const monthOptions: string[] = dayjs.months().map(month => month.charAt(0).toUpperCase() + month.slice(1));

    // The number of years to be displayed, corresponding to the size of the yearOptions list
    const numberOfYears: number = dayjs().year() - 2019 + 1;

    // Creates an array from the current year to 2019
    const yearOptions: string[] = Array.from(Array(numberOfYears).keys())
        .map(i => (i + 2019).toString())
        .reverse();

    const today = dayjs();
    let isDateFinished = true;

    // If the selection is the current year/month/week
    if (
        props.date.year === today.year() &&
        (props.date.month === today.month() || props.date.month === undefined) &&
        (props.date.week === today.isoWeek() || props.date.week === undefined)
    ) {
        isDateFinished = false;
    }

    const range = parseDate(isDateFinished, props.date.year, props.date.month, props.date.week);

    const dateStart = dayjs(range.from).format("DD/MM");
    const dateEnd = dayjs(range.to).subtract(1, "day").format("DD/MM");

    function changeWeek(e: SelectChangeEvent<string>) {
        const selectedWeek: string = e.target.value;

        props.setDetailNavStack([]);

        updateQueryParam("week", selectedWeek === "Alle" ? null : selectedWeek, false);

        props.setDayViewState({isOpen: false, date: ""});
        updateQueryParam("day", null, true);

        if (selectedWeek === "Alle") {
            props.setDate(prevDate => ({...prevDate, week: undefined}));
        } else {
            props.setDate(prevDate => ({...prevDate, month: undefined, week: parseInt(selectedWeek)}));
            updateQueryParam("month", null, true);
        }
    }

    function changeMonth(e: SelectChangeEvent<string>) {
        const selectedMonth: string = e.target.value;
        const selectedMonthIndex = monthOptions.indexOf(selectedMonth);

        props.setDetailNavStack([]);

        updateQueryParam("month", selectedMonth === "Alle" ? null : selectedMonthIndex.toString(), false);

        props.setDayViewState({isOpen: false, date: ""});
        updateQueryParam("day", null, true);

        if (selectedMonth === "Alle") {
            props.setDate(prevDate => ({...prevDate, month: undefined}));
        } else {
            props.setDate(prevDate => ({...prevDate, month: selectedMonthIndex, week: undefined}));
            updateQueryParam("week", null, true);
        }
    }

    function changeYear(e: SelectChangeEvent<string>) {
        const selectedYear: string = e.target.value;

        props.setDetailNavStack([]);

        updateQueryParam("year", selectedYear, false);

        props.setDayViewState({isOpen: false, date: ""});
        updateQueryParam("day", null, true);

        props.setDate(prevDate => {
            if (prevDate.week === 53 && dayjs(selectedYear).isoWeeksInYear() < 53) {
                return {...prevDate, year: parseInt(selectedYear), week: 52}
            } else {
                return {...prevDate, year: parseInt(selectedYear)};
            }
        });

        setWeekOptions(Array.from(Array(dayjs(selectedYear).isoWeeksInYear()).keys()).map(i => (i + 1).toString()));
    }

    return (
        <Card sx={{ py: 1, px: 2 }}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{xs: "left", sm: "center"}}
                justifyContent="space-between"
                spacing={1}
            >
                <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                >
                    <DateDropdown label="År" options={yearOptions} selected={props.date.year.toString()}
                        hasAllOption={false} handleChange={changeYear}
                    />
                    <DateDropdown label="Måned" options={monthOptions}
                        selected={props.date.month === undefined ? "Alle" : monthOptions[props.date.month]}
                        hasAllOption={true} handleChange={changeMonth}
                    />
                    <DateDropdown label="Uge" options={weekOptions}
                        selected={props.date.week === undefined ? "Alle" : props.date.week.toString()}
                        hasAllOption={true} handleChange={changeWeek}
                    />
                </Stack>
                <Typography fontSize="1.25rem">Periode: {dateStart} - {dateEnd}</Typography>
            </Stack>
        </Card>
    );
}
