import { Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useMemo } from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { TimeValue } from "../../models/TimeValue";
import { DateSelection } from "../../types/DateSelection";
import { EnergyType } from "../../types/EnergyType";

interface LoadDurationGraphProps {
    energyValues: {selection: TimeValue[], lastYear: TimeValue[]},
    energyType: EnergyType,
    selectedDate: DateSelection,
    dayViewState: {isOpen: boolean, date: string}
}

export default function LoadDurationGraph(props: LoadDurationGraphProps) {
    // This is only necessary in order to use superscript for m3
    const unit = props.energyType.unit === "m3" ? <>m<sup>3</sup></> : props.energyType.unit;

    let legendSelection = props.selectedDate.year.toString();
    let legendLastYear = (props.selectedDate.year - 1).toString();

    if (props.dayViewState.isOpen) {
        legendSelection = dayjs(props.dayViewState.date).format("D. MMMM YYYY");
        legendLastYear = dayjs(props.dayViewState.date).subtract(1, "year").format("D. MMMM YYYY");
    } else if (props.selectedDate.month !== undefined) {
        legendSelection = dayjs(props.selectedDate.year.toString()).set("month", props.selectedDate.month).format("MMMM YYYY");
        legendLastYear = dayjs((props.selectedDate.year - 1).toString()).set("month", props.selectedDate.month).format("MMMM YYYY");
        legendSelection = legendSelection.charAt(0).toUpperCase() + legendSelection.slice(1);
        legendLastYear = legendLastYear.charAt(0).toUpperCase() + legendLastYear.slice(1);
    } else if (props.selectedDate.week !== undefined) {
        legendSelection = "Uge " + props.selectedDate.week + " " + props.selectedDate.year;
        const weekTmp = props.selectedDate.week === 53 ? 52 : props.selectedDate.week;
        legendLastYear = "Uge " + weekTmp + " " + (props.selectedDate.year - 1);
    }

    // useMemo prevents the line animations from being interrupted when the data changes
    // https://github.com/recharts/recharts/issues/846#issuecomment-808350385
    const data = useMemo(() => {
        const sortedSelection = [...props.energyValues.selection];
        sortedSelection.sort((a, b) => b.value - a.value);

        const sortedLastYear = [...props.energyValues.lastYear];
        sortedLastYear.sort((a, b) => b.value - a.value);

        // In case there is missing data in one dataset, always map over the largest array
        if (sortedSelection.length < sortedLastYear.length) {
            return sortedLastYear.map((timeValue, index) => (
                {
                    pct: (index + 1) / sortedLastYear.length * 100,
                    selection: index < sortedSelection.length ? sortedSelection[index].value : null,
                    lastYear: timeValue.value
                }
            ));
        } else {
            return sortedSelection.map((timeValue, index) => (
                {
                    pct: (index + 1) / sortedSelection.length * 100,
                    selection: timeValue.value,
                    lastYear: index < sortedLastYear.length ? sortedLastYear[index].value : null
                }
            ));
        }
    }, [props.energyValues]);

    const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
        if (active && payload && payload.length) {
            const lastYear = payload[0].payload.lastYear ?? "--";
            const selection = payload[0].payload.selection ?? "--";

            let dateSelection = props.selectedDate.year.toString();
            let dateLastYear = (props.selectedDate.year - 1).toString();

            return (
                <Box p={1.5} borderRadius="5px" border="1px solid black" sx={{backgroundColor: "white"}}>
                    <Typography lineHeight={1} mb={1}>
                        {label.toLocaleString("da-DK", {maximumFractionDigits: 1}) + "%"}
                    </Typography>
                    <Typography lineHeight={1} color="#c5c5c5">
                        {dateLastYear}: {" "}
                        {lastYear.toLocaleString("da-DK", {maximumFractionDigits: props.energyType.decimals})} {" "}
                        {unit}
                    </Typography>
                    <Typography lineHeight={1} mt={1} color={props.energyType.mainColor} fontFamily="NeoSansProBold">
                        {dateSelection}: {" "}
                        {selection.toLocaleString("da-DK", {maximumFractionDigits: props.energyType.decimals})} {" "}
                        {unit}
                    </Typography>
                </Box>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={330}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{ right: 50 }}
            >
                <CartesianGrid strokeDasharray="2 2" />
                <XAxis
                    type="number" dataKey="pct" tickSize={8} tickMargin={5}
                    ticks={[0, 25, 50, 75, 100]} tickFormatter={tick => tick + "%"}
                />
                <YAxis
                    width={100} tickSize={8} tickMargin={5} domain={["auto", "auto"]}
                    tickFormatter={tick => tick.toLocaleString("da-DK", {maximumFractionDigits: props.energyType.decimals})}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ outline: "none" }}
                    isAnimationActive={false}
                />
                <Legend
                    wrapperStyle={{paddingLeft: 70}}
                    formatter={(value, entry, index) => (
                        <Typography
                            display="inline" position="relative" top="2px" marginRight={2}
                            fontFamily={index === 0 ? "NeoSansProRegular" : "NeoSansProBold"}
                        >
                            {value}
                        </Typography>
                    )}
                    payload={[
                        { id: "lastYear", value: legendLastYear, type: "line", color: "#c5c5c5"},
                        { id: "selection", value: legendSelection, type: "line", color: props.energyType.mainColor}
                    ]}
                />
                <Line type="monotone" dataKey="lastYear" stroke="#c5c5c5" strokeWidth={2} strokeDasharray="8 8" animationDuration={1000} dot={false} />
                <Line type="monotone" dataKey="selection" stroke={props.energyType.mainColor} strokeWidth={3} animationDuration={1000} dot={false} />
            </LineChart>
        </ResponsiveContainer>
    )
}
