import { Box, Card, CssBaseline, Grid, IconButton, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AuthContextProps, useAuth } from 'react-oidc-context';
import TotalUsageDashboard from './components/total-usage/TotalUsageDashboard';
import Toolbar from './components/toolbar/Toolbar';
import EnergyService from './services/EnergyService';
import dayjs from 'dayjs';
import { EnergyData } from './types/EnergyData';
import { EnergyType } from './types/EnergyType';
import bottomWaveGreen from "./assets/images/bottom_wave_green.svg";
import { TimeValue } from './models/TimeValue';
import { UsageType } from './types/UsageType';
import { setWeekInYear } from './util/DateUtils';
import DataTodayService from './services/DataTodayService';
import { Site } from './types/Site';
import { Campus } from './types/Campus';
import { Organization } from './types/Organization';
import { Warning } from './types/Warning';
import { DistEntity } from './models/DistEntity';
import UsageDetailsBox from './components/usage-details/UsageDetailsBox';
import { DateSelection } from './types/DateSelection';
import LiveUsageView from './components/live-values/LiveUsageView';
import LiveGraphBox from './components/live-values/LiveGraphBox';
import { getCacheSearchParams, updateQueryParam } from './util/ParamUtils';
import { theme } from './theme';
import { cache } from '.';
import { WarningTwoTone } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { DistributionType } from './services/EnergyDistibutionCalculations';

export let auth : AuthContextProps;

// List of all campuses (currently hardcoded, will become state if fetched from API in the future)
export const allCampuses: Campus[] = [
    {id: 28643, siteId: 137487, siteGuid: "8f6b40d7-75a2-49b5-a57e-a6606e07ff06", name: "Lyngby"},
    {id: 137424, siteId: 137669, siteGuid: "9c1a684a-051d-408a-812b-2b5394a779bc", name: "Ballerup"},
    {id: 134006, siteId: 151989, siteGuid: "52be5396-ec7a-4057-aa50-b6ac4c4d62c2", name: "Risø"}
];

export default function App()
{
    const [warningOpen, setWarningOpen] = useState(false);

    const searchParams = new URLSearchParams(window.location.search);

    // Energy use numbers for the overview boxes (based on date) - null when API call fails or returns nothing
    const [elecData, setElecData] = useState<EnergyData>({selection: null, lastYear: null, previous: null});
    const [waterData, setWaterData] = useState<EnergyData>({selection: null, lastYear: null, previous: null});
    const [heatData, setHeatData] = useState<EnergyData>({selection: null, lastYear: null, previous: null});
    const [coolingData, setCoolingData] = useState<EnergyData>({selection: null, lastYear: null, previous: null});

    // Energy use numbers to populate the graph in the detailed view when clicking an overview box's icon
    const [elecTimeSeries, setElecTimeSeries] = useState<{selection: TimeValue[], lastYear: TimeValue[]}>({selection: [], lastYear: []});
    const [waterTimeSeries, setWaterTimeSeries] = useState<{selection: TimeValue[], lastYear: TimeValue[]}>({selection: [], lastYear: []});
    const [heatTimeSeries, setHeatTimeSeries] = useState<{selection: TimeValue[], lastYear: TimeValue[]}>({selection: [], lastYear: []});
    const [coolingTimeSeries, setCoolingTimeSeries] = useState<{selection: TimeValue[], lastYear: TimeValue[]}>({selection: [], lastYear: []});

    // All area distributions within the selected campus (used for filtering and calculating energy values)
    const [distributions, setDistributions] = useState<DistEntity[]>([]);

    // List of all sites and organizations in the selected campus - populated in Toolbar.tsx
    const [allOrganizations, setAllOrganizations] = useState<Organization[]>([]);
    const [allSites, setAllSites] = useState<Site[]>([]);

    // Set the initially selected campus based on corresponding search param
    const initialCampus = allCampuses.find(campus => campus.id.toString() === searchParams.get("campus")) ?? allCampuses[0];

    // The selected campus/site/organization in the three dropdowns
    const [selectedCampus, setSelectedCampus] = useState<Campus>(initialCampus);
    const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
    const [selectedSite, setSelectedSite] = useState<Site | null>(null);

    // Used to navigate browser history with search params (see useEffect below where it gets used)
    const allOrganizationsRef = useRef<Organization[]>(allOrganizations);
    const allSitesRef = useRef<Site[]>(allSites);

    const yearStr = searchParams.get("year");
    const monthStr = searchParams.get("month");
    const weekStr = searchParams.get("week");

    // Set the initially selected date based on corresponding search params
    const initialDate = {
        year: yearStr ? parseInt(yearStr) : dayjs().year(),
        month: monthStr ? parseInt(monthStr) : undefined,
        week: weekStr ? parseInt(weekStr) : undefined
    };

    // The currently selected date in the date dropdown
    const [selectedDate, setSelectedDate] = useState<DateSelection>(initialDate);

    // Data for the three live value graphs
    const [energyValues, setEnergyValues] = useState<TimeValue[]>([]);
    const [energyPrices, setEnergyPrices] = useState<TimeValue[]>([]);
    const [energyTariffs, setEnergyTariffs] = useState<TimeValue[]>([]);
    const [emissions, setEmissions] = useState<TimeValue[]>([]);
    const [emissionPrognosis, setEmissionPrognosis] = useState<TimeValue[]>([]);

    // Determines whether the live value graph box is open and which graph type is displayed
    // The 'date' prop is used for the price graph to denote which day is currently being shown
    const [graphState, setGraphState] = useState({isOpen: false, usageType: UsageType.ENERGY, date: dayjs().format()});

    const typeStr = searchParams.get("type");

    // Determines whether the detailed view with the expanded graph is open (null = closed) and which energy type is displayed
    const [detailViewState, setDetailViewState] = useState<EnergyType | null>(typeStr ? EnergyType.getType(typeStr) : null);

    const dayStr = searchParams.get("day");
    const date = dayStr ? dayjs(dayStr).format() : "";

    // Used to control when the graph day view is shown with hourly values (true when the user clicks a bar in month or week selection in detailed view)
    const [dayViewState, setDayViewState] = useState({isOpen: dayStr ? true : false, date: date});

    // Saves the order in which the user navigates through the detailed view by clicking bars (used by the X button to go backwards)
    const [detailNavStack, setDetailNavStack] = useState<DateSelection[]>([]);

    const graphStr = searchParams.get("graph");

    // The selected graph type in the dropdown located in the top right of the detail view box
    const [graphType, setGraphType] = useState(graphStr ? graphStr.charAt(0).toUpperCase() + graphStr.slice(1) : "Forbrug");

    // Used to start and stop the CSS animation for the energy icons at the bottom of each overview box
    const [animClass, setAnimClass] = useState("gelatine");

    // Shows whether the energy data has been received from the API yet - used to display loading icons
    // Numbers are in percentages (100 = finished) and array index corresponds to id in EnergyType
    const [isLoading, setIsLoading] = useState([0, 0, 0, 0]);

    // Shows whether the data for the three live value graphs has been received yet
    const [isGraphLoading, setIsGraphLoading] = useState({energy: true, price: true, emissions: true});

    // Any API errors that have to be presented to the user - array index corresponds to id in EnergyType
    // Errors are shown as a message in the overview boxes, warnings are shown as a small yellow exclamation mark next to the energy numbers
    const [errors, setErrors] = useState<(Error | null)[]>([null, null, null, null]);
    const [warnings, setWarnings] = useState(
        [
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE},
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE},
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE},
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE}
        ]
    );

    const controllerRef = useRef<AbortController | null>();
    const controllerGraphRef = useRef<AbortController | null>();

    auth = useAuth();

    // This will be false when the selected date is the same as today's date, which means we do
    // not have complete data and will have to compare with the same interval for lastYear and previous
    let isDateFinished = true;

    let today = dayjs();

    let prevYear = selectedDate.year;
    let prevMonth = selectedDate.month;
    let prevWeek = selectedDate.week;

    const lastYearWeek = (selectedDate.week !== undefined && selectedDate.week === 53) ? 52 : selectedDate.week;

    // Week view
    if (selectedDate.week !== undefined) {
        let date = setWeekInYear(dayjs(selectedDate.year.toString()), selectedDate.week);
        let prevDate = date.subtract(7, "day");
        prevWeek = prevDate.isoWeek();
        prevYear = prevDate.isoWeekYear();

        if (selectedDate.year === today.year() && selectedDate.week === today.isoWeek()) {
            isDateFinished = false;
        }
    // Month view
    } else if (selectedDate.month !== undefined) {
        let date = dayjs(`${selectedDate.year}-${selectedDate.month + 1}-01`);
        let prevDate = date.subtract(1, "month");
        prevMonth = prevDate.month();
        prevYear = prevDate.year();

        if (selectedDate.year === today.year() && selectedDate.month === today.month()) {
            isDateFinished = false;
        }
    // Year view
    } else {
        prevYear -= 2;

        if (selectedDate.year === today.year()) {
            isDateFinished = false;
        }
    }

    // Fetch data for the live value emissions graph
    useEffect(() => {
        let timeout = 0;

        const loadEmissions = () => {
            // Only shows the loading icon if the API is taking longer than 1 second to respond
            timeout = window.setTimeout(() => {
                setIsGraphLoading(prev => ({...prev, emissions: true}));
            }, 1000);

            const p1 = DataTodayService.getEmissions().then(emissions => setEmissions(emissions));
            const p2 = DataTodayService.getEmissionPrognosis().then(emissions => setEmissionPrognosis(emissions));

            Promise.all([p1, p2]).then(() => {
                clearTimeout(timeout);
                setIsGraphLoading(prev => ({...prev, emissions: false}));
            });
        };

        loadEmissions();
        const interval = setInterval(loadEmissions, 300000); // Fetch new data every 5 minutes

        return () => {
            clearTimeout(timeout);
            clearInterval(interval);
        }
    }, []);

    // Fetch data for the live value price graph
    useEffect(() => {
        if (controllerGraphRef.current) {
            controllerGraphRef.current.abort();
        }

        controllerGraphRef.current = new AbortController();

        const timeout = window.setTimeout(() => {
            setIsGraphLoading(prev => ({...prev, price: true}));
        }, 1000);

        const p1 = DataTodayService.getEnergyPrices(graphState.date, controllerGraphRef.current?.signal)
            .then(prices => setEnergyPrices(prices)).catch(() => {});

        const p2 = DataTodayService.getEnergyTariffs(graphState.date, controllerGraphRef.current?.signal)
            .then(tariffs => setEnergyTariffs(tariffs)).catch(() => {});

        Promise.all([p1, p2]).then(() => {
            clearTimeout(timeout);
            setIsGraphLoading(prev => ({...prev, price: false}));
        });

        return () => clearTimeout(timeout);
    }, [graphState.date]);

    // Fetch data for the live value energy graph
    useEffect(() => {
        let timeout = 0;

        const loadEnergy = () => {
            timeout = window.setTimeout(() => {
                setIsGraphLoading(prev => ({...prev, energy: true}));
            }, 1000);

            DataTodayService.getEnergyValues(selectedCampus).then(energy => {
                setEnergyValues(energy);
                clearTimeout(timeout);
                setIsGraphLoading(prev => ({...prev, energy: false}));
            });
        };

        loadEnergy();
        const interval = setInterval(loadEnergy, 1800000); // Fetch new data every 30 minutes

        return () => {
            clearTimeout(timeout);
            clearInterval(interval);
        }
    }, [selectedCampus]);

    useEffect(() => {
        updateQueryParam("campus", selectedCampus.id.toString(), true);
        updateQueryParam("year", selectedDate.year.toString(), true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // https://reactjs.org/docs/hooks-faq.html#why-am-i-seeing-stale-props-or-state-inside-my-function
        // Acts as a direct pointer to the two lists which is needed for the history listener below. This is necessary because the
        // listener is added on start up where the lists are empty as the API has not returned yet. Referencing the state directly
        // will then always return empty whereas the pointer will always reference the current value of the list.
        allOrganizationsRef.current = allOrganizations;
        allSitesRef.current = allSites;
    }, [allOrganizations, allSites]);

    useEffect(() => {
        // This listener runs whenever the user presses the back/forward button in the browser
        // This means the search params will change and the state needs to be updated manually
        const onHistoryChangeEvent = (event: PopStateEvent) => {
            const searchParams = new URLSearchParams(window.location.search);

            setDetailNavStack([]);

            const campusQ = searchParams.get("campus");
            const organizationQ = searchParams.get("org");
            const siteQ = searchParams.get("site");

            setSelectedCampus(allCampuses.find(campus => campus.id.toString() === campusQ) ?? allCampuses[0]);
            setSelectedOrganization(allOrganizationsRef.current.find(org => org.id.toString() === organizationQ) ?? null);
            setSelectedSite(allSitesRef.current.find(site => site.id.toString() === siteQ) ?? null);

            const yearQ = searchParams.get("year");
            const monthQ = searchParams.get("month");
            const weekQ = searchParams.get("week");

            const newDate = {
                year: yearQ ? parseInt(yearQ) : dayjs().year(),
                month: monthQ ? parseInt(monthQ) : undefined,
                week: weekQ ? parseInt(weekQ) : undefined
            };

            setSelectedDate(newDate);

            const typeQ = searchParams.get("type");

            setDetailViewState(typeQ ? EnergyType.getType(typeQ) : null);

            const dayQ = searchParams.get("day");
            const date = dayQ ? dayjs(dayQ).format() : "";

            setDayViewState({isOpen: dayQ ? true : false, date: date});

            const graphQ = searchParams.get("graph");

            graphQ && setGraphType(graphQ.charAt(0).toUpperCase() + graphQ.slice(1));
        }

        window.addEventListener("popstate", onHistoryChangeEvent);

        return () => window.removeEventListener("popstate", onHistoryChangeEvent);
    }, []);

    // Adds newly fetched energy values to the cache
    useEffect(() => {
        const params = getCacheSearchParams();

        // If the API requests have completed and there is no cached entry for this data
        if (!dayViewState.isOpen && isLoading.every(value => value === 100) && !cache.peek(params)) {
            const cachedValue = {
                data: {
                    elec: elecData,
                    water: waterData,
                    heat: heatData,
                    cooling: coolingData
                },
                series: {
                    elec: elecTimeSeries,
                    water: waterTimeSeries,
                    heat: heatTimeSeries,
                    cooling: coolingTimeSeries
                },
                warnings: warnings,
                errors: errors
            };

            cache.set(params, cachedValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isLoading, elecData, waterData, heatData, coolingData, elecTimeSeries,
        waterTimeSeries, heatTimeSeries, coolingTimeSeries, warnings, errors
    ]);

    // Fetches energy data from API based on the current user selection of campus/org/site/date
    useLayoutEffect(() => {
        // Return if we are viewing the load duration curve or day view (data for those is fetched in UsageDetailsContent)
        if (dayViewState.isOpen || (graphType === "Belastningstid" && detailViewState)) {
            return;
        }

        const searchParams = new URLSearchParams(window.location.search);

        // Return if the URL contains the org param and allSites or distributions lists have not received API data yet
        // (The lists are needed for org data fetch and, without this check, the fetch would run before those lists are filled)
        if (searchParams.get("org") && (!allSites.length || !distributions.length)) {
            return;
        }

        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();

        setElecData({selection: null, lastYear: null, previous: null});
        setWaterData({selection: null, lastYear: null, previous: null});
        setHeatData({selection: null, lastYear: null, previous: null});
        setCoolingData({selection: null, lastYear: null, previous: null});

        setElecTimeSeries({selection: [], lastYear: []});
        setWaterTimeSeries({selection: [], lastYear: []});
        setHeatTimeSeries({selection: [], lastYear: []});
        setCoolingTimeSeries({selection: [], lastYear: []});

        setErrors([null, null, null, null]);
        setWarnings([
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE},
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE},
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE},
            {selection: Warning.NONE, lastYear: Warning.NONE, previous: Warning.NONE}
        ]);

        const cachedValue: any = cache.get(getCacheSearchParams());

        if (cachedValue) {
            setElecData(cachedValue.data.elec);
            setWaterData(cachedValue.data.water);
            setHeatData(cachedValue.data.heat);
            setCoolingData(cachedValue.data.cooling);
            setElecTimeSeries(cachedValue.series.elec);
            setWaterTimeSeries(cachedValue.series.water);
            setHeatTimeSeries(cachedValue.series.heat);
            setCoolingTimeSeries(cachedValue.series.cooling);
            setWarnings(cachedValue.warnings);
            setErrors(cachedValue.errors);

            // Necessary in some cases where loading was not previously set to finished (not sure why it happens)
            setIsLoading([100, 100, 100, 100]);

            return;
        }

        setIsLoading([0, 0, 0, 0]);

        const stopLoading = (energyType: EnergyType) => {
            const timeoutId = setTimeout(() => {
                setIsLoading(prev => {
                    const newArr = [...prev];
                    newArr[energyType.id] = 100;
                    return newArr;
                });
            }, 350);

            controllerRef.current?.signal.addEventListener("abort", () => clearTimeout(timeoutId));
        };

        getEnergyData(EnergyType.ELEC, setElecData, setElecTimeSeries, DistributionType.CATEGORISED).then(() => stopLoading(EnergyType.ELEC)).catch(() => { });
        getEnergyData(EnergyType.WATER, setWaterData, setWaterTimeSeries, DistributionType.STANDARD).then(() => stopLoading(EnergyType.WATER)).catch(() => {});
        getEnergyData(EnergyType.HEAT, setHeatData, setHeatTimeSeries, DistributionType.STANDARD).then(() => stopLoading(EnergyType.HEAT)).catch(() => {});
        getEnergyData(EnergyType.COOLING, setCoolingData, setCoolingTimeSeries, DistributionType.STANDARD).then(() => stopLoading(EnergyType.COOLING)).catch(() => {});

        async function getEnergyData(
            energyType: EnergyType, setEnergyData: Dispatch<SetStateAction<EnergyData>>,
            setEnergyTimeSeries: Dispatch<SetStateAction<{ selection: TimeValue[], lastYear: TimeValue[] }>>,
            distributionType: DistributionType
        ): Promise<void> {
            const handleError = (error: any) => {
                if (error.name === "AbortError") {
                    throw error;
                }

                setErrors(prev => {
                    const newArr = [...prev];
                    newArr[energyType.id] = error;
                    return newArr;
                });
            }

            try {
                // The total energy use of a site based on selected date
                const selection = await EnergyService.getEnergyUsage(
                    energyType, selectedCampus, selectedOrganization, selectedSite, distributions, allSites,
                    isDateFinished, selectedDate.year, selectedDate.month, selectedDate.week, distributionType, controllerRef.current?.signal
                );
                setEnergyData(prev => ({...prev, selection: selection.value}));
                setEnergyTimeSeries(prev => ({...prev, selection: selection.timeSeries}));
                setWarnings(prev => {
                    const newArr = [...prev];
                    newArr[energyType.id] = {...newArr[energyType.id], selection: selection.warning};
                    return newArr;
                });
                setIsLoading(prev => {
                    const newArr = [...prev];
                    newArr[energyType.id] = 33;
                    return newArr;
                })

                try {
                    // The total energy use of a site in the same month/week one year ago (or the previous year when only year is selected)
                    const lastYear = await EnergyService.getEnergyUsage(
                        energyType, selectedCampus, selectedOrganization, selectedSite, distributions, allSites,
                        isDateFinished, selectedDate.year - 1, selectedDate.month, lastYearWeek, distributionType, controllerRef.current?.signal
                    );
                    setEnergyData(prev => ({...prev, lastYear: lastYear.value}));
                    setEnergyTimeSeries(prev => ({...prev, lastYear: lastYear.timeSeries}));
                    setWarnings(prev => {
                        const newArr = [...prev];
                        newArr[energyType.id] = {...newArr[energyType.id], lastYear: lastYear.warning};
                        return newArr;
                    });
                    setIsLoading(prev => {
                        const newArr = [...prev];
                        newArr[energyType.id] = 66;
                        return newArr;
                    })
                } catch (error: any) {
                    handleError(error);
                }

                try {
                    // The total energy use of a site based on the previous week/month or two years ago relative to the selected date
                    const previous = await EnergyService.getEnergyUsage(
                        energyType, selectedCampus, selectedOrganization, selectedSite, distributions, allSites,
                        isDateFinished, prevYear, prevMonth, prevWeek, distributionType, controllerRef.current?.signal
                    );
                    setEnergyData(prev => ({...prev, previous: previous.value}));
                    setWarnings(prev => {
                        const newArr = [...prev];
                        newArr[energyType.id] = {...newArr[energyType.id], previous: previous.warning};
                        return newArr;
                    });
                    setIsLoading(prev => {
                        const newArr = [...prev];
                        newArr[energyType.id] = 99;
                        return newArr;
                    })
                } catch (error: any) {
                    handleError(error);
                }
            } catch (error: any) {
                handleError(error);
            }
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization, selectedSite, selectedCampus, selectedDate, graphType, dayViewState, detailViewState, allSites, distributions]);

    let detailsData: {selection: TimeValue[], lastYear: TimeValue[]} = {selection: [], lastYear: []};

    switch (detailViewState) {
        case EnergyType.ELEC:
            detailsData = elecTimeSeries;
            break;
        case EnergyType.WATER:
            detailsData = waterTimeSeries;
            break;
        case EnergyType.HEAT:
            detailsData = heatTimeSeries;
            break;
        case EnergyType.COOLING:
            detailsData = coolingTimeSeries;
            break;
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box maxWidth={{xs: 450, sm: 900, md: 1000, xl: 1920}} mx="auto" px={{ xs: 3, md: 5, xxl: 8 }} py={{ xs: 3, md: 5 }}>
                {warningOpen && 
                    <Grid container spacing={4.5} mb={{ xs: 3, md: 4.5 }} columns={10} justifyContent="center" direction={{ xs: "column-reverse", xl: "row" }}>
                        <Grid item xs={12}>
                            <Card sx={{
                                height: "100%",
                                backgroundColor: "#ffefc0",
                                color: "white",
                                px: { xs: 3, md: 5 },
                                py: 2,
                                backgroundRepeat: "repeat-x",
                                backgroundPositionY: "bottom",
                                backgroundPositionX: "right",
                                backgroundSize: "auto 60%"
                            }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#ffefc0",
                                        alignItems: "center",
                                        padding: 1,
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            background: "#ffefc0",
                                            alignItems: "center",
                                            padding: 1,
                                            mr: 3
                                        }}
                                    >
                                        <WarningTwoTone fontSize="large"
                                            sx={{
                                                color: "#FC7634"
                                            }}
                                        />
                                        <Typography
                                            style={{
                                                marginLeft: 5,
                                                color: "#030F4F",
                                            }}
                                        >
                                            Der er midlertidigt manglende forbrugsdata i nogle bygninger.
                                        </Typography>
                                    </Box>
                                    <IconButton
                                        aria-label="close"
                                        sx={{ backgroundColor: "gray", width: 32, height: 32, ml: 1, "&:hover": { backgroundColor: "#a6a6a6" } }}
                                        onClick={(e:any) =>
                                        {
                                            setWarningOpen(false);
                                        }}
                                    >
                                        <CloseIcon sx={{ color: "white" }} />
                                    </IconButton>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={4.5} mb={{ xs: 3, md: 4.5 }} columns={10} justifyContent="center" direction={{ xs: "column-reverse", xl: "row" }}>
                    <Grid item xs={10} xl>
                        <Toolbar
                            selectedCampus={selectedCampus} setSelectedCampus={setSelectedCampus}
                            selectedSite={selectedSite} setSelectedSite={setSelectedSite}
                            selectedOrganization={selectedOrganization} setSelectedOrganization={setSelectedOrganization}
                            distributions={distributions} setDistributions={setDistributions}
                            allSites={allSites} setAllSites={setAllSites}
                            allOrganizations={allOrganizations} setAllOrganizations={setAllOrganizations}
                            selectedDate={selectedDate} setSelectedDate={setSelectedDate} isDateFinished={isDateFinished}
                            setDetailNavStack={setDetailNavStack} setDayViewState={setDayViewState}
                        />
                    </Grid>
                    <Grid item xs={10} xl={4}>
                        <LiveUsageView
                            campus={selectedCampus} energyPrices={energyPrices} energyTariffs={energyTariffs}
                            emissions={emissions} graphState={graphState} setGraphState={setGraphState}
                        />
                        { graphState.isOpen &&
                            <Box display={{xs: "block", xl: "none"}} height={495} mt={3} className="fade-anim">
                                <LiveGraphBox
                                    type={graphState.usageType} energyValues={energyValues} energyPrices={energyPrices}
                                    energyTariffs={energyTariffs} emissions={emissions} emissionPrognosis={emissionPrognosis}
                                    graphState={graphState} setGraphState={setGraphState} isGraphLoading={isGraphLoading}
                                    notImplemented={selectedCampus.name !== "Lyngby" && graphState.usageType === UsageType.ENERGY && !energyValues.length}
                                />
                            </Box>
                        }
                    </Grid>
                </Grid>
                <Grid container columns={10} spacing={4.5}>
                    <Grid item xs>
                        { detailViewState
                            ? <UsageDetailsBox
                                energyType={detailViewState} energyValues={detailsData}
                                selectedDate={selectedDate} setSelectedDate={setSelectedDate} selectedCampus={selectedCampus}
                                selectedOrganization={selectedOrganization} selectedSite={selectedSite}
                                distributions={distributions} allSites={allSites}
                                setDetailViewState={setDetailViewState}
                                dayViewState={dayViewState} setDayViewState={setDayViewState}
                                navStack={detailNavStack} setNavStack={setDetailNavStack}
                                isLoading={isLoading[detailViewState.id]}
                                error={errors[detailViewState.id]} graphState={graphState}
                                graphType={graphType} setGraphType={setGraphType}
                            />
                            : <TotalUsageDashboard
                                elecData={elecData} waterData={waterData} heatData={heatData} coolingData={coolingData}
                                animClass={animClass} setAnimClass={setAnimClass} date={selectedDate} graphType={graphType} isLoading={isLoading}
                                errors={errors} warnings={warnings} graphState={graphState} setDetailViewState={setDetailViewState}
                            />
                        }
                    </Grid>
                    { graphState.isOpen &&
                        <Grid item xs={detailViewState ? 5 : 7.5} display={{xs: "none", xl: "block"}} className="fade-anim">
                            <LiveGraphBox
                                type={graphState.usageType} energyValues={energyValues} energyPrices={energyPrices}
                                energyTariffs={energyTariffs} emissions={emissions} emissionPrognosis={emissionPrognosis}
                                graphState={graphState} setGraphState={setGraphState} isGraphLoading={isGraphLoading}
                                notImplemented={selectedCampus.name !== "Lyngby" && graphState.usageType === UsageType.ENERGY && !energyValues.length}
                            />
                        </Grid>
                    }
                </Grid>
                <Box
                    position="fixed" width="100vw" height="100vh" top={0} left={0} zIndex={-1}
                    sx={{
                        backgroundImage: `url(${bottomWaveGreen})`,
                        backgroundRepeat: "repeat-x",
                        backgroundPosition: "bottom",
                        backgroundSize: "auto 45%"
                    }}
                />
            </Box>
        </ThemeProvider>
    );
}
