import { Tag } from "../../models/Tag";

/**
 * Parses a string representing a tag into the Tag type containing a key and value.
 * @param {string} tag - The string tag (from the JSON object returned by the API)
 * @returns The tag as a Tag type object
 */
export function parseTag(tag: string): Tag {
    let key: string = "";
    let value: any;

    try {
        const sepIndex = tag.indexOf(":");

        if (sepIndex >= 0) {
            key = tag.substring(0, sepIndex);
            value = tag.substring(sepIndex + 1);

            if (value.length > 0 && value[0] === "@") {
                value = value.substring(1);
            }

        } else {
            key = tag;
            value = null;
        }
    } catch {}

    return {key: key, value: value};
}