import dayjs from "dayjs";
import { Guid } from "../models/Guid";
import { TimeValue } from "../models/TimeValue";
import { Campus } from "../types/Campus";
import { getTimeSeries } from "./api/TimeSeriesService";

/**
 * Fetches the hourly energy prices for the specified date.
 * @param {string} date - The date to get energy prices for (in ISO string format)
 * @returns The hourly energy prices as an array of time values
 */
async function getEnergyPrices(date: string, signal?: AbortSignal): Promise<TimeValue[]> {
    const from = dayjs(date);
    const to = from.add(1, "day");

    const timeSeries = await getTimeSeries(
        new Guid("6ba8e94a-6d5a-45f4-803a-9fe5e6793a32"),
        from.format("YYYY-MM-DDT00:01"),
        to.format("YYYY-MM-DDT00:01"),
        undefined,
        undefined,
        signal
    );

    return timeSeries;
}

/**
 * Fetches the hourly energy tariff prices for the specified date.
 * @param {string} date - The date to get tariff prices for (in ISO string format)
 * @returns The hourly tariff prices as an array of time values
 */
async function getEnergyTariffs(date: string, signal?: AbortSignal): Promise<TimeValue[]> {
    const from = dayjs(date);
    const to = from.add(1, "day");

    const timeSeries = await getTimeSeries(
        new Guid("24c6c52e-bb08-422c-9a16-e360a092c066"),
        from.format("YYYY-MM-DDT00:01"),
        to.format("YYYY-MM-DDT00:01"),
        undefined,
        undefined,
        signal
    );

    return timeSeries;
}

/**
 * Fetches the list of CO2 emission values for today.
 * @returns The emissions as an array of time values
 */
async function getEmissions(): Promise<TimeValue[]> {
    const from = dayjs();
    const to = from.add(1, "day");
    const timeSeries = await getTimeSeries(new Guid("531c1f29-e364-4245-8a93-e1b36144bded"), from.format("YYYY-MM-DDT00:01"), to.format("YYYY-MM-DDT00:01"));
    return timeSeries;
}

/**
 * Fetches the list of CO2 emission prognosis values for today.
 * @returns The emission prognosis as an array of time values
 */
async function getEmissionPrognosis(): Promise<TimeValue[]> {
    const from = dayjs();
    const to = from.add(1, "day");
    const timeSeries = await getTimeSeries(new Guid("08bda65a-30a4-4110-95ac-f51fbd425623"), from.format("YYYY-MM-DDT00:01"), to.format("YYYY-MM-DDT00:01"));
    return timeSeries;
}

/**
 * Fetches today's hourly energy usage for the selected campus.
 * @param {Campus} campus - The selected campus
 * @returns Today's hourly energy usage for the selected campus as an array of time values
 */
async function getEnergyValues(campus: Campus): Promise<TimeValue[]> {
    let guid = "";

    if (campus.name === "Ballerup") {
        // The ELEC virtual point for site "Bygning Ballerup"
        guid = "dcc2019f-036a-4927-aa8c-606061a7a4a6";
    } else if (campus.name === "Risø") {
        // The ELEC virtual point for Risø total usage
        guid = "3a47dc6f-75da-4ceb-aa1f-fb502c1b57db";
    } else {
        // The ELEC virtual point for Lyngby total usage
        guid = "7eb0d7a6-1f29-411f-b779-194eb3a98dfc";
    }

    const from = dayjs();
    const to = from.add(1, "day");
    const timeSeries = await getTimeSeries(new Guid(guid), from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD"), "PT1H", "delta");
    return timeSeries;
}

const exported = {
    getEnergyPrices,
    getEnergyTariffs,
    getEmissions,
    getEmissionPrognosis,
    getEnergyValues
}

export default exported;