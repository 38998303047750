import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { Box, Card, Stack, Typography, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Guid } from "../../models/Guid";
import { TimeValue } from "../../models/TimeValue";
import LiveValueService from "../../services/LiveValueService";
import bottomWaveGreen from "../../assets/images/bottom_wave_green.svg";
import LiveValueBox from "./LiveValueBox";
import liveUsageIcon from "../../assets/images/live_usage.svg";
import { getValueByTimestamp } from "../../util/LiveValueUtils";
import { UsageType } from "../../types/UsageType";
import { Campus } from "../../types/Campus";
import dayjs from "dayjs";

interface LiveUsageViewProps {
    campus: Campus,
    energyPrices: TimeValue[],
    energyTariffs: TimeValue[],
    emissions: TimeValue[],
    graphState: {isOpen: boolean, usageType: UsageType, date: string},
    setGraphState: Dispatch<SetStateAction<{isOpen: boolean, usageType: UsageType, date: string}>>
}

export default function LiveUsageView(props: LiveUsageViewProps) {
    const [connection, setConnection] = useState<HubConnection | null>(null);

    // The three live values that constantly update
    const [currentEnergy, setCurrentEnergy] = useState<TimeValue | null>(null);
    const [currentPrice, setCurrentPrice] = useState<number | null>(null);
    const [currentEmission, setCurrentEmission] = useState<number | null>(null);

    // The present energy price at this hour
    const energyPriceNow = useRef<TimeValue | undefined>(undefined);
    const tariffPriceNow = useRef<TimeValue | undefined>(undefined);

    const [loading, setLoading] = useState(true);

    const theme = useTheme();

    useEffect(() => {
        const newConnection = LiveValueService.getNewConnection();
        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (props.campus.name === "Lyngby") {
            if (connection?.state === HubConnectionState.Connected) return;
            LiveValueService.connectAndSetValue(connection, new Guid("db225f4f-d107-40dc-bda3-28b78e122e07"), setCurrentEnergy, setLoading);
        } else {
            connection?.stop();
            setLoading(false);
            setCurrentEnergy(null);
            setCurrentPrice(null);
            setCurrentEmission(null);
        }
    }, [connection, props.campus]);

    useEffect(() => {
        if (currentEnergy) {
            // The current energy price at this hour
            if (dayjs(props.energyPrices.length && props.energyPrices[0].ts).isSame(dayjs(), "day")) {
                energyPriceNow.current = getValueByTimestamp(currentEnergy.ts, props.energyPrices);
            }

            // The current tariff price at this hour
            if (dayjs(props.energyTariffs.length && props.energyTariffs[0].ts).isSame(dayjs(), "day")) {
                tariffPriceNow.current = getValueByTimestamp(currentEnergy.ts, props.energyTariffs);
            }

            // The price per hour for the selected campus (based on the current energy use)
            if (energyPriceNow.current !== undefined && tariffPriceNow.current !== undefined) {
                setCurrentPrice(currentEnergy.value * ((energyPriceNow.current.value / 1000) + tariffPriceNow.current.value));
            } else if (energyPriceNow.current !== undefined) {
                setCurrentPrice(currentEnergy.value * (energyPriceNow.current.value / 1000));
            } else if (tariffPriceNow.current !== undefined) {
                setCurrentPrice(currentEnergy.value * tariffPriceNow.current.value);
            } else {
                setCurrentPrice(null);
            }

            // The latest emission CO2 number (which should be close to the current time)
            const emission = props.emissions.length ? props.emissions[props.emissions.length - 1] : undefined;

            // The emissions in kg. per hour for the selected campus (based on the current energy use)
            if (emission !== undefined) {
                setCurrentEmission(currentEnergy.value * (emission.value / 1000));
            } else {
                setCurrentEmission(null);
            }
        }
    }, [currentEnergy, props.energyPrices, props.energyTariffs, props.emissions]);

    /* Link temporarily removed until new certificate has been bought
        <Box display="flex" justifyContent="center">
            <img src={leafIcon} alt="Leaf Icon" height={18}/>
            <Link href={certificatePdf} target="_blank" rel="noopener noreferrer" ml={1} fontSize="1rem" fontFamily="NeoSansProLight" lineHeight={1} sx={{color: "white"}}>
                Elforbruget på DTU er købt som miljøvenligt el
            </Link>
        </Box>
    */

    return (
        <Card sx={{
            height: "100%",
            backgroundColor: theme.palette.primary.main,
            color: "white",
            px: {xs: 3, md: 5},
            py: 4,
            backgroundImage: `url(${bottomWaveGreen})`,
            backgroundRepeat: "repeat-x",
            backgroundPositionY: "bottom",
            backgroundPositionX: "right",
            backgroundSize: "auto 60%"
        }}>
            <Stack spacing={2} justifyContent="space-between" height="100%">
                <Box display="flex" mb={1}>
                    <img src={liveUsageIcon} alt="Live Icon" width={22}/>
                    <Typography ml={1} fontFamily="NeoSansProBold" fontSize="1.75rem" lineHeight={1}>Aktuelt elforbrug - {props.campus.name}</Typography>
                </Box>
                <Stack direction={{xs: "column", sm: "row"}} spacing={3.75} justifyContent="center" alignItems="center">
                    <LiveValueBox
                        liveValue={currentEnergy ? currentEnergy.value : null} type={UsageType.ENERGY}
                        setGraphState={props.setGraphState} graphState={props.graphState} loading={loading}
                        notImplemented={props.campus.name !== "Lyngby"}
                    />
                    <LiveValueBox
                        liveValue={currentPrice} type={UsageType.PRICE}
                        setGraphState={props.setGraphState} graphState={props.graphState} loading={loading}
                        notImplemented={props.campus.name !== "Lyngby"}
                    />
                    <LiveValueBox
                        liveValue={currentEmission} type={UsageType.EMISSIONS}
                        setGraphState={props.setGraphState} graphState={props.graphState} loading={loading}
                        notImplemented={props.campus.name !== "Lyngby"}
                    />
                </Stack>
            </Stack>
        </Card>
    );
}
