import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import { Bar, CartesianGrid, Cell, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { TimeValue } from "../../models/TimeValue";
import { DateSelection } from "../../types/DateSelection";
import { EnergyType } from "../../types/EnergyType";
import { UsageType } from "../../types/UsageType";
import { updateQueryParam } from "../../util/ParamUtils";

interface ConsumptionGraphProps {
    energyType: EnergyType,
    energyValues: {selection: TimeValue[], lastYear: TimeValue[]},
    selectedDate: DateSelection,
    dayViewState: {isOpen: boolean, date: string},
    setDayViewState: Dispatch<SetStateAction<{isOpen: boolean, date: string}>>,
    setSelectedDate: Dispatch<SetStateAction<DateSelection>>,
    graphState: {isOpen: boolean, usageType: UsageType, date: string},
    setNavStack: Dispatch<SetStateAction<DateSelection[]>>
}

export default function ConsumptionGraph(props: ConsumptionGraphProps) {
    const theme = useTheme();

    const [focusBar, setFocusBar] = useState<number | undefined>();

    const isWideScreen = useMediaQuery(theme.breakpoints.up("xl"));

    // This is only necessary in order to use superscript for m3
    const unit = props.energyType.unit === "m3" ? <>m<sup>3</sup></> : props.energyType.unit;

    let axisFormat = isWideScreen && !props.graphState.isOpen ? "MMMM" : "MMM";
    let tooltipFormat = "MMMM";
    let legendSelection = props.selectedDate.year.toString();
    let legendLastYear = (props.selectedDate.year - 1).toString();

    if (props.dayViewState.isOpen) {
        axisFormat = "HH";
        legendSelection = dayjs(props.dayViewState.date).format("D. MMMM YYYY");
        legendLastYear = dayjs(props.dayViewState.date).subtract(1, "year").format("D. MMMM YYYY");
    } else if (props.selectedDate.month !== undefined) {
        axisFormat = "D.";
        tooltipFormat = "D. MMMM";
        legendSelection = dayjs(props.selectedDate.year.toString()).set("month", props.selectedDate.month).format("MMMM YYYY");
        legendLastYear = dayjs((props.selectedDate.year - 1).toString()).set("month", props.selectedDate.month).format("MMMM YYYY");
        legendSelection = legendSelection.charAt(0).toUpperCase() + legendSelection.slice(1);
        legendLastYear = legendLastYear.charAt(0).toUpperCase() + legendLastYear.slice(1);
    } else if (props.selectedDate.week !== undefined) {
        axisFormat = isWideScreen ? "dddd" : "ddd";
        tooltipFormat = "dddd";
        legendSelection = "Uge " + props.selectedDate.week + " " + props.selectedDate.year;
        const weekTmp = props.selectedDate.week === 53 ? 52 : props.selectedDate.week;
        legendLastYear = "Uge " + weekTmp + " " + (props.selectedDate.year - 1);
    }

    let data: {date: string, selection: number | null, lastYear: number | null}[] =
        props.energyValues.selection.map((timeValue, index) => {
            let dateStr = dayjs(timeValue.ts).format(axisFormat);
            let lastYearValue = props.energyValues.lastYear.length >= props.energyValues.selection.length
                ? props.energyValues.lastYear[index].value
                : 0;

            return {
                date: dateStr.charAt(0).toUpperCase() + dateStr.slice(1),
                selection: timeValue.value === 0 ? null : timeValue.value,
                lastYear: lastYearValue === 0 ? null : lastYearValue
            }
        });

    const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
        const currentIndex = data.findIndex(elem => elem.date === label);
        let labelFormatted = label;

        if (currentIndex >= 0) {
            if (props.dayViewState.isOpen) {
                const nextHour = parseInt(label) + 1;
                const nextLabel = nextHour < 10 ? "0" + nextHour : nextHour.toString();
                labelFormatted = label === "23" ? "23:00 - 00:00" : `${label}:00 - ${nextLabel}:00`;
            } else {
                labelFormatted = dayjs(props.energyValues.selection[currentIndex].ts).format(tooltipFormat);
                labelFormatted = labelFormatted.charAt(0).toUpperCase() + labelFormatted.slice(1);
            }

            if (active && payload && payload.length) {
                const lastYear = payload[0].payload.lastYear ?? "--";
                const selection = payload[0].payload.selection ?? "--";

                let dateSelection = props.selectedDate.year.toString();
                let dateLastYear = (props.selectedDate.year - 1).toString();

                if (props.selectedDate.week && !props.dayViewState.isOpen) {
                    // The dates are different when a week is selected
                    const selectionDate = dayjs(props.energyValues.selection[currentIndex].ts);
                    dateSelection = selectionDate.format("DD/MM/YYYY");

                    if (props.energyValues.lastYear.length) {
                        dateLastYear = dayjs(props.energyValues.lastYear[currentIndex].ts).format("DD/MM/YYYY");
                    }
                }

                return (
                    <Box p={1.5} borderRadius="5px" border="1px solid black" sx={{backgroundColor: "white"}}>
                        <Typography lineHeight={1} mb={1}>{labelFormatted}</Typography>
                        <Typography lineHeight={1} color="#c5c5c5">
                            {dateLastYear}: {" "}
                            {lastYear.toLocaleString("da-DK", {maximumFractionDigits: props.energyType.decimals})} {" "}
                            {unit}
                        </Typography>
                        <Typography lineHeight={1} mt={1} color={props.energyType.mainColor} fontFamily="NeoSansProBold">
                            {dateSelection}: {" "}
                            {selection.toLocaleString("da-DK", {maximumFractionDigits: props.energyType.decimals})} {" "}
                            {unit}
                        </Typography>
                    </Box>
                );
            }
        }

        return null;
    };

    function handleBarClick(state: CategoricalChartState) {
        if (state && state.activeTooltipIndex !== undefined && data[state.activeTooltipIndex].selection !== null) {
            if (props.dayViewState.isOpen) return;

            props.setNavStack(prev => {
                const stack = [...prev];
                stack.push(props.selectedDate);
                return stack;
            });

            if (props.selectedDate.month === undefined && props.selectedDate.week === undefined) {
                // When clicking a bar in year view, show the month view for the clicked month
                props.setSelectedDate(prev => ({...prev, month: state.activeTooltipIndex}));
                updateQueryParam("month", state.activeTooltipIndex.toString(), false);
            } else {
                // When clicking a bar in month or week view, show the day view for the clicked day
                const date = props.energyValues.selection[state.activeTooltipIndex].ts;
                props.setDayViewState({isOpen: true, date: date});
                updateQueryParam("day", dayjs(date).format("YYYY-MM-DD"), false);
            }
        }
    }

    return (
        <ResponsiveContainer width="100%" height={330}>
            <ComposedChart
                width={500}
                height={300}
                data={data}
                margin={{ right: 50 }}
                onMouseLeave={state => setFocusBar(undefined)}
                onMouseMove={state => {
                    if (state.isTooltipActive) {
                      setFocusBar(state.activeTooltipIndex);
                    } else {
                      setFocusBar(undefined);
                    }
                }}
                onClick={state => handleBarClick(state)}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" tickSize={8} tickMargin={5} />
                <YAxis
                    width={100} tickSize={8} tickMargin={5}
                    tickFormatter={tick => tick.toLocaleString("da-DK", {maximumFractionDigits: props.energyType.decimals})}
                />
                <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ outline: "none" }}
                    isAnimationActive={false}
                />
                <Legend
                    wrapperStyle={{paddingLeft: 70}}
                    formatter={(value, entry, index) => (
                        <Typography
                            display="inline" position="relative" top="2px" marginRight={2}
                            fontFamily={index === 0 ? "NeoSansProRegular" : "NeoSansProBold"}
                        >
                            {value}
                        </Typography>
                    )}
                    payload={[
                        { id: "lastYear", value: legendLastYear, type: "line", color: "#c5c5c5"},
                        { id: "selection", value: legendSelection, type: "rect", color: props.energyType.mainColor}
                    ]}
                />
                <Bar dataKey="selection" fill={props.energyType.mainColor} radius={[3, 3, 0, 0]} maxBarSize={60}>
                    {
                        data.map((entry, index) => (
                            <Cell
                                className="details-bar"
                                key={`cell-${index}`}
                                fill={focusBar === index ? props.energyType.darkColor : props.energyType.mainColor}
                            />
                        ))
                    }
                </Bar>
                <Line type="linear" dataKey="lastYear" stroke="#c5c5c5" strokeWidth={3} isAnimationActive={false} />
            </ComposedChart>
        </ResponsiveContainer>
    )
}
