import React from "react";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
//import { useLocation, useNavigate, Location } from "react-router-dom";


type AuthorizationProps = {
    children: React.ReactNode,
    oidcConfig: AuthProviderProps
}


export default function Authorization({ children, oidcConfig }: AuthorizationProps) {

    // eslint-disable-next-line
    const conf: AuthProviderProps = {
        ...oidcConfig,
        onSigninCallback: (res: any) => {
            console.log("onSigninCallback - Authorization");
        }
    };
    

    return (
        <AuthProvider {...oidcConfig}>
                {children}
        </AuthProvider>
    );
}
