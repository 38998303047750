import { Box, styled, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Warning } from "../../types/Warning";
import ErrorIcon from '@mui/icons-material/Error';

interface UsageComparisonProps {
    current: number,
    previous: number,
    decimals: number,
    unit: string,
    prevStr: string,
    warning?: Warning,
    dateStr?: string
}

export default function UsageComparison(props: UsageComparisonProps) {
    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 170,
            color: "black",
            backgroundColor: "#fbc02d",
            fontFamily: "NeoSansProLight"
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "#fbc02d"
        },
        [`& .${tooltipClasses.tooltipPlacementTop}`]: {
            top: "7px"
        }
    });

    let comparisonStr = "";
    let comparisonColor = "";
    let pctChange = 0;

    // Calculates the percentage based on the numbers after rounding
    const current = Number(props.current.toFixed(props.decimals));
    const previous = Number(props.previous.toFixed(props.decimals));

    if (current > previous) { // Increase
        comparisonStr = "Højere forbrug end";
        comparisonColor = "#BE1D1D";
        pctChange = (current - previous) / previous * 100;
    } else if (current < previous) { // Decrease
        comparisonStr = "Lavere forbrug end";
        comparisonColor = "#1DBE23";
        pctChange = (previous - current) / previous * 100;
    } else {
        comparisonStr = "Samme forbrug som";
        comparisonColor = "black";
    }

    const endStr = props.dateStr !== undefined
        ? `${props.dateStr} ${props.prevStr}`
        : `${props.prevStr} (${previous.toLocaleString("da-DK")} `;

    // This is only necessary in order to use superscript for m3
    const unit = props.unit === "m3" ? <>m<sup>3</sup>)</> : props.unit + ")";

    return (
        <Box maxWidth={200}>
            { previous !== 0 &&
                <>
                    <Box display="flex" gap={0.25}>
                        <Typography color={comparisonColor} fontSize="1.5rem" fontFamily={"NeoSansProBold"}>
                            {pctChange.toLocaleString("da-DK", {maximumFractionDigits: 1})}%
                        </Typography>
                        { (props.warning !== undefined && props.warning !== Warning.NONE) &&
                            <CustomTooltip
                                title="Tallet kan være misvisende i den valgte periode pga. manglende data"
                                arrow
                                placement="top"
                            >
                                <ErrorIcon sx={{fontSize: "1rem", color: "#fbc02d"}} />
                            </CustomTooltip>
                        }
                    </Box>
                    <Typography lineHeight={1} fontSize="0.75rem">
                        {comparisonStr} {" "}
                        <Typography component={'span'} fontSize="0.75rem" fontFamily={"NeoSansProBold"}>
                            {endStr} {props.dateStr === undefined ? unit : ""}
                        </Typography>
                    </Typography>
                </>
            }
        </Box>
    )
}
