import elecIcon from "../assets/images/elec_icon.svg";
import waterIcon from "../assets/images/water_icon.svg";
import heatIcon from "../assets/images/heat_icon.svg";
import coolingIcon from "../assets/images/cooling_icon.svg";
import elecLoad from "../assets/animations/elec-load.json";
import waterLoad from "../assets/animations/water-load.json";
import heatLoad from "../assets/animations/heat-load.json";
import coolingLoad from "../assets/animations/cooling-load.json";

/**
 * The four types of energy that the application can display energy usage numbers for.
 * Each energy type has some corresponding constant data which is used in the UI and for API queries.
 */
export class EnergyType {
    public static readonly ELEC = new EnergyType(
        0, "Elforsyning", "elec", "#7A2290", "#F8F4F9", "#570075", "#E7D9EA",
        "kWh", 0, "_EF_EM_Ptot", [], elecIcon, elecLoad
    );

    public static readonly WATER = new EnergyType(
        1, "Brugsvand", "water", "#303DEB", "#F4F5FE", "#0013b7", "#CED2FA",
        "m3", 2, "_BF_FM_FLtot", [], waterIcon, waterLoad
    );
    public static readonly HEAT = new EnergyType(
        2, "Varmeenergi", "heat", "#F97425", "#FFF8F4", "#ce5019", "#FFDDC9",
        "MWh", 2, "_VF_QM_Qtot", [], heatIcon, heatLoad
    );

    public static readonly COOLING = new EnergyType(
        3, "Køleenergi", "cooling", "#171749", "#F3F3F6", "#000022", "#DBDBE4",
        "MWh", 2, "_KF_QM_Qtot", [], coolingIcon, coolingLoad
    );

    public id: number;
    public displayName: string;
    public shortName: string;
    public mainColor: string;
    public lightColor: string;
    public darkColor: string;
    public hoverColor: string;
    public unit: string;
    public decimals: number;
    public queryName: string;
    public queryTags: string[];
    public icon: string;
    public loadingIcon: {};

    private constructor(
        id: number, displayName: string, shortName: string, mainColor: string, lightColor: string,
        darkColor: string, hoverColor: string, unit: string, decimals: number,
        queryName: string, queryTags: string[], icon: string, loadingIcon: {}
    ) {
        this.id = id;
        this.displayName = displayName;
        this.shortName = shortName;
        this.mainColor = mainColor;
        this.lightColor = lightColor;
        this.darkColor = darkColor;
        this.hoverColor = hoverColor;
        this.unit = unit;
        this.decimals = decimals;
        this.queryName = queryName;
        this.queryTags = queryTags;
        this.icon = icon;
        this.loadingIcon = loadingIcon;
    }

    public static getType(shortName: string) {
        if (shortName === "elec") {
            return EnergyType.ELEC;
        } else if (shortName === "water") {
            return EnergyType.WATER;
        } else if (shortName === "heat") {
            return EnergyType.HEAT;
        } else {
            return EnergyType.COOLING;
        }
    }
}
