import { Box, Card, IconButton, Stack, Typography } from "@mui/material";
import { TimeValue } from "../../models/TimeValue";
import { UsageType } from "../../types/UsageType";
import PriceGraph from "./PriceGraph";
import EmissionGraph from "./EmissionGraph";
import CloseIcon from '@mui/icons-material/Close';
import { Dispatch, SetStateAction } from "react";
import EnergyGraph from "./EnergyGraph";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import dayjs from "dayjs";
import { Player } from "@lottiefiles/react-lottie-player";
import leafLoad from "../../assets/animations/leaf-load.json";

interface LiveGraphBoxProps {
    type: UsageType,
    energyValues: TimeValue[],
    energyPrices: TimeValue[],
    energyTariffs: TimeValue[],
    emissions: TimeValue[],
    emissionPrognosis: TimeValue[],
    graphState: {isOpen: boolean, usageType: UsageType, date: string},
    setGraphState: Dispatch<SetStateAction<{isOpen: boolean, usageType: UsageType, date: string}>>,
    isGraphLoading: {energy: boolean, price: boolean, emissions: boolean},
    notImplemented: boolean
}

export default function LiveGraphBox(props: LiveGraphBoxProps) {
    let typeStr = "";
    let unit: string | JSX.Element = "";
    let graph = <></>;
    let isLoading = false;

    switch (props.type) {
        case UsageType.ENERGY:
            typeStr = "Elforbrug";
            unit = "kWh";
            graph = <EnergyGraph energyValues={props.energyValues} />;
            isLoading = props.isGraphLoading.energy;
            break;
        case UsageType.PRICE:
            typeStr = "El spotpris";
            unit = "DKK pr. kWh (inkl. tarif, ekskl. moms og afgifter)";
            graph = <PriceGraph energyPrices={props.energyPrices} energyTariffs={props.energyTariffs} graphState={props.graphState} />;
            isLoading = props.isGraphLoading.price;
            break;
        case UsageType.EMISSIONS:
            typeStr = "Udledning";
            unit = <>Gram CO<sub>2</sub> pr. kWh</>;
            graph = <EmissionGraph emissions={props.emissions} prognosis={props.emissionPrognosis} />;
            isLoading = props.isGraphLoading.emissions;
            break;
    }

    return (
        <Card sx={{height: "100%", position: "relative", p: {xs: 3, md: 3.75}}}>
            <Stack height="100%">
                <Box mb={3}>
                    <Stack 
                        direction={{xs: props.graphState.usageType === UsageType.PRICE ? "column-reverse" : "row", sm: "row"}} 
                        justifyContent="space-between" 
                        rowGap={1}
                        alignItems={{xs: "left", sm: "center"}}
                    >
                        <Box>
                            <Typography display="inline" fontSize="1.625rem" fontFamily="NeoSansProBold" lineHeight={1}>{typeStr}</Typography>
                            <Typography display="inline" fontSize="1.625rem" lineHeight={1}>
                                {" "} {props.graphState.usageType !== UsageType.PRICE && "i dag"}
                                {" "} {props.type !== UsageType.ENERGY && "(DK2)"}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1} justifyContent="space-between">
                            { props.graphState.usageType === UsageType.PRICE &&
                                <>
                                    <Typography>{dayjs(props.graphState.date).format("D. MMMM YYYY")}</Typography>
                                    <IconButton
                                        sx={{ width: 32, height: 32, color: "black" }}
                                        onClick={() => props.setGraphState(prev => ({...prev, date: dayjs(prev.date).subtract(1, "day").format()}))}
                                    >
                                        <NavigateBeforeIcon sx={{ fontSize: "2rem" }} />
                                    </IconButton>
                                    <IconButton
                                        sx={{ width: 32, height: 32, color: "black" }}
                                        onClick={() => props.setGraphState(prev => ({...prev, date: dayjs(prev.date).add(1, "day").format()}))}
                                        disabled={dayjs(props.graphState.date).isSame(dayjs().add(1, "day"), "day")}
                                    >
                                        <NavigateNextIcon sx={{ fontSize: "2rem" }} />
                                    </IconButton>
                                </>
                            }
                            <IconButton
                                aria-label="close"
                                onClick={() => props.setGraphState(prev => ({...prev, isOpen: false}))}
                                sx={{backgroundColor: "black", width: 32, height: 32, ml: 1, "&:hover": {backgroundColor: "#a6a6a6"}}}
                            >
                                <CloseIcon sx={{ color: "white" }} />
                            </IconButton>
                        </Box>
                    </Stack>
                    <Typography fontSize="0.75rem" mt={0.5}>{unit}</Typography>
                </Box>
                { isLoading
                    ? <Box position="absolute" margin="auto" top="50%" left={0} right={0} sx={{transform: "translateY(-50%)"}}>
                        <Player autoplay loop src={leafLoad} style={{ height: "105px", width: "105px" }} />
                    </Box>
                    : <Box height="100%" display="flex" alignItems="center">
                        {graph}
                    </Box>
                }
            </Stack>
            { props.notImplemented &&
                <Typography
                    display="flex"
                    justifyContent="center"
                    position="absolute"
                    fontFamily="NeoSansProBold"
                    fontSize={{xs: "1.6rem", sm: "2.3rem"}}
                    lineHeight={1}
                    border={{xs: "4px solid gray", sm: "5px solid gray"}}
                    color="gray"
                    p={1}
                    margin="auto"
                    left={0}
                    right={0}
                    top={0}
                    bottom={0}
                    width={{xs: 200, sm: 280}}
                    height={{xs: 48, sm: 62}}
                    sx={{opacity: 0.25}}
                >
                    COMING SOON
                </Typography>
            }
        </Card>
    )
}
