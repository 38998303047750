import { Box, styled, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Warning } from "../../types/Warning";
import ErrorIcon from '@mui/icons-material/Error';

interface UsageDateSelectionProps {
    date: string,
    energy: number | null,
    decimals: number,
    color: string,
    warning: Warning
}

export default function UsageDateSelection(props: UsageDateSelectionProps) {
    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 170,
            color: "black",
            backgroundColor: "#fbc02d",
            fontFamily: "NeoSansProLight"
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "#fbc02d"
        },
        [`& .${tooltipClasses.tooltipPlacementTop}`]: {
            top: "7px"
        }
    });

    let energyString = props.energy === null ? "--" : props.energy.toLocaleString("da-DK", {maximumFractionDigits: props.decimals});
    let energyFontSize = "1.75rem";

    if (energyString.length > 10) {
        energyFontSize = "1.6rem";
    }

    return (
        <Box>
            <Box display="flex" alignItems="center" gap={0.7}>
                <Typography fontSize="0.875rem" color={props.color}>
                    {props.date.charAt(0).toUpperCase() + props.date.slice(1)}
                </Typography>
                { props.warning !== Warning.NONE &&
                    <CustomTooltip title="Tallet kan være misvisende i den valgte periode pga. manglende data" arrow placement="top">
                        <ErrorIcon sx={{fontSize: "1rem", color: "#fbc02d"}} />
                    </CustomTooltip>
                }
            </Box>
            <Typography fontSize={energyFontSize} fontFamily="NeoSansProBold" color={props.color}>
                {energyString}
            </Typography>
        </Box>
    )
}
