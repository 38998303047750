import { Dayjs } from 'dayjs';

/** 
 * Sets the week within the calendar year specified by the date. 
 * This is different to dayjs's default which sets the week within the date's week year, which is different from the calendar year.
 * Specifically, the week year runs from week 1 to 52/53 whereas the calendar year runs from 1st Jan to 31st Dec.
 * This leads to them being different in the start of January and end of December.
 * @param {Dayjs} date - The dayjs object where the week should be set within its year
 * @param {number} week - The week that the date should be set to
 * @returns A new dayjs object which is set to the first day of the specified week
*/ 
export function setWeekInYear(date: Dayjs, week: number): Dayjs {
    let newDate = date;

    if (date.year() !== date.isoWeekYear()) {
        newDate = date.set("month", 5); // Ensures calendar and week year are the same
    }

    return newDate.isoWeek(week).isoWeekday(1); // Also sets weekday to Monday
}
