import dayjs from "dayjs";
import { DistEntity } from "../models/DistEntity";
import { TimeValue } from "../models/TimeValue";
import { Campus } from "../types/Campus";
import { EnergyType } from "../types/EnergyType";
import { Organization } from "../types/Organization";
import { Site } from "../types/Site";
import { parseDate } from "./api/DateParser";
import { getEntities } from "./api/EntityService";
import { getTimeSeries } from "./api/TimeSeriesService";

/**
 * Fetches sheet data containing the energy usage of a campus, organisation or site within the specified date directly from the API.
 * The returned object is formatted as required by SheetJS so that it can be exported as an Excel file.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Campus} campus - The selected campus
 * @param {Organization | null} organization - The selected organization
 * @param {Site | null} site - The selected site/building
 * @param {DistEntity[]} distributions - List of distribution entities retrieved from DistributionService.getDistEntities()
 * @param {Site[]} allSites - List of all sites within the selected campus retrieved from SiteService.getAllSites()
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns An object containing the energy data in the format needed by SheetJS
 */
async function getSheetData(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null, distributions: DistEntity[],
    allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<{sheetData: {[site: string]: any}[], header: string[][]}> {
    const sheetTimeSeries = await getSheetTimeSeries(energyType, campus, organization, site, distributions, allSites, isDateFinished, year, month, week);

    let header = [["Dato"]];

    // Add a column header for each site that appears in the data
    if (sheetTimeSeries.length !== 0) {
        for (let i = 0; i < sheetTimeSeries[0].values.length; i++) {
            header[0].push(sheetTimeSeries[0].values[i].siteName + " (" + energyType.unit + ")");
        }
    }

    // Format the timeseries data such that it fits the format needed by SheetJS (exporting)
    const sheetData = sheetTimeSeries.map(time => {
        if (time.values.length === 0)  {
            return {day: time.ts};
        }

        // This type lets you dynamically add new properties to the object
        let obj: {[site: string]: any} = {day: time.ts};

        for (let i = 0; i < time.values.length; i++) {
            obj[`site${i}`] = time.values[i].value;
        }

        return obj;
    });

    return {sheetData, header};
}

/** 
 * Fetches sheet data containing the energy usage of a campus, organisation or site within the specified date directly from the API. 
 * Returns the data as a modified time series that shows which site a specific value is from, which is primarily needed for organizations
 * that have data from multiple sites.
 */
async function getSheetTimeSeries(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null, distributions: DistEntity[],
    allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<{ts: string, values: {siteName: string, value: number}[]}[]> {

    const {from, to} = parseDate(isDateFinished, year, month, week);

    let sheetTimeSeries: {ts: string, values: {siteName: string, value: number}[]}[] = [];

    let currentDate = dayjs(from);
    let toDate = dayjs(to).subtract(1, "day");

    while (currentDate.isBefore(toDate) || currentDate.isSame(toDate)) {
        sheetTimeSeries.push({ts: currentDate.format("DD/MM/YYYY"), values: []});
        currentDate = currentDate.add(1, "day");
    }

    if (site !== null) {
        let timeSeries = await fetchSiteTimeSeries(energyType, site, isDateFinished, year, month, week, signal);

        // If an organization (and site) has been selected
        if (organization !== null) {
            const dist = distributions.find(dist => dist.siteRef.legacyId === site.id && dist.organisationRef.legacyId === organization.id);

            if (dist === undefined) {
                return sheetTimeSeries;
            }

            timeSeries = timeSeries.map(timeValue => ({ts: timeValue.ts, value: timeValue.value * dist.energyPct}));
        }

        for (let i = 0; i < sheetTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => sheetTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "day").format("DD/MM/YYYY"));

            if (energyValue === undefined) {
                sheetTimeSeries[i].values.push({siteName: site.name, value: 0});
            } else {
                sheetTimeSeries[i].values.push({siteName: site.name, value: energyValue.value});
            }
        }

        return sheetTimeSeries;

    } else if (organization !== null) {
        const orgDists = distributions.filter(dist => dist.organisationRef.legacyId === organization.id);
        const filteredDists = orgDists.filter(dist => allSites.find(site => site.id === dist.siteRef.legacyId));

        for (const dist of filteredDists) {
            const distSite = {id: dist.siteRef.legacyId, guid: dist.siteRef.val, name: dist.siteRef.dis};

            const timeSeries = await fetchSiteTimeSeries(energyType, distSite, isDateFinished, year, month, week, signal);

            for (let i = 0; i < sheetTimeSeries.length; i++) {
                const energyValue = timeSeries.find(timeValue => sheetTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "day").format("DD/MM/YYYY"));

                if (energyValue === undefined) {
                    sheetTimeSeries[i].values.push({siteName: distSite.name, value: 0});
                } else {
                    sheetTimeSeries[i].values.push({siteName: distSite.name, value: energyValue.value * dist.energyPct});
                }
            }
        }

        return sheetTimeSeries;

    } else {
        // Total energy use for the chosen campus
        const campusTotal = {id: campus.siteId, guid: campus.siteGuid, name: campus.name + " Campus"};
        const timeSeries = await fetchSiteTimeSeries(energyType, campusTotal, isDateFinished, year, month, week, signal);

        for (let i = 0; i < sheetTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => sheetTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "day").format("DD/MM/YYYY"));

            if (energyValue === undefined) {
                sheetTimeSeries[i].values.push({siteName: campusTotal.name, value: 0});
            } else {
                sheetTimeSeries[i].values.push({siteName: campusTotal.name, value: energyValue.value});
            }
        }

        return sheetTimeSeries;
    }
}

/** Fetches the total energy use of a site within the specified date as a time series directly from the API. */
async function fetchSiteTimeSeries(
    energyType: EnergyType, site: Site, isDateFinished: boolean,
    year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<TimeValue[]> {
    // This request could perhaps be cached
    const virtualSitePoint = await getEntities(null, energyType.queryName, ["point", "virtual", `siteRef:@${site.id}`], null, signal);

    if (virtualSitePoint.length === 0) {
        return [];
    }

    const {from, to} = parseDate(isDateFinished, year, month, week);

    const timeSeries = await getTimeSeries(virtualSitePoint[0].tags.guid, from, to, "P1D", "delta", signal);

    return timeSeries;
}

const exported = {
    getSheetData
}

export default exported;
