import { TimeValue } from './../models/TimeValue';
import { Guid } from './../models/Guid';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Dispatch, SetStateAction } from 'react';

/**
 * Creates a new SignalR connection to the live values hub.
 * @returns The new connection
 */
function getNewConnection(): HubConnection {
    const newConnection = new HubConnectionBuilder()
        .withUrl("https://bmsdata.cas.dtu.dk/hubs/livevalues")
        .withAutomaticReconnect()
        .build();

    return newConnection;
}

/**
 * Uses the given connection and connects to the live values hub. The streamed values are saved using the
 * provided setValue() function.
 * @param {HubConnection | null} connection - The connection to be used
 * @param {Guid} guid - The GUID of the point to get values from
 * @param {Dispatch<SetStateAction<TimeValue | null>>} setValue - The setter to save the streamed values in state
 * @param {Dispatch<SetStateAction<boolean>>} setLoading - Used to display a loading icon while connection is in progress
 */
function connectAndSetValue(
    connection: HubConnection | null,
    guid: Guid, setValue: Dispatch<SetStateAction<TimeValue | null>>,
    setLoading: Dispatch<SetStateAction<boolean>>
): void {
    if (connection) {
        setLoading(true);

        connection.start()
            .then(result => {
                connection.on("updatedValue", jsonValue => {
                    const pointValues = JSON.parse(jsonValue);

                    for (const pointValue of pointValues) {
                        if (pointValue.id === guid.toString()) {
                            setValue(pointValue);
                            setLoading(false);
                        }
                    }
                });
            })
            .catch(e => console.log("Connection failed: ", e));
    }
}

const exported = {
    getNewConnection,
    connectAndSetValue
}

export default exported;