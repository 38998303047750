import { AuthorizedFetchAsync } from "../../authorization/AuthorizedFetch";
import { DistEntity } from "../../models/DistEntity";

/**
 * Fetches entities from the area distribution API endpoint (v3) based on the currently selected campus.
 * @param {number} campusId - The ID of the selected campus
 * @param {AbortSignal} [signal] - Passed to the fetch request so that it can be cancelled if necessary
 */
async function getDistEntities(campusId: number, signal?: AbortSignal): Promise<DistEntity[]> {
    const query = {
        distribution: {
            _kind: "marker"
        }
    };

    const response = await AuthorizedFetchAsync("entities/query", {
        method: "POST",
        headers: {
            "Content-type": "application/json",
        },
        signal: signal,
        body: JSON.stringify(query)
    }, "v3")

    if (!response.ok) {
        throw new Error("HTTP Error: " + response.status);
    }

    return await response.json();
}

const exported = {
    getDistEntities
};

export default exported;
