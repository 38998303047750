import { Campus } from "../types/Campus";
import { Site } from "../types/Site";
import { getEntities } from "./api/EntityService";

/**
 * Returns a list of all sites within the selected campus fetched from the API. Sites with the tag 'hidden' are excluded.
 * @param {Campus} campus - The selected campus that limits the returned sites
 * @param {AbortSignal} [signal] - Passed to the API fetch request so that it can be cancelled if necessary
 * @returns An array of sites
 */
async function getAllSites(campus: Campus, signal?: AbortSignal): Promise<Site[]> {
    const sites = await getEntities(null, null, ["site", `siteRef:@${campus.id}`], null, signal);
    const filtered = sites.filter(site => !site.tags.markerTags.includes("hidden"));
    filtered.sort((s1, s2) => s1.tags.displayName.localeCompare(s2.tags.displayName));
    const mapped = filtered.map(site => ({id: site.tags.id, guid: site.tags.guid.toString(), name: site.tags.displayName}));
    return mapped;
}

const exported = {
    getAllSites
};

export default exported;
