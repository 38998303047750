import { EnergyType } from './../types/EnergyType';
import { parseDate } from "./api/DateParser";
import { getTimeSeries } from "./api/TimeSeriesService";
import NoDataError from "../errors/NoDataError";
import { Site } from '../types/Site';
import { Organization } from '../types/Organization';
import { TimeValue } from '../models/TimeValue';
import { Warning } from '../types/Warning';
import { DistEntity } from '../models/DistEntity';
import dayjs, { ManipulateType } from 'dayjs';
import { Campus } from '../types/Campus';
import { getValidatedValue } from '../util/EnergyUtils';
import VirtualPointService from './VirtualPointService';
import { DistributionType } from './EnergyDistibutionCalculations';
import { get } from 'http';
import { getCategorisedDayUsage, getCategorisedUsageInHours, getEnergyCategorisedUsage } from './CategorisedEnergyService';


/**
 * Fetches the total energy use of a campus, organisation or site within the specified date directly from the API.
 * @param {EnergyType} energyType - The type of energy to retrieve a value for (electricity, water, heat or cooling)
 * @param {Campus} campus - The selected campus
 * @param {Organization | null} organization - The selected organization
 * @param {Site | null} site - The selected site/building
 * @param {DistEntity[]} distributions - List of distribution entities retrieved from DistributionService.getDistEntities()
 * @param {Site[]} allSites - List of all sites within the selected campus retrieved from SiteService.getAllSites()
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns An object containing the total energy use as a number; the transformed time series to be shown in the details graph;
 * and any warning that may have occurred in the process of validating the time series.
 */
async function getEnergyUsage(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null, distributions: DistEntity[],
    allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, distributionType: DistributionType = DistributionType.STANDARD, signal?: AbortSignal
): Promise<{ value: number, timeSeries: TimeValue[], warning: Warning }> {

    if (energyType === EnergyType.ELEC && distributionType === DistributionType.CATEGORISED) {
        return await getEnergyCategorisedUsage(energyType, campus, organization, site, distributions, allSites, isDateFinished, year, month, week, signal);
    }

    return await getStandardEnergyUsage(energyType, campus, organization, site, distributions, allSites, isDateFinished, year, month, week, signal);
}

async function getStandardEnergyUsage(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null, distributions: DistEntity[],
    allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<{ value: number, timeSeries: TimeValue[], warning: Warning }> {

    // Returned from this method as timeSeries and used as data for the details graph
    let energyTimeSeries: TimeValue[] = [];

    // Used with dayjs to subtract or add to dates when filling values in the energyTimeSeries array
    // Needed because the API returns e.g. the energy value for January with a timestamp of "02-01" (February) - same for days
    let dayOrMonth: ManipulateType = month === undefined && week === undefined ? "month" : "day";

    const { from, to } = parseDate(true, year, month, week);

    let currentDate = dayjs(from);
    let toDate = dayjs(to).subtract(1, "day");

    // Populates the timeSeries array beforehand so that the details graph will always have the same columns regardless of missing data
    while (currentDate.isBefore(toDate) || currentDate.isSame(toDate)) {
        energyTimeSeries.push({ ts: currentDate.format(), value: 0 });
        currentDate = currentDate.add(1, dayOrMonth);
    }

    if (site !== null) {
        let { value, timeSeries, warning } = await fetchSiteUsage(energyType, site, isDateFinished, year, month, week, signal);

        // If an organization (and site) has been selected
        if (organization !== null) {
            const dist = distributions.find(dist => dist.siteRef.legacyId === site.id && dist.organisationRef.legacyId === organization.id);

            if (dist === undefined) {
                throw new Error("The site distribution percentage could not be found for the chosen organization");
            }

            // Return the amount of energy that the organization is responsible for
            value = dist.energyPct * value;
            timeSeries = timeSeries.map(timeValue => ({ ts: timeValue.ts, value: timeValue.value * dist.energyPct }));
        }

        for (let i = 0; i < energyTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format());

            // Skip negative values (they will be shown as 0 (null) in the graph)
            if (energyValue === undefined || energyValue.value < 0) continue;

            energyTimeSeries[i].value = energyValue.value;
        }

        return { value, timeSeries: energyTimeSeries, warning };
    } else if (organization !== null) {
        const orgDists = distributions.filter(dist => dist.organisationRef.legacyId === organization.id);
        const filteredDists = orgDists.filter(dist => allSites.find(site => site.id === dist.siteRef.legacyId));

        let total = -1;

        // The last warning/error encountered will be the one shown
        let lastWarning = Warning.NONE;
        let lastError: Error | null = null;

        // Loop through every site that this organization is present in
        for (const dist of filteredDists) {
            const distSite = { id: dist.siteRef.legacyId, guid: dist.siteRef.val, name: dist.siteRef.dis };

            try {
                let { value, timeSeries, warning } = await fetchSiteUsage(energyType, distSite, isDateFinished, year, month, week, signal);

                if (total === -1) {
                    total = 0;
                }

                // Add up the organization's energy usage from each site
                total += dist.energyPct * value;
                lastWarning = warning;

                for (let i = 0; i < energyTimeSeries.length; i++) {
                    const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format());

                    // Skip negative values
                    if (energyValue === undefined || energyValue.value < 0) continue;

                    energyTimeSeries[i].value += dist.energyPct * energyValue.value;
                }
            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                lastError = error;
            }
        }

        // If no data was found, the last error encountered will be displayed
        if (total === -1 && lastError !== null) {
            throw lastError;
        }

        return { value: total, timeSeries: energyTimeSeries, warning: lastWarning };
    } else {
        // Show total energy use for the chosen campus
        const campusTotal = { id: campus.siteId, guid: campus.siteGuid, name: campus.name + " Campus" };
        let { value, timeSeries, warning } = await fetchSiteUsage(energyType, campusTotal, isDateFinished, year, month, week, signal);

        for (let i = 0; i < energyTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format());

            // Skip negative values (they will be shown as 0 (null) in the graph)
            if (energyValue === undefined || energyValue.value < 0) continue;

            energyTimeSeries[i].value = energyValue.value;
        }

        return { value, timeSeries: energyTimeSeries, warning };
    }
}

/**
 * Fetches the total energy use of a site within the specified date directly from the API.
 * @param {EnergyType} energyType - The type of energy to retrieve a value for (electricity, water, heat or cooling)
 * @param {Site} site - The site to retrieve a value for
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns An object containing the total energy use as a number; the returned time series from the API;
 * and any warning that may have occurred in the process of validating the time series.
 */
async function fetchSiteUsage(
    energyType: EnergyType, site: Site, isDateFinished: boolean,
    year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<{ value: number, timeSeries: TimeValue[], warning: Warning }> {
    const siteVirtualPointGuid = await VirtualPointService.getSiteVirtualPoint(energyType, site, signal);

    let { from, to, interval } = parseDate(isDateFinished, year, month, week);

    let timeSeries: TimeValue[] = [];

    // If the current year has been selected (and no month/week) then we want to fetch twice
    // First to get data for the completed months and then data for the unfinished month (in days)
    if (!isDateFinished && month === undefined && week === undefined) {
        // If we are not in January
        if (dayjs().month() !== 0) {
            // Fetch data for the completed months
            timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, "P1M", "delta", signal);
        }

        // Temporary fix to handle Ballerup API returning values in current month (instead of nothing as expected by the code below)
        if (timeSeries.length > 1) {
            if (dayjs(timeSeries[timeSeries.length - 1].ts).month() === dayjs(timeSeries[timeSeries.length - 2].ts).month()) {
                timeSeries.pop();
            }
        }

        from = dayjs(to).set("date", 1).format("YYYY-MM-DD");
        // Fetch data for the last unfinished month (in days)
        const unfinishedMonthData = await getTimeSeries(siteVirtualPointGuid, from, to, "P1D", "delta", signal);

        let value = unfinishedMonthData.reduce(((acc, current) => current.value < 0 ? acc : current.value + acc), 0);

        timeSeries.push({ ts: dayjs(from).add(1, "month").format(), value: value });
    } else {
        timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, interval, "delta", signal);
    }

    if (timeSeries.length === 0) {
        throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (year: ${year} month: ${month} week: ${week})`);
    }

    const { value, warning } = getValidatedValue(timeSeries, energyType, isDateFinished, year, month, week);

    return { value, timeSeries, warning };
}

/**
 * Fetches the hourly energy use of a campus, organisation or site for a specific day directly from the API.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Campus} campus - The selected campus
 * @param {Organization | null} organization - The selected organization
 * @param {Site | null} site - The selected site/building
 * @param {DistEntity[]} distributions - List of distribution entities retrieved from DistributionService.getDistEntities()
 * @param {Site[]} allSites - List of all sites within the selected campus retrieved from SiteService.getAllSites()
 * @param {string} date - The specific day to fetch data for (as an ISO date string)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The hourly energy data as an array of time values
 */
async function getEnergyDayUsage(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null,
    distributions: DistEntity[], allSites: Site[], date: string, ditributionType: DistributionType = DistributionType.STANDARD, signal?: AbortSignal
): Promise<TimeValue[]> {
    let result = [];

    if (ditributionType === DistributionType.STANDARD) {
        result = await getStandardDayUsage(energyType, campus, organization, site, distributions, allSites, date, signal);
    }
    else {
        result = await getCategorisedDayUsage(energyType, campus, organization, site, distributions, allSites, date, signal);
    }

    return result;
}


async function getStandardDayUsage(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null,
    distributions: DistEntity[], allSites: Site[], date: string, signal?: AbortSignal
): Promise<TimeValue[]> {

    let energyTimeSeries: TimeValue[] = [];

    for (let i = 0; i < 24; i++) {
        energyTimeSeries.push({ ts: dayjs(date).set("hour", i).format(), value: 0 });
    }

    if (site !== null) {
        let timeSeries = await fetchSiteDayUsage(energyType, site, date, signal);

        // If an organization (and site) has been selected
        if (organization !== null) {
            const dist = distributions.find(dist => dist.siteRef.legacyId === site.id && dist.organisationRef.legacyId === organization.id);

            if (dist === undefined) {
                throw new Error("The site distribution percentage could not be found for the chosen organization");
            }

            // Return the amount of energy that the organization is responsible for
            timeSeries = timeSeries.map(timeValue => ({ ts: timeValue.ts, value: timeValue.value * dist.energyPct }));
        }

        for (let i = 0; i < energyTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format());

            // Skip negative values (they will be shown as 0 (null) in the graph)
            if (energyValue === undefined || energyValue.value < 0) continue;

            energyTimeSeries[i].value = energyValue.value;
        }

        return energyTimeSeries;
    } else if (organization !== null) {
        const orgDists = distributions.filter(dist => dist.organisationRef.legacyId === organization.id);
        const filteredDists = orgDists.filter(dist => allSites.find(site => site.id === dist.siteRef.legacyId));

        // The last error encountered will be the one shown
        let lastError: Error | null = null;

        for (const dist of filteredDists) {
            const distSite = { id: dist.siteRef.legacyId, guid: dist.siteRef.val, name: dist.siteRef.dis };

            try {
                let timeSeries = await fetchSiteDayUsage(energyType, distSite, date, signal);

                for (let i = 0; i < energyTimeSeries.length; i++) {
                    const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format());

                    // Skip negative values
                    if (energyValue === undefined || energyValue.value < 0) continue;

                    energyTimeSeries[i].value += dist.energyPct * energyValue.value;
                }
            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                lastError = error;
            }
        }

        // If no data was found, the last error encountered will be displayed
        if (lastError !== null) throw lastError;

        return energyTimeSeries;
    } else {
        // Show total energy use for the chosen campus
        const campusTotal = { id: campus.siteId, guid: campus.siteGuid, name: campus.name + " Campus" };
        let timeSeries = await fetchSiteDayUsage(energyType, campusTotal, date, signal);

        for (let i = 0; i < energyTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format());

            // Skip negative values (they will be shown as 0 (null) in the graph)
            if (energyValue === undefined || energyValue.value < 0) continue;

            energyTimeSeries[i].value = energyValue.value;
        }

        return energyTimeSeries;
    }
}

/**
 * Fetches the hourly energy use of a site on a specific day directly from the API.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Site} site - The site to retrieve values for
 * @param {string} date - The specific day to fetch data for (as an ISO date string)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The time series returned by the API
 */
async function fetchSiteDayUsage(energyType: EnergyType, site: Site, date: string, signal?: AbortSignal): Promise<TimeValue[]> {
    const siteVirtualPointGuid = await VirtualPointService.getSiteVirtualPoint(energyType, site, signal);

    let from = dayjs(date).format("YYYY-MM-DD");
    let to = dayjs(from).add(1, "day").format("YYYY-MM-DD");

    let timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, "PT1H", "delta", signal);

    if (timeSeries.length === 0) {
        throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (${date})`);
    }

    return timeSeries;
}

/**
 * Fetches the hourly energy use of a campus, organisation or site within a specified date range directly from the API. Used for the load duration graph.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Campus} campus - The selected campus
 * @param {Organization | null} organization - The selected organization
 * @param {Site | null} site - The selected site/building
 * @param {DistEntity[]} distributions - List of distribution entities retrieved from DistributionService.getDistEntities()
 * @param {Site[]} allSites - List of all sites within the selected campus retrieved from SiteService.getAllSites()
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The hourly energy data as an array of time values
 */
async function getEnergyUsageInHours(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null,
    distributions: DistEntity[], allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, distributionType: DistributionType = DistributionType.STANDARD, signal?: AbortSignal
): Promise<TimeValue[]> {
    let result = [];
    if (distributionType === DistributionType.STANDARD) {
        result = await getStandardUsageInHours(energyType, campus, organization, site, distributions, allSites, isDateFinished, year, month, week, signal);
    }
    else {
        result = await getCategorisedUsageInHours(energyType, campus, organization, site, distributions, allSites, isDateFinished, year, month, week, signal);
    }

    return result;
}

/**
 * Fetches the hourly energy use of a campus, organisation or site within a specified date range directly from the API. Used for the load duration graph.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Campus} campus - The selected campus
 * @param {Organization | null} organization - The selected organization
 * @param {Site | null} site - The selected site/building
 * @param {DistEntity[]} distributions - List of distribution entities retrieved from DistributionService.getDistEntities()
 * @param {Site[]} allSites - List of all sites within the selected campus retrieved from SiteService.getAllSites()
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The hourly energy data as an array of time values
 */
async function getStandardUsageInHours(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null,
    distributions: DistEntity[], allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<TimeValue[]> {
    if (site !== null) {
        let timeSeries = await fetchSiteUsageInHours(energyType, site, isDateFinished, year, month, week, signal);

        // If an organization (and site) has been selected
        if (organization !== null) {
            const dist = distributions.find(dist => dist.siteRef.legacyId === site.id && dist.organisationRef.legacyId === organization.id);

            if (dist === undefined) {
                throw new Error("The site distribution percentage could not be found for the chosen organization");
            }

            // Return the amount of energy that the organization is responsible for
            timeSeries = timeSeries.map(timeValue => ({ ts: timeValue.ts, value: timeValue.value * dist.energyPct }));
        }

        return timeSeries;
    } else if (organization !== null) {
        const orgDists = distributions.filter(dist => dist.organisationRef.legacyId === organization.id);
        const filteredDists = orgDists.filter(dist => allSites.find(site => site.id === dist.siteRef.legacyId));

        const timeSeries: TimeValue[] = [];

        // The last error encountered will be the one shown
        let lastError: Error | null = null;

        for (const dist of filteredDists) {
            const distSite = { id: dist.siteRef.legacyId, guid: dist.siteRef.val, name: dist.siteRef.dis };

            try {
                const siteTimeSeries = await fetchSiteUsageInHours(energyType, distSite, isDateFinished, year, month, week, signal);

                for (const timeValue of siteTimeSeries) {
                    const index = timeSeries.findIndex(t => t.ts === timeValue.ts);

                    if (index !== -1) {
                        timeSeries[index] = { ...timeSeries[index], value: timeSeries[index].value + (dist.energyPct * timeValue.value) };
                    } else {
                        timeSeries.push({ ...timeValue, value: dist.energyPct * timeValue.value });
                    }
                }

            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                lastError = error;
            }
        }

        // If no data was found, the last error encountered will be displayed
        if (lastError !== null) throw lastError;

        // Because elements have been pushed they may not be ordered anymore
        timeSeries.sort((a, b) => a.ts.localeCompare(b.ts));

        return timeSeries;
    } else {
        // Show total energy use for the chosen campus
        const campusTotal = { id: campus.siteId, guid: campus.siteGuid, name: campus.name + " Campus" };
        let timeSeries = await fetchSiteUsageInHours(energyType, campusTotal, isDateFinished, year, month, week, signal);
        return timeSeries;
    }
}

/**
 * Fetches the hourly energy use of a site within the specified date range directly from the API.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Site} site - The site to retrieve values for
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The time series returned by the API with hourly values
 */
async function fetchSiteUsageInHours(
    energyType: EnergyType, site: Site, isDateFinished: boolean,
    year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<TimeValue[]> {
    const siteVirtualPointGuid = await VirtualPointService.getSiteVirtualPoint(energyType, site, signal);

    const { from, to } = parseDate(isDateFinished, year, month, week);

    let timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, "PT1H", "delta", signal);

    if (timeSeries.length === 0) {
        throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (year: ${year} month: ${month} week: ${week})`);
    }

    return timeSeries;
}

const exported = {
    getEnergyUsage,
    getEnergyDayUsage,
    getEnergyUsageInHours
}

export default exported;
