import { Alert, Box, Button, CircularProgress, Grid, Snackbar, styled, Tooltip, tooltipClasses, TooltipProps, useTheme } from '@mui/material';
import DropdownBox from './DropdownBox';
import SiteService from '../../services/SiteService';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import OrganizationService from '../../services/OrganizationService';
import DateSelectorBox from './DateSelectorBox';
import { Site } from '../../types/Site';
import { Campus } from '../../types/Campus';
import { Organization } from '../../types/Organization';
import { DistEntity } from '../../models/DistEntity';
import DistributionService from '../../services/api/DistributionService';
import XLSX from 'xlsx';
import SheetDataService from '../../services/SheetDataService';
import { EnergyType } from '../../types/EnergyType';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DateSelection } from '../../types/DateSelection';
import { allCampuses } from '../../App';

interface ToolbarProps {
    selectedCampus: Campus,
    setSelectedCampus: Dispatch<SetStateAction<Campus>>,
    selectedOrganization: Organization | null,
    setSelectedOrganization: Dispatch<SetStateAction<Organization | null>>,
    selectedSite: Site | null,
    setSelectedSite: Dispatch<SetStateAction<Site | null>>,
    allSites: Site[],
    setAllSites: Dispatch<SetStateAction<Site[]>>,
    allOrganizations: Organization[],
    setAllOrganizations: Dispatch<SetStateAction<Organization[]>>,
    distributions: DistEntity[],
    setDistributions: Dispatch<SetStateAction<DistEntity[]>>,
    selectedDate: DateSelection,
    setSelectedDate: Dispatch<SetStateAction<DateSelection>>,
    isDateFinished: boolean,
    setDetailNavStack: Dispatch<SetStateAction<DateSelection[]>>,
    setDayViewState: Dispatch<SetStateAction<{isOpen: boolean, date: string}>>
}

export default function Toolbar(props: ToolbarProps) {
    // The site and organization lists being displayed in the dropdowns (which are filtered depending on current selection)
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [orgs, setSites] = useState<Site[]>([]);

    const [loadingSites, setLoadingSites] = useState(false);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);

    const [exportIsLoading, setExportIsLoading] = useState(false);

    const [toastOpen, setToastOpen] = useState({open: false, msg: ""});

    const controllerRef = useRef<AbortController | null>();

    const theme = useTheme();

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 155,
            backgroundColor: "black",
            fontSize: "0.9rem",
            fontFamily: "NeoSansProLight"
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: "black"
        },
        [`& .${tooltipClasses.tooltipPlacementTop}`]: {
            top: "4px"
        }
    });

    // This is necessary in order to call it within useEffect below (otherwise it gives a warning)
    const setSelectedSite = props.setSelectedSite;
    const setSelectedOrganization = props.setSelectedOrganization;
    const setDistributions = props.setDistributions;
    const setAllSites = props.setAllSites;
    const setAllOrganizations = props.setAllOrganizations;

    useEffect(() => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        controllerRef.current = new AbortController();

        setSelectedOrganization(null);
        setSelectedSite(null);

        // Ensures that the dropdown will show "loading" while fetch is in progress
        setAllSites([]);
        setAllOrganizations([]);
        setLoadingSites(true);
        setLoadingOrganizations(true);

        DistributionService.getDistEntities(props.selectedCampus.id, controllerRef.current?.signal)
            .then(dists => {
                setDistributions(dists);

                SiteService.getAllSites(props.selectedCampus, controllerRef.current?.signal)
                    .then(sites => {
                        setAllSites(sites);
                        setSites(sites);
                        setLoadingSites(false);
                    })
                    .catch(() => {});

                OrganizationService.getAllOrganizations(dists, controllerRef.current?.signal)
                    .then(orgs => {
                        setAllOrganizations(orgs);
                        setOrganizations(orgs);
                        setLoadingOrganizations(false);
                    })
                    .catch(() => {});
            })
            .catch(() => {});
    }, [props.selectedCampus, setSelectedOrganization, setSelectedSite, setDistributions, setAllSites, setAllOrganizations]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (props.allSites.length) {
            const paramSite = props.allSites.find(site => site.id.toString() === searchParams.get("site"));
            paramSite && setSelectedSite(paramSite);
        }

        if (props.allOrganizations.length) {
            const paramOrg = props.allOrganizations.find(org => org.id.toString() === searchParams.get("org"));
            paramOrg && setSelectedOrganization(paramOrg);
        }
    }, [props.allSites, props.allOrganizations, setSelectedSite, setSelectedOrganization]);

    useEffect(() => {
        if (props.selectedOrganization !== null) {
            const selectedOrganizationId = props.selectedOrganization.id; // TS needs this otherwise it gives a "possibly null" error

            const filteredDists = props.distributions.filter(dist => dist.organisationRef.legacyId === selectedOrganizationId);
            const filteredSites = props.allSites.filter(site => filteredDists.find(dist => dist.siteRef.legacyId === site.id));

            setSites(filteredSites);
        } else {
            setSites(props.allSites);
        }
    }, [props.selectedOrganization, setSelectedSite, props.allSites, props.distributions]);

    useEffect(() => {
        if (props.selectedSite !== null) {
            const selectedSiteId = props.selectedSite.id;

            const filteredDists = props.distributions.filter(dist => dist.siteRef.legacyId === selectedSiteId);
            const filteredOrgs = props.allOrganizations.filter(org => filteredDists.find(dist => dist.organisationRef.legacyId === org.id));

            setOrganizations(filteredOrgs);
        } else {
            setOrganizations(props.allOrganizations);
        }
    }, [props.selectedSite, props.allOrganizations, props.distributions]);

    // Used as an onClick handler for the download data button (in DateSelectorBox.tsx)
    async function handleDownload() {
        setExportIsLoading(true);

        try {
            const dataElec = await SheetDataService.getSheetData(
                EnergyType.ELEC, props.selectedCampus, props.selectedOrganization, props.selectedSite, props.distributions,
                props.allSites, props.isDateFinished, props.selectedDate.year, props.selectedDate.month, props.selectedDate.week
            );
            const dataWater = await SheetDataService.getSheetData(
                EnergyType.WATER, props.selectedCampus, props.selectedOrganization, props.selectedSite, props.distributions,
                props.allSites, props.isDateFinished, props.selectedDate.year, props.selectedDate.month, props.selectedDate.week
            );
            const dataHeat = await SheetDataService.getSheetData(
                EnergyType.HEAT, props.selectedCampus, props.selectedOrganization, props.selectedSite, props.distributions,
                props.allSites, props.isDateFinished, props.selectedDate.year, props.selectedDate.month, props.selectedDate.week
            );
            const dataCooling = await SheetDataService.getSheetData(
                EnergyType.COOLING, props.selectedCampus, props.selectedOrganization, props.selectedSite, props.distributions,
                props.allSites, props.isDateFinished, props.selectedDate.year, props.selectedDate.month, props.selectedDate.week
            );

            const workbook = XLSX.utils.book_new();

            addWorksheet(workbook, dataElec.sheetData, dataElec.header, "Elforsyning");
            addWorksheet(workbook, dataWater.sheetData, dataWater.header, "Brugsvand");
            addWorksheet(workbook, dataHeat.sheetData, dataHeat.header, "Varmeenergi");
            addWorksheet(workbook, dataCooling.sheetData, dataCooling.header, "Køleenergi");

            XLSX.writeFileXLSX(workbook, "energy_data.xlsx", {bookType: "xlsx", type: "binary"});
        } catch (error: any) {
            setToastOpen({open: true, msg: error.message});
        }

        setExportIsLoading(false);
    }

    function addWorksheet(workbook: XLSX.WorkBook, data: {[site: string]: any}[], header: string[][], title: string) {
        const worksheet = XLSX.utils.json_to_sheet([]);

        if (data.length !== 0) {
            let columns = [];

            for (let i = 0; i < Object.keys(data[0]).length; i++) {
                columns.push({ width: 20 });
            }

            worksheet['!cols'] = columns;
        }

        XLSX.utils.sheet_add_aoa(worksheet, header);
        XLSX.utils.sheet_add_json(worksheet, data, { origin: 'A2', skipHeader: true });
        XLSX.utils.book_append_sheet(workbook, worksheet, title);
    }

    function handleClose(event?: React.SyntheticEvent | Event, reason?: string) {
        if (reason === 'clickaway') {
          return;
        }

        setToastOpen(prev => ({...prev, open: false}));
    };

    return (
        <Box>
            <Grid container spacing={{xs: 3, xl: 4.5}} columns={10} justifyContent="center">
                <Grid item xs={10} sm={5} md>
                    <DropdownBox
                        title="Campus" options={allCampuses} getOptionLabel={campus => campus.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        selected={props.selectedCampus} setSelected={props.setSelectedCampus}
                        disableTyping={true} isLoading={false} param="campus" setDayViewState={props.setDayViewState}
                    />
                </Grid>
                <Grid item xs={10} sm={5} md>
                    <DropdownBox
                        title="Organisation" options={organizations} getOptionLabel={org => org.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        selected={props.selectedOrganization} setSelected={props.setSelectedOrganization}
                        disableTyping={false} isLoading={loadingOrganizations} param="org" setDayViewState={props.setDayViewState}
                    />
                </Grid>
                <Grid item xs={10} sm={5} md>
                    <DropdownBox
                        title="Bygning" options={orgs} getOptionLabel={site => site.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        selected={props.selectedSite} setSelected={props.setSelectedSite}
                        disableTyping={false} isLoading={loadingSites} param="site" setDayViewState={props.setDayViewState}
                    />
                </Grid>
                <Grid item xs={10} sm>
                    <DateSelectorBox
                        date={props.selectedDate} setDate={props.setSelectedDate}
                        setDetailNavStack={props.setDetailNavStack} setDayViewState={props.setDayViewState}
                    />
                </Grid>
                <Grid item xs="auto">
                    <CustomTooltip arrow title={exportIsLoading ? "" : "Download regneark for denne periode"} placement="top">
                        <Button
                            onClick={handleDownload}
                            disabled={exportIsLoading}
                            sx={{
                                height: "100%",
                                minWidth: 0,
                                backgroundColor: "white",
                                boxShadow: theme.shadows[1],
                                transition: "background 0.2s, color 0.2s",
                                "&:hover": { backgroundColor: "black" }
                            }}
                        >
                            { exportIsLoading
                                ? <CircularProgress disableShrink size="1.75rem" thickness={5} sx={{ color: "black", m: "6px" }} />
                                : <FileDownloadOutlinedIcon sx={{fontSize: "2.5rem", color: "white", mixBlendMode: "difference"}} />
                            }
                        </Button>
                    </CustomTooltip>
                </Grid>
            </Grid>
            <Snackbar open={toastOpen.open} autoHideDuration={6000} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  Der opstod en fejl under hentning af data til tabellen ({toastOpen.msg})
                </Alert>
            </Snackbar>
        </Box>
    );
}
