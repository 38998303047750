import dayjs from "dayjs";
import NoDataError from "../errors/NoDataError";
import { TimeValue } from "../models/TimeValue";
import { EnergyType } from "../types/EnergyType";
import { Warning } from "../types/Warning";

/**
 * Calculates a total energy value from a time series by adding up all numbers and performing validation checks.
 * @param {TimeValue[]} timeSeries - The time series to be calculated
 * @param {EnergyType} energyType - The type of energy (used for potential error message)
 * @param {boolean} isDateFinished - Determines whether we are in the current year/month/week or not
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The calculated value along with a warning if some validation failed
 */
export function getValidatedValue(
    timeSeries: TimeValue[], energyType: EnergyType, isDateFinished: boolean, year: number, month?: number, week?: number
): {value: number, warning: Warning} {
    let value = 0;
    let warning = Warning.NONE;

    // If every value is negative
    // Deprecated: This is not a valid check, as negative values are allowed in some cases
    /*
    if (timeSeries.every(elem => elem.value < 0)) {
        throw new NoDataError(`The energy value for "${energyType.displayName}" was negative (year: ${year} month: ${month} week: ${week})`);
    }
    */

    // Sums the values of all entries in the array (and skips negative values)
    const sumPositives = () =>
    timeSeries.reduce(((acc, current) => {
        if (current.value < 0) {
            warning = Warning.NEGATIVE_VALUE;
            return acc;
        }
        return current.value + acc;
    }), 0);

    // If the selection is the current year/month/week
    if (!isDateFinished) {
        value = sumPositives();

    } else {
        // One year selection
        if (month === undefined && week === undefined) {
            value = sumPositives();

            if (timeSeries.length < 12) {
                warning = Warning.MISSING_DATA;
            }
        }

        // One month selection
        else if (month !== undefined) {
            value = sumPositives();

            if (timeSeries.length < dayjs(year).month(month).daysInMonth()) {
                warning = Warning.MISSING_DATA;
            }
        }

        // One week selection
        else if (week !== undefined) {
            value = sumPositives();

            if (timeSeries.length < 7) {
                warning = Warning.MISSING_DATA;
            }
        }
    }

    return {value, warning};
}
