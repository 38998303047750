import { Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { TimeValue } from "../../models/TimeValue";
import { UsageType } from "../../types/UsageType";

interface PriceGraphProps {
    energyPrices: TimeValue[],
    energyTariffs: TimeValue[],
    graphState: {isOpen: boolean, usageType: UsageType, date: string}
}

export default function PriceGraph(props: PriceGraphProps) {
    const theme = useTheme();

    const [focusBar, setFocusBar] = useState<number | undefined>();

    const isToday = dayjs().isSame(props.graphState.date, "date");

    const data = [];

    // For every hour today
    for (let i = 0; i < 24; i++) {
        const searchHour = i + 1 === 24 ? 0 : i + 1;

        const priceValue = props.energyPrices.find(tv => dayjs(tv.ts).hour() === searchHour);
        const tariffValue = props.energyTariffs.find(tv => dayjs(tv.ts).hour() === searchHour);

        data.push({
            time: i < 10 ? "0" + i : i.toString(),
            price: priceValue ? priceValue.value / 1000 : null,
            tariff: tariffValue ? tariffValue.value : null
        });
    }

    const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
        const nextHour = parseInt(label) + 1;
        const nextLabel = nextHour < 10 ? "0" + nextHour : nextHour.toString();
        const labelFormatted = label === "23" ? "23:00 - 00:00" : `${label}:00 - ${nextLabel}:00`;

        if (active && payload && payload.length) {
            const price = payload[0].payload.price;
            const tariff = payload[0].payload.tariff;
            const total = price && tariff ? price + tariff : null;

            return (
                <Box p={1.5} borderRadius="5px" border="1px solid black" sx={{backgroundColor: "white"}}>
                    <Typography lineHeight={1} mb={1}>{labelFormatted}</Typography>
                    { price &&
                        <Typography lineHeight={1} color={theme.palette.primary.main}>
                            Spotpris: {price.toLocaleString("da-DK", {maximumFractionDigits: 3})} DKK pr. kWh
                        </Typography>
                    }
                    { tariff &&
                        <Typography mt={1} lineHeight={1} color="#19F097">
                            Tarif: {tariff.toLocaleString("da-DK", {maximumFractionDigits: 3})} DKK pr. kWh
                        </Typography>
                    }
                    { total &&
                        <Typography mt={1} lineHeight={1} fontFamily="NeoSansProBold">
                            Total: {total.toLocaleString("da-DK", {maximumFractionDigits: 3})} DKK pr. kWh
                        </Typography>
                    }
                </Box>
            );
        }

        return null;
    };

    return (
        <ResponsiveContainer width="100%" height={340}>
            <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    right: 50
                }}
                onMouseLeave={state => setFocusBar(undefined)}
                onMouseMove={state => {
                    if (state.isTooltipActive) {
                      setFocusBar(state.activeTooltipIndex);
                    } else {
                      setFocusBar(undefined);
                    }
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tickSize={8} tickMargin={5} />
                <YAxis width={65} tickSize={8} tickMargin={5} tickFormatter={tick => tick.toLocaleString("da-DK", {maximumFractionDigits: 2})} />
                <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ outline: "none" }}
                    cursor={false}
                    isAnimationActive={false}
                />
                <Legend
                    wrapperStyle={{paddingLeft: 70}}
                    formatter={(value, entry, index) => (
                        <Typography
                            display="inline" position="relative" top="2px" marginRight={2}
                        >
                            {value}
                        </Typography>
                    )}
                />
                <Bar dataKey="tariff" stackId="a" fill="#19F097" maxBarSize={35} name="Tarif">
                    {
                        data.map((entry, index) => {
                            let barColor = "";

                            if (isToday && dayjs().hour() === parseInt(entry.time)) {
                                barColor = "#0C985E";
                            } else if (focusBar === index) {
                                barColor = "#21CE86";
                            } else {
                                barColor = "#19F097";
                            }

                            return <Cell key={`cell-${index}`} fill={barColor} />
                        })
                    }
                </Bar>
                <Bar dataKey="price" stackId="a" fill={theme.palette.primary.main} radius={[3, 3, 0, 0]} maxBarSize={35} name="Spotpris">
                    {
                        data.map((entry, index) => {
                            let barColor = "";

                            if (isToday && dayjs().hour() === parseInt(entry.time)) {
                                barColor = theme.palette.primary.dark;
                            } else if (focusBar === index) {
                                barColor = "#1ba86d";
                            } else {
                                barColor = theme.palette.primary.main;
                            }

                            return <Cell key={`cell-${index}`} fill={barColor} />
                        })
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    )
}
