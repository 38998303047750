import dayjs, { ManipulateType } from "dayjs";
import { pointCache } from "..";
import NoDataError from "../errors/NoDataError";
import NoPointError from "../errors/NoPointError";
import { DistEntity } from "../models/DistEntity";
import { Entity } from "../models/Entity";
import { Guid } from "../models/Guid";
import { TimeValue } from "../models/TimeValue";
import { Campus } from "../types/Campus";
import { EnergyType } from "../types/EnergyType";
import { Organization } from "../types/Organization";
import { Site } from "../types/Site";
import { Warning } from "../types/Warning";
import { getValidatedValue } from "../util/EnergyUtils";
import { parseDate } from "./api/DateParser";
import { getEntities } from "./api/EntityService";
import { getTimeSeries } from "./api/TimeSeriesService";
import { CategorizedDistribution } from "./EnergyDistibutionCalculations";

type ElecEnergyType = {
    key: string;
    queryTags: string[];
}

type energyUsage = { value: number, timeSeries: TimeValue[], warning: Warning };

const whatever2 = async (): Promise<{ value: number, timeSeries: TimeValue[], warning: Warning }> => {
    return new Promise((resolve) => {
        resolve({ value: 0, timeSeries: [], warning: Warning.NONE });
    });
};
export async function getEnergyCategorisedUsage(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null, distributions: DistEntity[],
    allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<energyUsage> {
    let energyTimeSeries: TimeValue[] = [];
    let totalEnergyValue = 0;

    let dayOrMonth: ManipulateType = month === undefined && week === undefined ? "month" : "day";

    const { from, to } = parseDate(true, year, month, week);

    let currentDate = dayjs(from);
    let toDate = dayjs(to).subtract(1, "day");

    // Populates the timeSeries array beforehand so that the details graph will always have the same columns regardless of missing data
    while (currentDate.isBefore(toDate) || currentDate.isSame(toDate)) {
        energyTimeSeries.push({ ts: currentDate.format(), value: 0 });
        currentDate = currentDate.add(1, dayOrMonth);
    }

    if (site !== null) {
        let t: energyUsage = await fetchSiteUsage("elec_t", ["totalSupply"], energyType, site, isDateFinished, year, month, week, signal);

        if (t.timeSeries.length === 0) {
            throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (year: ${year} month: ${month} week: ${week})`);
        }
        // If an organization (and site) has been selected
        if (organization !== null) {
            let k: energyUsage = await fetchSiteUsage("elec_k", ["equipLoad"], energyType, site, isDateFinished, year, month, week, signal);
            let s: energyUsage = await fetchSiteUsage("elec_s", ["specialLoad"], energyType, site, isDateFinished, year, month, week, signal);
            let u: energyUsage = await fetchSiteUsage("elec_u", ["userLoad"], energyType, site, isDateFinished, year, month, week, signal);
            const dist = distributions.find(dist => dist.siteRef.legacyId === site.id && dist.organisationRef.legacyId === organization.id);

            if (dist === undefined) {
                throw new Error("The site distribution percentage could not be found for the chosen organization");
            }

            // Return the amount of energy that the organization is responsible for
            totalEnergyValue = CategorizedDistribution(t.value, k.value, s.value, u.value, dist);
            for (let i = 0; i < energyTimeSeries.length; i++) {
                const tValue = t.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;
                const kValue = k.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;
                const sValue = s.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;
                const uValue = u.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;

                const value = CategorizedDistribution(tValue, kValue, sValue, uValue, dist);
                // Skip negative values (they will be shown as 0 (null) in the graph)
                if (value === undefined || value < 0) continue;

                energyTimeSeries[i].value = value;
            }
        }
        else {
            totalEnergyValue = t.value;
            for (let i = 0; i < energyTimeSeries.length; i++) {
                const energyValue = t.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format());

                // Skip negative values (they will be shown as 0 (null) in the graph)
                if (energyValue === undefined || energyValue.value < 0) continue;

                energyTimeSeries[i].value = energyValue.value;
            }
        }
        return { value: totalEnergyValue, timeSeries: energyTimeSeries, warning: t.warning };

    }
    else if (organization !== null) {
        const orgDists = distributions.filter(dist => dist.organisationRef.legacyId === organization.id);
        const filteredDists = orgDists.filter(dist => allSites.find(site => site.id === dist.siteRef.legacyId));

        let total = -1;

        // The last warning/error encountered will be the one shown
        let lastWarning = Warning.NONE;
        let lastError: Error | null = null;

        // Loop through every site that this organization is present in
        for (const dist of filteredDists) {
            const distSite = { id: dist.siteRef.legacyId, guid: dist.siteRef.val, name: dist.siteRef.dis };

            try {
                let t: energyUsage = await fetchSiteUsage("elec_t", ["totalSupply"], energyType, distSite, isDateFinished, year, month, week, signal);
                let k: energyUsage = await fetchSiteUsage("elec_k", ["equipLoad"], energyType, distSite, isDateFinished, year, month, week, signal);
                let s: energyUsage = await fetchSiteUsage("elec_s", ["specialLoad"], energyType, distSite, isDateFinished, year, month, week, signal);
                let u: energyUsage = await fetchSiteUsage("elec_u", ["userLoad"], energyType, distSite, isDateFinished, year, month, week, signal);
                if (t.timeSeries.length === 0) {
                    throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (year: ${year} month: ${month} week: ${week})`);
                }

                if (total === -1) {
                    total = 0;
                }

                // Add up the organization's energy usage from each site
                total += CategorizedDistribution(t.value, k.value, s.value, u.value, dist);
                if (lastWarning === Warning.NONE && t.warning !== Warning.NONE) {
                    lastWarning = t.warning;
                }

                for (let i = 0; i < energyTimeSeries.length; i++) {

                    const tValue = t.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;
                    const kValue = k.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;
                    const sValue = s.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;
                    const uValue = u.timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format())?.value ?? 0;

                    const value = CategorizedDistribution(tValue, kValue, sValue, uValue, dist);
                    // Skip negative values
                    if (value === undefined || value < 0) continue;

                    energyTimeSeries[i].value += value;
                }
            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                lastError = error;
            }
        }

        // If no data was found, the last error encountered will be displayed
        if (total === -1 && lastError !== null) {
            throw lastError;
        }

        return { value: total, timeSeries: energyTimeSeries, warning: lastWarning };
    } else {
        // Show total energy use for the chosen campus
        const campusTotal = { id: campus.siteId, guid: campus.siteGuid, name: campus.name + " Campus" };
        let { value, timeSeries, warning } = await fetchSiteUsage(energyType.shortName, energyType.queryTags, energyType, campusTotal, isDateFinished, year, month, week, signal);
        if (timeSeries.length === 0) {
            throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (year: ${year} month: ${month} week: ${week})`);
        }
        for (let i = 0; i < energyTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, dayOrMonth).format());

            // Skip negative values (they will be shown as 0 (null) in the graph)
            if (energyValue === undefined || energyValue.value < 0) continue;

            energyTimeSeries[i].value = energyValue.value;
        }

        return { value, timeSeries: energyTimeSeries, warning };
    }
}

async function fetchSiteUsage(energyTypeKey: string, queryTags: string[],
    energyType: EnergyType, site: Site, isDateFinished: boolean,
    year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<energyUsage> {
    let resultValue = 0;
    let timeSeries: TimeValue[] = [];
    let resultWarning = Warning.NONE;

    const siteVirtualPointGuid = await getSiteVirtualPoint(energyTypeKey, queryTags, site, energyType, signal);
    if (siteVirtualPointGuid) {

        let { from, to, interval } = parseDate(isDateFinished, year, month, week);


        // If the current year has been selected (and no month/week) then we want to fetch twice
        // First to get data for the completed months and then data for the unfinished month (in days)
        if (!isDateFinished && month === undefined && week === undefined) {
            // If we are not in January
            if (dayjs().month() !== 0) {
                // Fetch data for the completed months
                timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, "P1M", "delta", signal);
            }

            // Temporary fix to handle Ballerup API returning values in current month (instead of nothing as expected by the code below)
            if (timeSeries.length > 1) {
                if (dayjs(timeSeries[timeSeries.length - 1].ts).month() === dayjs(timeSeries[timeSeries.length - 2].ts).month()) {
                    timeSeries.pop();
                }
            }

            from = dayjs(to).set("date", 1).format("YYYY-MM-DD");
            // Fetch data for the last unfinished month (in days)
            const unfinishedMonthData = await getTimeSeries(siteVirtualPointGuid, from, to, "P1D", "delta", signal);

            let value = unfinishedMonthData.reduce(((acc, current) => current.value < 0 ? acc : current.value + acc), 0);


            timeSeries.push({ ts: dayjs(from).add(1, "month").format(), value: value });

        } else {
            timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, interval, "delta", signal);
        }

        const { value, warning } = getValidatedValue(timeSeries, energyType, isDateFinished, year, month, week);
        resultValue = value;
        resultWarning = warning;
    }

    return { value: resultValue, timeSeries, warning: resultWarning };
}

async function getSiteVirtualPoint(energyTypeKey: string, queryTags: string[], site: Site, energyType?: EnergyType, signal?: AbortSignal): Promise<Guid> {
    const cachedPoint: string | undefined = pointCache.get(`${energyTypeKey}-${site.id}`);
    let virtualSitePointGuid: Guid;

    if (cachedPoint) {
        virtualSitePointGuid = new Guid(cachedPoint);
    } else {
        const baseTags = ["point", "virtual", `siteRef:@${site.id}`];
        let virtualSitePoint: Entity[] = [];

        if (queryTags.length) {
            const tags: string[] = baseTags.concat(queryTags);

            virtualSitePoint = await getEntities(null, null, tags, null, signal);
        }

        //Deprecated to fetch virtual point from Entity name
        if (!virtualSitePoint.length && energyType) {
            virtualSitePoint = await getEntities(null, energyType.queryName, baseTags, null, signal);
        }

        virtualSitePointGuid = virtualSitePoint[0].tags.guid;
        pointCache.set(`${energyTypeKey}-${site.id}`, virtualSitePointGuid.toString());
    }

    return virtualSitePointGuid;
}



export async function getCategorisedDayUsage(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null,
    distributions: DistEntity[], allSites: Site[], date: string, signal?: AbortSignal
): Promise<TimeValue[]> {

    let energyTimeSeries: TimeValue[] = [];

    for (let i = 0; i < 24; i++) {
        energyTimeSeries.push({ ts: dayjs(date).set("hour", i).format(), value: 0 });
    }

    if (site !== null) {
        let t: TimeValue[] = await fetchSiteDayUsage("elec_t", ["totalSupply"], energyType, site, date, signal);

        if (t.length === 0) {
            throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (${date})`);
        }

        // If an organization (and site) has been selected
        if (organization !== null) {
        let k: TimeValue[] = await fetchSiteDayUsage("elec_k", ["equipLoad"], energyType, site, date, signal);
        let s: TimeValue[] = await fetchSiteDayUsage("elec_s", ["specialLoad"], energyType, site, date, signal);
        let u: TimeValue[] = await fetchSiteDayUsage("elec_u", ["userLoad"], energyType, site, date, signal);
            const dist = distributions.find(dist => dist.siteRef.legacyId === site.id && dist.organisationRef.legacyId === organization.id);

            if (dist === undefined) {
                throw new Error("The site distribution percentage could not be found for the chosen organization");
            }

            // Return the amount of energy that the organization is responsible for
            for (let i = 0; i < energyTimeSeries.length; i++) {
                const tValue = t.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                const kValue = k.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                const sValue = s.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                const uValue = u.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;

                const value = CategorizedDistribution(tValue, kValue, sValue, uValue, dist);
                // Skip negative values (they will be shown as 0 (null) in the graph)
                if (value === undefined || value < 0) continue;

                energyTimeSeries[i].value = value;
            }
        }

        for (let i = 0; i < energyTimeSeries.length; i++) {
            const energyValue = t.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format());

            // Skip negative values (they will be shown as 0 (null) in the graph)
            if (energyValue === undefined || energyValue.value < 0) continue;

            energyTimeSeries[i].value = energyValue.value;
        }

        return energyTimeSeries;
    } else if (organization !== null) {
        const orgDists = distributions.filter(dist => dist.organisationRef.legacyId === organization.id);
        const filteredDists = orgDists.filter(dist => allSites.find(site => site.id === dist.siteRef.legacyId));

        // The last error encountered will be the one shown
        let lastError: Error | null = null;

        for (const dist of filteredDists) {
            const distSite = { id: dist.siteRef.legacyId, guid: dist.siteRef.val, name: dist.siteRef.dis };

            try {
                let t: TimeValue[] = await fetchSiteDayUsage("elec_t", ["totalSupply"], energyType, distSite, date, signal);

                if (t.length === 0) {
                    throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (${date})`);
                }

                let k: TimeValue[] = await fetchSiteDayUsage("elec_k", ["equipLoad"], energyType, distSite, date, signal);
                let s: TimeValue[] = await fetchSiteDayUsage("elec_s", ["specialLoad"], energyType, distSite, date, signal);
                let u: TimeValue[] = await fetchSiteDayUsage("elec_u", ["userLoad"], energyType, distSite, date, signal);
                for (let i = 0; i < energyTimeSeries.length; i++) {
                    const tValue = t.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                    const kValue = k.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                    const sValue = s.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                    const uValue = u.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;

                    const value = CategorizedDistribution(tValue, kValue, sValue, uValue, dist);
                    // Skip negative values (they will be shown as 0 (null) in the graph)
                    if (value === undefined || value < 0) continue;

                    energyTimeSeries[i].value = value;
                }
            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                lastError = error;
            }
        }

        // If no data was found, the last error encountered will be displayed
        if (lastError !== null) throw lastError;

        return energyTimeSeries;
    } else {
        // Show total energy use for the chosen campus
        const campusTotal = { id: campus.siteId, guid: campus.siteGuid, name: campus.name + " Campus" };
        let timeSeries = await fetchSiteDayUsage(energyType.shortName, energyType.queryTags, energyType, campusTotal, date, signal);
        if (timeSeries.length === 0) {
            throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (${date})`);
        }
        for (let i = 0; i < energyTimeSeries.length; i++) {
            const energyValue = timeSeries.find(timeValue => energyTimeSeries[i].ts === dayjs(timeValue.ts).subtract(1, "hour").format());

            // Skip negative values (they will be shown as 0 (null) in the graph)
            if (energyValue === undefined || energyValue.value < 0) continue;

            energyTimeSeries[i].value = energyValue.value;
        }

        return energyTimeSeries;
    }
}


/**
 * Fetches the hourly energy use of a site on a specific day directly from the API.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Site} site - The site to retrieve values for
 * @param {string} date - The specific day to fetch data for (as an ISO date string)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The time series returned by the API
 */
async function fetchSiteDayUsage(energyTypeKey: string, queryTags: string[], energyType: EnergyType, site: Site, date: string, signal?: AbortSignal): Promise<TimeValue[]> {
    const siteVirtualPointGuid = await getSiteVirtualPoint(energyTypeKey, queryTags, site, energyType, signal);

    let from = dayjs(date).format("YYYY-MM-DD");
    let to = dayjs(from).add(1, "day").format("YYYY-MM-DD");

    let timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, "PT1H", "delta", signal);

    return timeSeries;
}


/**
 * Fetches the hourly energy use of a campus, organisation or site within a specified date range directly from the API. Used for the load duration graph.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Campus} campus - The selected campus
 * @param {Organization | null} organization - The selected organization
 * @param {Site | null} site - The selected site/building
 * @param {DistEntity[]} distributions - List of distribution entities retrieved from DistributionService.getDistEntities()
 * @param {Site[]} allSites - List of all sites within the selected campus retrieved from SiteService.getAllSites()
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The hourly energy data as an array of time values
 */
export async function getCategorisedUsageInHours(
    energyType: EnergyType, campus: Campus, organization: Organization | null, site: Site | null,
    distributions: DistEntity[], allSites: Site[], isDateFinished: boolean, year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<TimeValue[]> {

    if (site !== null) {

        let t: TimeValue[] = await fetchSiteUsageInHours("elec_t", ["totalSupply"], energyType, site, isDateFinished, year, month, week, signal);

        if (t.length === 0) {
            throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (year: ${year} month: ${month} week: ${week})`);
        }
        // If an organization (and site) has been selected
        if (organization !== null) {
            let k: TimeValue[] = await fetchSiteUsageInHours("elec_k", ["equipLoad"], energyType, site, isDateFinished, year, month, week, signal);
            let s: TimeValue[] = await fetchSiteUsageInHours("elec_s", ["specialLoad"], energyType, site, isDateFinished, year, month, week, signal);
            let u: TimeValue[] = await fetchSiteUsageInHours("elec_u", ["userLoad"], energyType, site, isDateFinished, year, month, week, signal);
            const dist = distributions.find(dist => dist.siteRef.legacyId === site.id && dist.organisationRef.legacyId === organization.id);

            if (dist === undefined) {
                throw new Error("The site distribution percentage could not be found for the chosen organization");
            }

            // Return the amount of energy that the organization is responsible for
            t = t.map((timeValue: TimeValue) => {
                const kValue = k.find(x => timeValue.ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                const sValue = s.find(x => timeValue.ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                const uValue = u.find(x => timeValue.ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                const value = CategorizedDistribution(timeValue.value, kValue, sValue, uValue, dist);
                // Skip negative values (they will be shown as 0 (null) in the graph)

                return { ts: timeValue.ts, value: value };
            });
        }

        return t;
    } else if (organization !== null) {
        const orgDists = distributions.filter(dist => dist.organisationRef.legacyId === organization.id);
        const filteredDists = orgDists.filter(dist => allSites.find(site => site.id === dist.siteRef.legacyId));

        const timeSeries: TimeValue[] = [];

        // The last error encountered will be the one shown
        let lastError: Error | null = null;

        for (const dist of filteredDists) {
            const distSite = { id: dist.siteRef.legacyId, guid: dist.siteRef.val, name: dist.siteRef.dis };

            try {
                let t: TimeValue[] = await fetchSiteUsageInHours("elec_t", ["totalSupply"], energyType, distSite, isDateFinished, year, month, week, signal);
                let k: TimeValue[] = await fetchSiteUsageInHours("elec_k", ["equipLoad"], energyType, distSite, isDateFinished, year, month, week, signal);
                let s: TimeValue[] = await fetchSiteUsageInHours("elec_s", ["specialLoad"], energyType, distSite, isDateFinished, year, month, week, signal);
                let u: TimeValue[] = await fetchSiteUsageInHours("elec_u", ["userLoad"], energyType, distSite, isDateFinished, year, month, week, signal);

                for (const timeValue of t) {
                    const index = timeSeries.findIndex(t => t.ts === timeValue.ts);
                    const kValue = k.find(x => timeValue.ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                    const sValue = s.find(x => timeValue.ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                    const uValue = u.find(x => timeValue.ts === dayjs(timeValue.ts).subtract(1, "hour").format())?.value ?? 0;
                    const value = CategorizedDistribution(timeValue.value, kValue, sValue, uValue, dist);
                    if (index !== -1) {
                        timeSeries[index] = { ...timeSeries[index], value: timeSeries[index].value + value };
                    } else {
                        timeSeries.push({ ...timeValue, value: value });
                    }
                }

            } catch (error: any) {
                if (error.name === "AbortError") throw error;
                lastError = error;
            }
        }

        // If no data was found, the last error encountered will be displayed
        if (lastError !== null) throw lastError;

        // Because elements have been pushed they may not be ordered anymore
        timeSeries.sort((a, b) => a.ts.localeCompare(b.ts));

        return timeSeries;
    } else {
        // Show total energy use for the chosen campus
        const campusTotal = { id: campus.siteId, guid: campus.siteGuid, name: campus.name + " Campus" };
        let timeSeries = await fetchSiteUsageInHours(energyType.shortName, energyType.queryTags, energyType, campusTotal, isDateFinished, year, month, week, signal);
        if (timeSeries.length === 0) {
            throw new NoDataError(`Could not find any time values for "${energyType.displayName}" from the selected date (year: ${year} month: ${month} week: ${week})`);
        }
        return timeSeries;
    }
}

/**
 * Fetches the hourly energy use of a site within the specified date range directly from the API.
 * @param {EnergyType} energyType - The type of energy to retrieve values for (electricity, water, heat or cooling)
 * @param {Site} site - The site to retrieve values for
 * @param {boolean} isDateFinished - Determines the date range to get data from (used by parseDate())
 * @param {number} year - The selected year
 * @param {number} [month] - The selected month (optional)
 * @param {number} [week] - The selected week (optional)
 * @param {AbortSignal} [signal] - Passed to the API fetch requests so that they can be cancelled if necessary
 * @returns The time series returned by the API with hourly values
 */
async function fetchSiteUsageInHours(energyTypeKey: string, queryTags: string[],
    energyType: EnergyType, site: Site, isDateFinished: boolean,
    year: number, month?: number, week?: number, signal?: AbortSignal
): Promise<TimeValue[]> {
    const siteVirtualPointGuid = await getSiteVirtualPoint(energyTypeKey, queryTags, site, energyType, signal);

    const { from, to } = parseDate(isDateFinished, year, month, week);

    let timeSeries = await getTimeSeries(siteVirtualPointGuid, from, to, "PT1H", "delta", signal);

    return timeSeries;
}
