import { auth } from "./AuthorizationHandler";

export const fetchState = Object.freeze({
    IDLE: Symbol("none"),
    LOADING: Symbol("LOADING"),
    LOADED: Symbol("LOADED"),
    ERROR: Symbol("ERROR")
});

const dataAPI = "https://bmsdata.cas.dtu.dk/api/"

export async function AuthorizedFetchAsync(query: string, content: any, version = "v2") {

    if (auth.user?.expired) {
        await auth.signinRedirect();
    }
    content.headers.Authorization = "Bearer " + auth.user?.access_token;

    const url = dataAPI + version + "/" + query;

    let response = await fetch(url, content);

    return response;
}

export async function AuthorizedFetch(query:string, content: any, version = "v2") {

    if (auth.user?.expired) {
        await auth.signinRedirect();
    }
    content.headers.Authorization = "Bearer " + auth.user?.access_token;

    const url = dataAPI + version + "/" + query;

    return fetch(url, content);
}
