import { AuthorizedFetchAsync } from "../../authorization/AuthorizedFetch";
import { Guid } from "../../models/Guid";
import { TimeValue } from "../../models/TimeValue";

/**
 * Fetches time series from the API (v3) using the given query.
 * @param {Guid} guid - The GUID of an entity
 * @param {string} [from] - Optional from date/time specification (ex. "2020-04-22", "2020-04-22 12:00")
 * @param {string} [to] - Optional to date/time specification (ex. "2020-04-22", "2020-04-22 12:00")
 * @param {string} [interval] - Optional interval resolution of the time series in ISO 8601 duration format (ex. "PT15M", "PT1H", "P1D", "P1M", "P1Y")
 * @param {string} [aggregation] - Optional alignment aggregation as: Raw, Mean, Max, Min, Delta, Count
 * @param {AbortSignal} [signal] - Passed to the API fetch request so that it can be cancelled if necessary
 * @returns The time series as an array of time values
*/
export async function getTimeSeries(guid: Guid, from?: string, to?: string, interval?: string, aggregation?: string, signal?: AbortSignal): Promise<TimeValue[]> {
    let reqString = `timevalues?id=${guid.toString()}`;

    if (from !== undefined) {
        reqString += `&from=${from}`;
    }

    if (to !== undefined) {
        reqString += `&to=${to}`;
    }

    if (interval !== undefined) {
        reqString += `&interval=${interval}`;
    }

    if (aggregation !== undefined) {
        reqString += `&aggregation=${aggregation}`;
    }

    const response = await AuthorizedFetchAsync(reqString, {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
        },
        signal: signal
    }, "v3")

    if (!response.ok) {
        throw new Error("HTTP Error: " + response.status);
    }

    return await response.json();
}
