import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import './assets/fonts/NeoSansProRegular.OTF';
import './assets/fonts/NeoSansProBold.OTF';
import './assets/fonts/NeoSansProLight.OTF';

import Authorization from './authorization/Authorization';
import AuthorizationContext from './authorization/AuthorizationHandler';
import { AuthProviderProps } from 'react-oidc-context';

import dayjs from 'dayjs';
import da from 'dayjs/locale/da';
import localeData from 'dayjs/plugin/localeData';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isoWeek from 'dayjs/plugin/isoWeek';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import isLeapYear from 'dayjs/plugin/isLeapYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import LRUCache from 'lru-cache';
import TestPage from './TestPage/TestPage';

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

// If the search params do not contain the auth token or code then store them
if (!new URLSearchParams(window.location.search).has("code")) {
    sessionStorage.setItem("initial_search_params", window.location.search);
}

const oidcConfig: AuthProviderProps = {
    authority: "https://bmsnet.cas.dtu.dk/login/",
    client_id: "bmsnetdashboard",
    redirect_uri: window.location.origin + "/",
    response_type: "code",
    scope: "bmsnetdataapi_readonly offline_access openid",
    automaticSilentRenew: true,
    silent_redirect_uri: "https://bmsnet.cas.dtu.dk/login/",
    silentRequestTimeoutInSeconds: 30,
    validateSubOnSilentRenew: true,
    monitorAnonymousSession: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    onSigninCallback: () => {
        // Check if the page was previously loaded using search parameters
        const initialSearchParams = sessionStorage.getItem("initial_search_params");

        if (initialSearchParams !== null) {
            const searchParams = new URLSearchParams(initialSearchParams);
            searchParams.sort();
            let newRelativePathQuery = window.location.pathname;
            if (searchParams.toString()) {
                newRelativePathQuery = "?" + searchParams.toString();
            }
            window.history.replaceState(null, "", newRelativePathQuery);
            sessionStorage.removeItem("initial_search_params");
        }
    }
};

// Stores the displayed energy values
export const cache = new LRUCache({ max: 1000 });
// Stores virtual points for sites
export const pointCache = new LRUCache({ max: 500 });

dayjs.locale(da); // Sets the global locale to Denmark
dayjs.extend(localeData); // Lets you fetch a list of month/weekday names
dayjs.extend(isBetween); // Compare date with two other dates
dayjs.extend(isSameOrAfter);  // Compare date with another date
dayjs.extend(isoWeek); // Use week numbers
dayjs.extend(isoWeeksInYear); // Number of weeks in that year
dayjs.extend(isLeapYear); // Check for leap year (used by isoWeeksInYear)
dayjs.extend(advancedFormat); // Provides more format options (such as week number)

root.render(
        <Authorization oidcConfig={oidcConfig}>
        <AuthorizationContext>
            <App />
            </AuthorizationContext>
        </Authorization>
);
   // <React.StrictMode>
    //</React.StrictMode>
