import { Box, FormControl, MenuItem, Select, SelectChangeEvent, Typography, useTheme } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface DateDropdownProps {
    label: string,
    options: string[], // The list of items in the dropdown
    selected: string, // The currently selected item
    hasAllOption: boolean, // Decides whether the dropdown should have an item called "All"
    handleChange: (event: SelectChangeEvent) => void
}

export default function DateDropdown(props: DateDropdownProps) {
    const theme = useTheme();

    const optionElements = props.options.map(option => {
        return <MenuItem key={option} value={option} sx={{fontSize: "1.25rem"}}>{option}</MenuItem>;
    })

    const styles = {
        fontSize: "1.25rem",
        "& .MuiSelect-iconStandard": {
            color: "black",
        }
    };

    return (
        <Box display="flex" alignItems="center" gap={1.5}>
            <Typography fontSize="1.25rem" fontFamily={"NeoSansProBold"}>{props.label}</Typography>
            <FormControl variant="standard">
                <Select
                    autoWidth
                    disableUnderline
                    id="date-dropdown"
                    value={props.selected}
                    onChange={props.handleChange}
                    IconComponent={KeyboardArrowDownIcon}
                    MenuProps={{ PaperProps: {
                        sx: {
                            maxHeight: 400,
                            minWidth: 100,
                            boxShadow: theme.shadows[1]
                        }
                    } }}
                    sx={styles}
                >
                    {props.hasAllOption && <MenuItem value="Alle" sx={{fontSize: "1.25rem"}}>Alle</MenuItem>}
                    {optionElements}
                </Select>
            </FormControl>
        </Box>
    )
}
