import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { EnergyType } from "../../types/EnergyType";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { updateQueryParam } from "../../util/ParamUtils";

interface GraphTypeDropdownProps {
    graphType: string,
    setGraphType: Dispatch<SetStateAction<string>>,
    energyType: EnergyType
}

export function GraphTypeDropdown(props: GraphTypeDropdownProps) {
    const theme = useTheme();

    const styles = {
        color: props.energyType.mainColor,
        backgroundColor: props.energyType.lightColor,
        fontFamily: "NeoSansProBold",
        borderRadius: "6px",
        px: 1,
        borderWidth: 0,
        fontSize: "1rem",
        "& .MuiSelect-iconStandard": {
            color: props.energyType.mainColor,
        }
    };

    return (
        <FormControl variant="standard" sx={{ minWidth: 150 }} >
            <Select
                disableUnderline
                IconComponent={KeyboardArrowDownIcon}
                value={props.graphType}
                onChange={e => {
                    props.setGraphType(e.target.value);
                    updateQueryParam("graph", e.target.value.toLowerCase(), false);
                }}
                MenuProps={{ PaperProps: {
                    sx: {
                        backgroundColor: props.energyType.lightColor,
                        color: props.energyType.mainColor,
                        boxShadow: theme.shadows[1],
                        borderRadius: "6px",
                        "& .MuiMenuItem-root": {
                            fontFamily: "NeoSansProBold",
                        },
                        "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: props.energyType.hoverColor,
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: props.energyType.hoverColor,
                        }
                    }
                } }}
                sx={styles}
            >
                <MenuItem value="Forbrug">Forbrug</MenuItem>
                <MenuItem value="Belastningstid">Belastningstid</MenuItem>
            </Select>
        </FormControl>
    );
}
