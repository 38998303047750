import { Box, Card, Fade, IconButton, Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DistEntity } from "../../models/DistEntity";
import { TimeValue } from "../../models/TimeValue";
import { Campus } from "../../types/Campus";
import { EnergyType } from "../../types/EnergyType";
import { Organization } from "../../types/Organization";
import { Site } from "../../types/Site";
import { UsageType } from "../../types/UsageType";
import CloseIcon from '@mui/icons-material/Close';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DateSelection } from "../../types/DateSelection";
import { setWeekInYear } from "../../util/DateUtils";
import { updateQueryParam } from "../../util/ParamUtils";
import { GraphTypeDropdown } from "./GraphTypeDropdown";
import { UsageDetailsContent } from "./UsageDetailsContent";

interface UsageDetailsBoxProps {
    energyType: EnergyType,
    energyValues: {selection: TimeValue[], lastYear: TimeValue[]},
    selectedDate: DateSelection,
    setSelectedDate: Dispatch<SetStateAction<DateSelection>>,
    selectedCampus: Campus,
    selectedOrganization: Organization | null,
    selectedSite: Site | null,
    allSites: Site[],
    distributions: DistEntity[],
    setDetailViewState: Dispatch<SetStateAction<EnergyType | null>>,
    dayViewState: {isOpen: boolean, date: string},
    setDayViewState: Dispatch<SetStateAction<{isOpen: boolean, date: string}>>,
    navStack: DateSelection[],
    setNavStack: Dispatch<SetStateAction<DateSelection[]>>,
    isLoading: number,
    error: Error | null,
    graphState: {isOpen: boolean, usageType: UsageType, date: string},
    graphType: string,
    setGraphType: Dispatch<SetStateAction<string>>
}

export default function UsageDetailsBox(props: UsageDetailsBoxProps) {
    // Used when fetching data for day view or load duration graph to control the loading icon
    const [isLoadingDetails, setIsLoadingDetails] = useState(false);

    const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: props.energyType.mainColor,
            fontSize: "0.8rem",
            fontFamily: "NeoSansProBold"
        }
    });

    const isLoadingBool = props.isLoading === 100 ? false : true;
    const displayLoad = props.graphType === "Belastningstid" || props.dayViewState.isOpen ? isLoadingDetails : isLoadingBool;

    let subtitle = "";

    if (!props.selectedSite && !props.selectedOrganization) {
        subtitle = props.selectedCampus.name + " Campus";
    } else if (props.selectedSite && props.selectedOrganization) {
        subtitle = props.selectedOrganization.name + " - " + props.selectedSite.name;
    } else if (props.selectedSite) {
        subtitle = props.selectedSite.name;
    } else if (props.selectedOrganization) {
        subtitle = props.selectedOrganization.name;
    }

    const year = props.selectedDate.year;
    const month = props.selectedDate.month;
    const week = props.selectedDate.week;

    let nextDate = dayjs();
    let prevDate = dayjs("2019");

    // Day view
    if (props.dayViewState.isOpen) {
        const date = dayjs(props.dayViewState.date);
        nextDate = date.add(1, "day");
        prevDate = date.subtract(1, "day");
    }
    // Year view
    else if (month === undefined && week === undefined) {
        nextDate = dayjs((year + 1).toString());
        prevDate = dayjs((year - 1).toString());
    }
    // Month view
    else if (month !== undefined) {
        const date = dayjs(`${year}-${month + 1}-01`);
        nextDate = date.add(1, "month");
        prevDate = date.subtract(1, "month");
    }
    // Week view
    else if (week !== undefined) {
        const date = setWeekInYear(dayjs(year.toString()), week);
        nextDate = date.add(7, "day");
        prevDate = date.subtract(7, "day");
    }

    // This is necessary in order to call it within useEffect below (otherwise it gives a warning)
    const setNavStack = props.setNavStack;
    const setGraphType = props.setGraphType;

    useEffect(() => {
        setNavStack([]);
    }, [props.selectedSite, props.selectedOrganization, props.selectedCampus, setNavStack]);

    useEffect(() => {
        // If only a year is selected while load duration graph is being viewed then go back to consumption graph
        if (props.graphType === "Belastningstid" && props.selectedDate.month === undefined && props.selectedDate.week === undefined) {
            setGraphType("Forbrug");
            updateQueryParam("graph", "forbrug", true);
        }
    }, [props.selectedDate, props.graphType, setGraphType]);

    function showAdjacent(isNavNext: boolean) {
        const date = isNavNext ? nextDate : prevDate;

        if (props.dayViewState.isOpen) {
            props.setDayViewState({isOpen: true, date: date.format()});
            updateQueryParam("day", date.format("YYYY-MM-DD"), false);
        } else if (month === undefined && week === undefined) {
            props.setSelectedDate({year: date.year(), month: undefined, week: undefined});
            updateQueryParam("year", date.year().toString(), false);
        } else if (month !== undefined) {
            props.setSelectedDate({year: date.year(), month: date.month(), week: undefined});
            updateQueryParam("month", date.month().toString(), false);
            updateQueryParam("year", date.year().toString(), true);
        } else if (week !== undefined) {
            props.setSelectedDate({year: date.isoWeekYear(), month: undefined, week: date.isoWeek()});
            updateQueryParam("week", date.isoWeek().toString(), false);
            updateQueryParam("year", date.isoWeekYear().toString(), true);
        }
    }

    function handleClose() {
        const stack = [...props.navStack];
        const removed = stack.pop();
        props.setNavStack(stack);

        props.setDayViewState({isOpen: false, date: ""});
        updateQueryParam("day", null, false);

        if (removed !== undefined) {
            if (removed.month === undefined && props.graphType === "Belastningstid") {
                props.setDetailViewState(null);
                updateQueryParam("graph", null, false);
                updateQueryParam("type", null, true);
                return;
            }

            removed.month === undefined && updateQueryParam("month", null, false);
            props.setSelectedDate(removed);
        } else {
            props.setDetailViewState(null);
            updateQueryParam("graph", null, false);
            updateQueryParam("type", null, true);
        }
    }

    return (
        <Box position="relative" className="fade-anim">
            <Card sx={{height: "100%", minHeight: {xs: 530, md: 495, xl: 518, xxl: 495}, p: {xs: 3, md: 3.75}}}>
                <Box mb={3}>
                    <Stack direction={{xs: "column-reverse", sm: "row"}} justifyContent="space-between" rowGap={2} alignItems={{xs: "left", sm: "center"}}>
                        <Box>
                            <Typography
                                display="inline" color={props.energyType.mainColor}
                                fontSize="1.625rem" fontFamily="NeoSansProBold" lineHeight={1}
                            >
                                {props.energyType.displayName}
                            </Typography>
                            <Typography
                                color={props.energyType.mainColor} display="inline"
                                fontSize="1.625rem" lineHeight={1}
                            >
                                {" "} ({props.energyType.unit === "m3" ? <>m<sup>3</sup></> : props.energyType.unit})
                            </Typography>
                        </Box>
                        <Box display="flex" gap={1} justifyContent="space-between">
                            { (props.selectedDate.month !== undefined || props.selectedDate.week !== undefined) &&
                                <GraphTypeDropdown graphType={props.graphType} setGraphType={props.setGraphType} energyType={props.energyType} />
                            }
                            <IconButton
                                sx={{ width: 32, height: 32, color: "black" }}
                                onClick={() => showAdjacent(false)}
                                disabled={prevDate.isBefore("2019-01-01", week !== undefined && !props.dayViewState.isOpen ? "isoWeek" : "date")}
                            >
                                <NavigateBeforeIcon sx={{ fontSize: "2rem" }} />
                            </IconButton>
                            <IconButton
                                sx={{ width: 32, height: 32, color: "black" }}
                                onClick={() => showAdjacent(true)}
                                disabled={nextDate.isAfter(dayjs(), week !== undefined && !props.dayViewState.isOpen ? "isoWeek" : "date")}
                            >
                                <NavigateNextIcon sx={{ fontSize: "2rem" }} />
                            </IconButton>
                            <IconButton
                                aria-label="close"
                                sx={{backgroundColor: "black", width: 32, height: 32, ml: 1, "&:hover": {backgroundColor: "#a6a6a6"}}}
                                onClick={() => handleClose()}
                            >
                                <CloseIcon sx={{ color: "white" }} />
                            </IconButton>
                        </Box>
                    </Stack>
                    <Typography fontSize="0.75rem" mt={0.5}>{subtitle}</Typography>
                </Box>
                <UsageDetailsContent
                    energyType={props.energyType} energyValues={props.energyValues}
                    selectedDate={props.selectedDate} setSelectedDate={props.setSelectedDate}
                    selectedCampus={props.selectedCampus}
                    selectedSite={props.selectedSite} selectedOrganization={props.selectedOrganization}
                    distributions={props.distributions} allSites={props.allSites}
                    dayViewState={props.dayViewState} setDayViewState={props.setDayViewState}
                    setNavStack={props.setNavStack} isLoading={props.isLoading}
                    error={props.error} graphState={props.graphState} graphType={props.graphType}
                    isLoadingDetails={isLoadingDetails} setIsLoadingDetails={setIsLoadingDetails}
                />
            </Card>
            { !displayLoad &&
                <CustomTooltip
                    title="Tilbage til oversigt"
                    placement="bottom"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 300 }}
                    PopperProps={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, -5],
                                },
                            },
                        ],
                    }}
                >
                    <IconButton
                        onClick={() => {
                            props.setDetailViewState(null);
                            props.setDayViewState({isOpen: false, date: ""});
                            updateQueryParam("graph", null, false);
                            updateQueryParam("type", null, true);
                            updateQueryParam("day", null, true);
                        }}
                        sx={{
                            backgroundColor: "white",
                            width: 100,
                            height: 100,
                            boxShadow: "0px 2px 6px 2px #00000029",
                            position: "absolute",
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: 0,
                            right: 0,
                            bottom: -50,
                            "&:hover": {backgroundColor: "white"},
                            "&:hover img": {width: 65, transition: "width 0.3s"}
                    }}>
                        <img src={props.energyType.icon} alt="Energy Icon" width={55} style={{transition: "width 0.3s"}} />
                    </IconButton>
                </CustomTooltip>
            }
        </Box>
    );
}
