import dayjs from "dayjs";
import { TimeValue } from "../models/TimeValue";

/**
 * Finds the given timestamp in the time series and returns its corresponding time value.
 * Because every time series used in this application has been fetched with 'delta', the method does not look for an exact
 * timestamp match, but rather whether the timestamp is between the current time value and the next one in the series.
 * It assumes that the timestamps in the time series represent the end of an interval - for example if the time values are
 * one hour apart, then a timestamp with "13:00" is assumed to represent the range 12:00 - 13:00.
 * @param {string} timestamp - The timestamp where we need to find its value
 * @param {TimeValue[]} timeSeries - The time series wherein we wish to find the value
 * @returns The time value that contains the given timestamp (undefined if none was found)
 */
export function getValueByTimestamp(timestamp: string, timeSeries: TimeValue[]): TimeValue | undefined {
    const result = timeSeries.find((value, i) => {
        if (i === 0) {
            return dayjs(timestamp).isBetween(dayjs(value.ts).subtract(1, "hour"), dayjs(value.ts), null, "[)");
        } else {
            return dayjs(timestamp).isBetween(dayjs(timeSeries[i - 1].ts), dayjs(value.ts), null, "[)");
        }
    });

    // Undefined if no value was found
    return result;
}
