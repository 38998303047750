import { DistEntity } from "../models/DistEntity";
import { TimeValue } from "../models/TimeValue";

export enum DistributionType {
    STANDARD,
    CATEGORISED
}

function StandardDistribution(timeValue: TimeValue, distribution: DistEntity): TimeValue {

    const result = { ts: timeValue.ts, value: timeValue.value * distribution.energyPct };
    return result;
}

export function CategorizedDistribution(totalSupply: number, equipLoad: number, specialLoad: number, userLoad: number, distribution: DistEntity): number {
    const categorizedLoad = equipLoad * distribution.energyKPct
                            + specialLoad * distribution.energySPct
        + userLoad * distribution.energyLPct;
    const remainder = totalSupply - equipLoad - specialLoad - userLoad;
    const remainderDistribution = remainder * distribution.energyPct;
    const result = remainderDistribution + categorizedLoad;
    return result;
}

