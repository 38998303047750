/**
 * Updates the given query parameter in the URL by setting it to a new value or deleting it.
 * @param {string} param - The query parameter to be updated.
 * @param {string | null} value - The new value that the query parameter should be set to. If null then the parameter will be deleted from the URL.
 * @param {boolean} replace - If true, the new history state will replace the old one (replaceState) rather than pushed to the stack (pushState).
 */
export function updateQueryParam(param: string, value: string | null, replace: boolean) {
    const searchParams = new URLSearchParams(window.location.search);

    if (value) {
        searchParams.set(param, value);
    } else {
        searchParams.delete(param);
    }

    searchParams.sort();
    const newRelativePathQuery = '?' + searchParams.toString();

    if (newRelativePathQuery !== window.location.search) {
        replace ? window.history.replaceState(null, '', newRelativePathQuery) : window.history.pushState(null, '', newRelativePathQuery);
    }
}

/** 
 * Gets the current query params from the URL but excludes the ones that should not be a part of the key in the cache. 
 * Returns them in string format.
 */
export function getCacheSearchParams(): string {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("type");
    searchParams.delete("graph");

    return searchParams.toString();
}