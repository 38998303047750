import { Entity } from "../../models/Entity";
import { Guid } from "../../models/Guid";
import { Tag } from "../../models/Tag";

import { AuthorizedFetchAsync } from "../../authorization/AuthorizedFetch";
import { parseTag } from "./TagParser";

/**
 * Fetches entities from the API (v2) using the given query.
 * Query parameters that do not need to be specified can simply be set to 'null'.
 * @param {number | null} id - The ID of an entity
 * @param {string | null} name - The name of an entity
 * @param {string[] | null} tags - A list of tags that the returned entities should contain
 * @param {number | null} levels - Used with id or name; specifies the level of referenced entities included in the result
 * @param {AbortSignal} [signal] - Passed to the API fetch request so that it can be cancelled if necessary
 * @returns An array of Entity objects
 */
export async function getEntities(id: number | null, name: string | null, tags: string[] | null, levels: number | null, signal?: AbortSignal): Promise<Entity[]> {
    const reqOpt = {
        method: 'GET',
        headers: {
            "Content-type": "application/json",
        },
        signal: signal
    }

    var reqString = 'Entities';

    if (id !== null) {
        if (!reqString.includes('?')) {
            reqString += '?';
        }
        reqString += 'id=' + id;
    }

    if (name !== null) {
        if (!reqString.includes('?')) {
            reqString += '?';
        }
        else {
            reqString += '&';
        }
        reqString += 'name=' + name;
    }

    if (tags !== null && tags.length > 0) {
        if (!reqString.includes('?')) {
            reqString += '?';
        }
        else {
            reqString += '&';
        }
        reqString += 'tags=' + tags.join();
    }

    if (levels !== null) {
        if (!reqString.includes('?')) {
            reqString += '?';
        }
        else {
            reqString += '&';
        }
        reqString += 'levels=' + levels;
    }

    let result = await AuthorizedFetchAsync(reqString, reqOpt);

    if (!result.ok) {
        throw new Error("HTTP Error: " + result.status);
    }

    let jsonArray = await result.json();

    let mappedList = jsonArray.map(entityMapper);

    return mappedList;
}

/**
 * Maps a JSON object (returned from the API) to an object of type Entity.
 * @param {any} s - The JSON object to be mapped
 * @returns The mapped Entity object
*/
function entityMapper(s: any): Entity {
    let tags: Tag[] = s.tags.map((tagString: string) => parseTag(tagString));

    let result: Entity = {
        entity: s.entity,
        tags: {
            id: Number(tags.find(tag => tag.key === "id")?.value),
            guid: new Guid(tags.find(tag => tag.key === "guid")?.value ?? ""),
            displayName: tags.find(tag => tag.key === "dis")?.value ?? s.entity,
            siteRef: Number(tags.find(tag => tag.key === "siteRef")?.value),
            markerTags: tags.filter(tag => !tag.value).map(tag => tag.key),
            valueTags: tags.filter(tag => tag.value !== "")
        }
    };

    return result;
}
