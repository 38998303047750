import { Box } from "@mui/material";
import { Bar, BarChart, Cell, ResponsiveContainer, YAxis } from "recharts";

interface UsageGraphProps {
    current: number,
    lastYear: number,
    color: string,
    decimals: number
}

export default function UsageGraph(props: UsageGraphProps) {
    // Rounds the numbers before displaying
    const current = Number(props.current.toFixed(props.decimals));
    const lastYear = Number(props.lastYear.toFixed(props.decimals));

    return (
        <Box position="relative" ml="-10px" width={100} height={250}>
            <ResponsiveContainer>
                <BarChart margin={{}} width={100} height={250} data={[{value: lastYear}, {value: current}]}>
                    <YAxis hide tickCount={0} />
                    <Bar dataKey="value" radius={5} barSize={30}>
                        <Cell
                            key={`cell-0`}
                            fill={"#c5c5c5"}
                        />
                        <Cell
                            key={`cell-1`}
                            fill={props.color}
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </Box>
    )
}
