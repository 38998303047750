import { pointCache } from "..";
import NoPointError from "../errors/NoPointError";
import { Entity } from "../models/Entity";
import { Guid } from "../models/Guid";
import { EnergyType } from "../types/EnergyType";
import { Site } from "../types/Site";
import { getEntities } from "./api/EntityService";

/**
 * Fetches the virtual point of a site with the specified energy type.
 * @param {EnergyType} energyType - The type of energy which determines the virtual point (e.g. EF_EM, BF_FM...)
 * @param {Site} site - The site to get the virtual point for
 * @param {AbortSignal} [signal] - Passed to the API fetch request so that it can be cancelled if necessary
 * @returns The GUID for the virtual point
 */
async function getSiteVirtualPoint(energyType: EnergyType, site: Site, signal?: AbortSignal): Promise<Guid> {
    const cachedPoint: string | undefined = pointCache.get(`${energyType.shortName}-${site.id}`);
    let virtualSitePointGuid: Guid;

    if (cachedPoint) {
        virtualSitePointGuid = new Guid(cachedPoint);
    } else {
        const baseTags = ["point", "virtual", `siteRef:@${site.id}`];
        let virtualSitePoint: Entity[] = [];

        if (energyType.queryTags.length) {
            const queryTags = baseTags.concat(energyType.queryTags);

            virtualSitePoint = await getEntities(null, null, queryTags, null, signal);
        }

        if (!virtualSitePoint.length) {
            virtualSitePoint = await getEntities(null, energyType.queryName, baseTags, null, signal);

            if (!virtualSitePoint.length) {
                throw new NoPointError(`Could not find "${energyType.displayName}" energy usage for ${site.name} (no virtual point)`);
            }
        }

        virtualSitePointGuid = virtualSitePoint[0].tags.guid;
        pointCache.set(`${energyType.shortName}-${site.id}`, virtualSitePointGuid.toString());
    }

    return virtualSitePointGuid;
}

const exported = {
    getSiteVirtualPoint
}

export default exported;
