import { Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { TimeValue } from "../../models/TimeValue";

interface EmissionGraphProps {
    emissions: TimeValue[],
    prognosis: TimeValue[]
}

export default function EmissionGraph(props: EmissionGraphProps) {
    const theme = useTheme();

    const data = parseTimeSeries(props.emissions, props.prognosis);

    // Defines the x-axis domain (lowest and highest time)
    const xMin = dayjs().startOf("day");
    const xMax = xMin.add(1, "day").subtract(5, "minute");

    let startTime = xMin.unix() + 3600;
    let ticksUnix = [];

    // Fills the array with times that define the ticks on the x-axis
    for (let i = 0; i < 12; i++) {
        ticksUnix.push(startTime);
        startTime += 7200;
    }

    const unit = <>gram CO<sub>2</sub> pr. kWh</>;

    const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
        const timeLabel = dayjs.unix(label).format("HH:mm");
        const nextLabel = dayjs.unix(label).add(5, "minute").format("HH:mm");
        const labelFormatted = timeLabel + " - " + nextLabel;

        if (active && payload && payload.length) {
            return (
                <Box p={1.5} position="relative" borderRadius="5px" border="1px solid black" sx={{backgroundColor: "white"}}>
                    <Typography lineHeight={1} mb={1}>{labelFormatted}</Typography>
                    { payload[0].payload.prognosis !== null &&
                        <Typography lineHeight={1} color="orange">
                            Prognose: {(payload[0].payload.prognosis)?.toLocaleString("da-DK", {maximumFractionDigits: 2})} {unit}
                        </Typography>
                    }
                    { payload[0].payload.emission !== null &&
                        <Typography lineHeight={1} mt={1} color={theme.palette.primary.main} fontFamily="NeoSansProBold">
                            Udledning: {(payload[0].payload.emission)?.toLocaleString("da-DK", {maximumFractionDigits: 2})} {unit}
                        </Typography>
                    }
                </Box>
            );
        }

        return null;
    };

    function parseTimeSeries(emissions: TimeValue[], prognosis: TimeValue[]) {
        // Converting to unix is necessary because using dayjs addition in the loop below is too intensive
        const isoToUnix = (timeSeries: TimeValue[]) => {
            const unixValues: {unix: number, value: number}[] = [];

            for (let i = 0; i < timeSeries.length; i++) {
                unixValues.push({
                    unix: dayjs(timeSeries[i].ts).unix(),
                    value: timeSeries[i].value
                });
            }

            return unixValues;
        };

        const emissionsParsed = isoToUnix(emissions);
        const prognosisParsed = isoToUnix(prognosis);

        const startOfToday = dayjs().startOf("day").unix();

        const data = [];

        // For every 5-minute interval today
        for (let i = 0; i < 24 * 60 / 5; i++) {
            const timestamp = startOfToday + 60 * 5 * i; // Timestamp for the graph data
            const searchTimestamp = timestamp + 60 * 5; // Same timestamp 5 minutes later to match API data format

            const emisValue = emissionsParsed.find(tv => tv.unix === searchTimestamp);
            const progValue = prognosisParsed.find(tv => tv.unix === searchTimestamp);

            data.push({
                time: timestamp,
                emission: emisValue ? emisValue.value : null,
                prognosis: progValue ? progValue.value : null
            });
        }

        return data;
    }

    return (
        <ResponsiveContainer width="100%" height={340}>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    right: 50
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="time" tickSize={8} tickMargin={5}
                    type="number" scale="time" domain={[xMin.unix(), xMax.unix()]}
                    ticks={ticksUnix} tickFormatter={tick => dayjs.unix(tick).format("HH:mm")}
                />
                <YAxis width={65} tickSize={8} tickMargin={5} tickFormatter={tick => tick.toLocaleString("da-DK", {maximumFractionDigits: 2})} />
                <Tooltip
                    content={<CustomTooltip />}
                    wrapperStyle={{ outline: "none" }}
                    isAnimationActive={false}
                />
                <Legend
                    wrapperStyle={{paddingLeft: 70}}
                    formatter={(value, entry, index) => (
                        <Typography
                            display="inline" position="relative" top="2px" marginRight={2}
                        >
                            {value}
                        </Typography>
                    )}
                />
                <Line type="monotone" dataKey="prognosis" stroke="orange" strokeDasharray="5 5" dot={false} strokeWidth={1.5} name="Prognose" />
                <Line type="monotone" dataKey="emission" stroke={theme.palette.primary.main} dot={false} strokeWidth={2} name="Udledning" />
            </LineChart>
        </ResponsiveContainer>
    )
}
